import React, { useEffect, useState } from "react";
import api from "../../api/axiosConfig";
import format from "date-fns/format";
import parse from "date-fns/parse"; // Import parse to handle date strings
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { useAuth } from "../Authentication/AuthContext";

const CalendarChart = () => {
  const [calendarData, setCalendarData] = useState([]);
  const [error, setError] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const { userDetails } = useAuth();
  const roleName = userDetails.role_name;
  const userId = userDetails.id;
  const dayNames = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const fetchCalendarData = async (month, year) => {
    try {
      const res = await api.get(`/api/timesheet/dailyduration`, {
        params: { month: month + 1, year },
      });
      console.log("Fetched data:", res.data);

      // Format data to add parsed day
      const formattedData = res.data.map((entry) => {
        const dayDate = new Date(entry.day); // Parse date
        return {
          ...entry,
          day: format(dayDate, "dd-MM-yyyy"), // Format as dd-MM-yyyy
        };
      });

      setCalendarData(formattedData); // Set formatted data
    } catch (error) {
      console.error(
        "Error fetching calendar data:",
        error.response?.data || error.message
      );
      setError("Failed to load calendar data.");
    }
  };

  useEffect(() => {
    fetchCalendarData(currentMonth, currentYear);
  }, [currentMonth, currentYear]);

  // const getTotalBookedHoursForDay = (day) => {
  //   const currentDate = new Date(currentYear, currentMonth, day + 1);

  //   // Calculate total booked hours for the given day
  //   const totalMinutes = calendarData.reduce((acc, entry) => {
  //     const entryDate = parse(entry.day, "dd-MM-yyyy", new Date());
  //     if (
  //       entryDate.getTime() === currentDate.getTime() &&
  //       (roleName === "Administrator" || entry.user_id === userId)
  //     ) {
  //       // Convert booked_hours to minutes (ignoring seconds)
  //       const [hours, minutes] = entry.booked_hours.split(":").map(Number);
  //       const durationInMinutes = hours * 60 + minutes;
  //       return acc + durationInMinutes;
  //     }
  //     return acc;
  //   }, 0);

  //   // Convert total minutes back to HH:MM format
  //   const hours = Math.floor(totalMinutes / 60);
  //   const minutes = totalMinutes % 60;
  //   return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
  //     2,
  //     "0"
  //   )}`;
  // };
  const getTotalBookedHoursForDay = (day) => {
    const currentDate = new Date(currentYear, currentMonth, day + 1);

    // Skip calculation for dates before January 2025
    if (
      currentYear < 2025 ||
      (currentYear === 2025 && currentMonth === 0 && day + 1 < 1)
    ) {
      return "00:00"; // Default for invalid dates
    }

    const totalMinutes = calendarData.reduce((acc, entry) => {
      const entryDate = parse(entry.day, "dd-MM-yyyy", new Date());
      if (
        entryDate.getTime() === currentDate.getTime() &&
        (roleName === "Administrator" || entry.user_id === userId)
      ) {
        const [hours, minutes] = entry.booked_hours.split(":").map(Number);
        const durationInMinutes = hours * 60 + minutes;
        return acc + durationInMinutes;
      }
      return acc;
    }, 0);

    // Convert total minutes back to HH:MM format
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  const handlePrevMonth = () => {
    // Prevent navigating to months before January 2025
    if (currentYear === 2025 && currentMonth === 0) return;
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const firstDayIndex = new Date(currentYear, currentMonth, 1).getDay();
  const currentMonthDays = getDaysInMonth(currentMonth, currentYear);

  return (
    <div>
      <div className="calendar-controls-duration">
        <button onClick={handlePrevMonth} style={{ border: "none" }}>
          <MdKeyboardDoubleArrowLeft />
        </button>
        <h2>{`${monthNames[currentMonth]} ${currentYear}`}</h2>
        <button onClick={handleNextMonth} style={{ border: "none" }}>
          <MdKeyboardDoubleArrowRight />
        </button>
      </div>
      <div className="calendar-grid">
        {dayNames.map((day) => (
          <div className="calendar-day-label" key={day}>
            {day}
          </div>
        ))}
        {Array.from({ length: firstDayIndex }).map((_, index) => (
          <div className="calendar-empty-day" key={index}></div>
        ))}
        {[...Array(currentMonthDays).keys()].map((day) => {
          const totalBookedHours = getTotalBookedHoursForDay(day);
          const dayDate = new Date(currentYear, currentMonth, day + 1);
          const isToday =
            dayDate.getDate() === new Date().getDate() &&
            dayDate.getMonth() === new Date().getMonth() &&
            dayDate.getFullYear() === new Date().getFullYear();

          return (
            <div
              className={`calendar-day ${isToday ? "calendar-today" : ""}`}
              key={day}
              style={{
                backgroundColor: isToday ? "yellow" : "transparent",
                fontWeight: isToday ? "600" : "400",
                color:
                  dayDate.getDay() === 0 ||
                  (dayDate.getDay() === 6 && Math.ceil((day + 1) / 7) % 2 === 1)
                    ? "red"
                    : "black",
              }}
            >
              <div style={{ backgroundColor: "transparent" }}>{day + 1}</div>
              <div
                style={{
                  color: "#6200ee",
                  display: totalBookedHours === "00:00" ? "none" : "block",
                  backgroundColor: "transparent",
                }}
              >
                {totalBookedHours || ""}
              </div>
            </div>
          );
        })}
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default CalendarChart;
