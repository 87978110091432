import React, { useEffect, useState, useContext, useRef } from "react";
import * as XLSX from "xlsx";
import { useRolePermissions } from "../Settings/RoleContext";
import { useAuth } from "../Authentication/AuthContext";
import DatePicker from "react-datepicker";
import { format, parseISO, parse, isValid } from "date-fns";
import { useParams } from "react-router-dom";
import "../../styles/projects/PhaseDetails.css";
import api from "../../api/axiosConfig";
import { CircularProgress, Typography, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { FaChevronDown } from "react-icons/fa";
import { ProjectContext } from "../../App";
import { Helmet } from "react-helmet";
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from "react-toastify";

const fetchData = async (url, setData, setError) => {
  try {
    const response = await fetch(url);
    if (!response.ok)
      throw new Error(`Network response was not ok: ${response.statusText}`);
    const data = await response.json();
    setData(data);
  } catch (error) {
    if (setError) {
      setError(error.message);
    } else {
      console.error("Error fetching data:", error);
    }
  }
};

const addTaskToPhase = async (phaseId, taskTemplateId, setError) => {
  try {
    // Ensure phaseId and taskTemplateId are valid
    if (!phaseId || !taskTemplateId) {
      throw new Error("Invalid phaseId or taskTemplateId");
    }

    // Send a POST request to add the task template to the phase
    const response = await api.post(`/api/addTaskTemplate-phase/${phaseId}`, {
      phase_id: phaseId,
      tasktemplate_id: taskTemplateId,
    });

    // Return the response data
    return response.data;
  } catch (error) {
    // Set error state or handle error as needed
    setError(error.message || "An error occurred while adding the task.");
    // Optionally, you might want to rethrow the error or handle it differently
    throw error;
  }
};

function UpdatePlannedHours({ taskId, initialPlannedHours }) {
  const [plannedHours, setPlannedHours] = useState(initialPlannedHours);
  const [message, setMessage] = useState("");

  const updatePlannedHours = async () => {
    try {
      const response = await api.put(
        `/api/phase_usertasks/${taskId}/plannedhours`,
        {
          plannedhours: plannedHours,
        }
      );
      setMessage(response.data.message);
    } catch (error) {
      setMessage("Failed to update planned hours.");
    }
  };
}

const addVariationToPhase = async (phaseId, variationData, setError) => {
  try {
    const response = await api.post(
      `/api/phase/${phaseId}/addVariation`,
      variationData
    );
    return response.data; // Axios automatically parses JSON
  } catch (error) {
    setError(error);
  }
};
const PhaseDetails = () => {
  const { phaseId, projectId } = useParams();
  const { userDetails } = useAuth();
  const userID = userDetails.id;
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const { hasPermission } = useRolePermissions();
  const [phase, setPhase] = useState(null);
  const project_start_date = phase ? phase.start_date : null;
  const project_due_date = phase ? phase.end_date : null;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [taskTemplateId, setTaskTemplateId] = useState("");
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [tasksByPhase, setTasksByPhase] = useState([]);
  const [updatedTasks, setUpdatedTasks] = useState([]);
  const [selectedTTaskId, setSelectedTTaskId] = useState([]);
  const [tabIndex, setTabIndex] = useState(() => {
    const storedTabIndex = localStorage.getItem("tabIndex");
    return storedTabIndex ? parseInt(storedTabIndex, 10) : 0;
  });
  const [isEditMode, setIsEditMode] = useState(false); // To track if it's add or edit mode
  const [openVariationDialog, setOpenVariationDialog] = useState(false);
  const [variationNo, setVariationNo] = useState("");
  const [variationName, setVariationName] = useState("");
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [variationHours, setVariationHours] = useState(0);
  const [variations, setVariations] = useState([]); // Initialize as an empty array
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [claimStatusVariation, setClaimStatusVariation] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const variationRef = useRef(null);
  const [bulkActionVariation, setBulkActionVariation] = useState(false);
  const [deleteTaskDialogOpen, setDeleteTaskDialogOpen] = useState(false);
  const [deleteIndividualTaskDialogOpen, setIndividualDeleteTaskDialogOpen] =
    useState(false);
  const [addTaskDial, setAddTaskDial] = useState(false);
  const [taskTemplateIdToAdd, setTaskTemplateIdToAdd] = useState([]);
  const [newTask, setNewTask] = useState({ name: "" });
  const [variationToDelete, setVariationToDelete] = useState([]);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxCheckedForTask, setIsCheckboxCheckedForTask] =
    useState(false);

  //issues props
  const issueRef = useRef(null);
  const [issues, setIssues] = useState([]); // To store the list of issues
  const [openIssueDialog, setOpenIssueDialog] = useState(false); // To control the dialog for adding/editing issues
  const [users, setUsers] = useState([]);
  const [projectUsers, setProjectUsers] = useState([]);
  const options = projectUsers.map((user) => ({
    label: user.name, // Displayed name in the MultiSelect
    value: user.id, // Group ID for tracking selection
  }));

  const [projectSelectedUsers, setProjectSelectedUsers] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [issueNo, setIssueNo] = useState(""); // To track the issue number
  const [issueName, setIssueName] = useState(""); // To track the issue name
  const [issueHours, setIssueHours] = useState(0); // To track the planned hours for the issue
  const [issueType, setIssueType] = useState([]);
  const [issueRaisedBy, setIssueRaisedBy] = useState([]);
  const [issueErrorBy, setIssueErrorBy] = useState([]);
  const [issueAttendedBy, setIssueAttendedBy] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState(null); // To track the issue being edited
  const [isIssueEditMode, setIsIssueEditMode] = useState(false); // To differentiate between add and edit mode for issues
  const [issueToDelete, setIssueToDelete] = useState([]); // To track the issue to be deleted
  const [deleteIssueDialogOpen, setDeleteIssueDialogOpen] = useState(false); // To control the delete confirmation dialog
  const [isIssueCheckboxChecked, setIsIssueCheckboxChecked] = useState(false); // To track the checkbox state in the delete dialog
  const [bulkAction, setBulkAction] = useState(false);
  const [claimStatusIssue, setClaimStatusIssue] = useState("");
  const [expandedIndex, setExpandedIndex] = useState(null);
  //  add phase_task starts
  const [plannedHours, setPlannedHours] = useState({});
  const [actualHours, setActualHours] = useState({});

  const handleDateChange = (tasktemplateId, taskId, dateType, date) => {
    console.log("tasks to update", tasktemplateId, taskId, dateType, date);

    const updateTaskDates = async () => {
      try {
        let start_date;
        let due_date;

        let parsedDate;

        // Check if 'date' is a string or a Date object and handle accordingly
        if (typeof date === "string") {
          // If it's a string (already in 'yyyy-MM-dd' format), convert it to a Date object
          parsedDate = new Date(date); // Convert the string to a Date object
        } else if (date instanceof Date) {
          parsedDate = date; // If it's already a Date object, use it
        }

        // Check if the parsed date is valid
        if (!isValid(parsedDate)) {
          console.error("Invalid date format", date);
          return;
        }

        console.log("Parsed date:", parsedDate); // Log the parsed date for debugging

        // Convert the parsed date to the ISO format (yyyy-MM-dd)
        const isoDate = parsedDate.toISOString().split("T")[0]; // Extract only the date part (yyyy-MM-dd)

        // Now set the formatted date (in ISO format)
        if (dateType === "start_date") {
          start_date = isoDate;
        } else if (dateType === "due_date") {
          due_date = isoDate;
        }

        // Only send the date(s) that are not undefined
        const payload = {};
        if (start_date) payload.start_date = start_date;
        if (due_date) payload.due_date = due_date;
        console.log("payload", payload);

        // Send the updated dates to the server
        const response = await api.put(
          `/api/task/update-dates/${taskId}`,
          payload
        );

        console.log("task id", taskId, "date", date);
        fetchAllData(); // Refresh the data after update
        console.log("Task dates updated successfully:", response.data);
      } catch (error) {
        console.error("Error updating task dates:", error);
      }
    };

    if (taskId && dateType) {
      updateTaskDates(); // Only update if taskId and dateType are present
    }
  };

  const handleCheckboxChange = (e, taskId) => {
    if (e.target.checked) {
      // Add the taskId to the selectedTTaskId array
      setSelectedTTaskId((prev) => [...prev, taskId]);
    } else {
      // Remove the taskId from the selectedTTaskId array
      setSelectedTTaskId((prev) => prev.filter((id) => id !== taskId));
    }
  };

  const handleTaskUser = async (taskId, selectedOptions) => {
    const userIds = selectedOptions.map((option) => option.value);

    console.log("User IDs for task:", userIds);

    // Update the local state
    setTasksByPhase((prevState) => {
      return prevState.map((phase) => {
        return {
          ...phase,
          tasks: phase.tasks.map((task) => {
            if (task.id === taskId) {
              return {
                ...task,
                assigned_users: selectedOptions.map((user) => ({
                  label: user.label,
                  value: user.value,
                })),
              };
            }
            return task;
          }),
        };
      });
    });

    try {
      // Handle empty userIds case for the server
      const response = await api.put("/api/update-task-userid", {
        userIds, // Send an empty array when no users are selected
        taskId,
      });
      fetchAllData();
      console.log("Users updated successfully:", response.data);
    } catch (error) {
      console.error(
        "Error updating users:",
        error.response?.data || error.message
      );
    }
  };
  function normalizePlannedHours(input) {
    // If input is a decimal (e.g., "1.5"), convert it to HH:MM format
    if (/^[0-9]+([.][0-9]+)?$/.test(input)) {
      const hours = Math.floor(parseFloat(input));
      const minutes = Math.round((parseFloat(input) - hours) * 60);
      return `${hours}:${minutes.toString().padStart(2, "0")}`;
    }

    // If input is in HH:MM format, return it directly
    if (/^[0-9]+:[0-5][0-9]$/.test(input)) {
      return input;
    }

    // If input is a whole number, treat it as hours
    if (/^[0-9]+$/.test(input)) {
      return `${parseInt(input, 10)}:00`;
    }

    // For invalid formats, return an empty string
    return "";
  }

  // Handle planned hours change
  const handlePlannedHoursChange = (taskId, event, tasktemplateId) => {
    const value = normalizePlannedHours(event.target.value);
    console.log("value", value, "id", taskId, "tasktemplateid", tasktemplateId);
    // setTasksByPhase((prevState) => {
    //   console.log("prevState:", prevState);

    //   // Ensure taskTemplateId matches the id field type
    //   const updatedPhase = prevState.find(
    //     (t) => t.id === Number(taskTemplateId)
    //   );
    //   console.log("updatedPhase:", updatedPhase);

    //   if (!updatedPhase) {
    //     console.warn("Phase not found for taskTemplateId:", taskTemplateId);
    //     return prevState; // Return the state as is if no phase matches
    //   }

    //   // Map through the tasks to update the specific task's planned_hours
    //   const updatedTasks = updatedPhase.tasks.map((task) => {
    //     if (task.id === taskId) {
    //       console.log(`Updating task ${taskId} planned_hours to:`, value);
    //       return { ...task, planned_hours: value }; // Update planned_hours for the matching task
    //     }
    //     return task; // Return the task as is if it doesn't match
    //   });

    //   // Return the updated state
    //   return prevState.map(
    //     (phase) =>
    //       phase.id === taskTemplateId
    //         ? { ...phase, tasks: updatedTasks } // Update tasks for the matching phase
    //         : phase // Keep other phases as is
    //   );
    // });
    setPlannedHours((prevState) => ({
      ...prevState,
      [taskId]: value,
    }));
  };

  const convertTimeToMinutes = (time) => {
    if (!time || time === "00:00") return 0; // Ensure empty or "00:00" is treated as 0
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes; // Convert time to total minutes
  };

  const convertMinutesToHHMM = (totalMinutes) => {
    const isNegative = totalMinutes < 0; // Check if the difference is negative
    const absoluteMinutes = Math.abs(totalMinutes); // Use absolute value for calculation
    const hours = Math.floor(absoluteMinutes / 60);
    const minutes = absoluteMinutes % 60;
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
    return isNegative ? `-${formattedTime}` : formattedTime; // Add "-" if negative
  };

  const handleSavePlannedHours = (taskId) => {
    const hours = plannedHours[taskId];
    console.log("plannedhours is", plannedHours[taskId]);

    // Validate input for HH:mm format
    if (!hours || !/^([0-9]+:[0-5][0-9])$/.test(hours)) {
      toast.error("Please enter planned hours ", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }
    fetchAllData();
    savePlannedHoursToDatabase(taskId, phaseId, hours).catch((error) => {
      console.error("Error saving planned hours:", error);
      alert(error.message || "An error occurred while saving planned hours.");
    });
  };

  const savePlannedHoursToDatabase = async (taskId, phaseId, hours) => {
    console.log("details:", phaseId, taskId, hours);

    try {
      // Validate inputs again before sending the request
      if (!taskId || !/^([0-9]+:[0-5][0-9])$/.test(hours)) {
        throw new Error(
          "Invalid taskId or hours. Please refresh the page and try again."
        );
      }

      // Send a PUT request to update planned hours
      const response = await api.put(
        `/api/phase_usertasks/${taskId}/plannedhours`,
        {
          plannedhours: hours, // Send HH:mm format
        }
      );
      fetchAllData();
      // toast.success("Hours have been successfully added to the task.", {
      //   position: "bottom-center",
      //   autoClose: 2000,
      // });

      console.log("response", response);
      return response.data;
    } catch (error) {
      console.error(
        "Error in savePlannedHoursToDatabase:",
        error.message || error
      );
      throw error; // Re-throw the error to be caught in the calling function
    }
  };

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  // fetch all details based on variation issue

  const fetchAllData = async () => {
    const fetchData = async (endpoint, setter) => {
      try {
        const response = await api.get(endpoint);
        setter(response.data);
      } catch (error) {
        setError(error);
      }
    };
    try {
      // Fetch all data concurrently
      await Promise.all([
        fetchData(`/api/phase/${phaseId}`, setPhase),
        fetchData(`/api/phase/${phaseId}/usertaskTemplates`, setTasksByPhase),
        fetchData(`/api/taskTemplates`, setTaskTemplates),
        fetchData(`/api/users`, setUsers),
        fetchData(`/api/projects/${projectId}/users`, setProjectUsers),
        fetchData(`/api/phase/${phaseId}/variations`, (data) =>
          setVariations(Array.isArray(data) ? data : [])
        ),
        fetchData(`/api/phase/${phaseId}/issues`, (data) =>
          setIssues(Array.isArray(data) ? data : [])
        ),
        api.get(`/api/phase/${phaseId}/plannedHours`, (data) =>
          setPlannedHours(Array.isArray(data) ? data : [])
        ),
        fetchData(`/api/projects/${projectId}/customer`, setCustomer),
      ]);
    } finally {
      setLoading(false); // Ensure loading is set to false once all requests are done
    }
  };

  useEffect(() => {
    fetchAllData();
  }, [phaseId]);

  //handleAddTask
  const handleAddTask = async () => {
    if (!taskTemplateId) {
      toast.error("Please select a task to add.", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }

    try {
      const newTask = await addTaskToPhase(
        phaseId,
        Number(taskTemplateId),
        setError
      );
      console.log("new task--", newTask);
      if (newTask) {
        // Update tasks for the phase
        setTasksByPhase((prevTasks) => {
          const updatedTasks = [...prevTasks, newTask];
          console.log("new task added", updatedTasks);
          return updatedTasks;
        });
        console.log("task by phase after inserting new value", tasksByPhase);
        // Instead of removing the taskTemplateId, mark it as "used"
        setTaskTemplates((prevTemplates) =>
          prevTemplates.map((template) =>
            template.id === Number(taskTemplateId)
              ? { ...template, used: true } // Mark template as used instead of removing it
              : template
          )
        );

        // Clear the task template selection
        setTaskTemplateId("");
        // Clear any existing errors
        setError(null);
      }
    } catch (error) {
      console.error("Error adding task:", error);
    }
  };
  const handleDeleteTask = async () => {
    setIndividualDeleteTaskDialogOpen(true);
  };

  const handleDeleteClick = (taskTemplateId) => {
    console.log("task id to delete", taskTemplateId);
    setTaskToDelete(Number(taskTemplateId));
    setDeleteTaskDialogOpen(true);
  };

  const handleDeleteTaskTemplate = async () => {
    const taskTemplateId = Number(taskToDelete);

    if (!taskTemplateId) {
      console.error("No task template ID set for deletion.");
      alert("Please select a task template to delete.");
      return;
    }

    try {
      // Perform the deletion via API
      const response = await api.delete(
        `/api/phase/${phaseId}/usertaskTemplates/${taskTemplateId}`
      );

      if (response.status === 200) {
        // Update state to reflect the deletion
        setTasksByPhase((prevTasks) =>
          prevTasks.filter((task) => task.id !== taskTemplateId)
        );
        setTaskTemplates((prevTemplates) =>
          prevTemplates.map((template) =>
            template.id === taskTemplateId
              ? { ...template, used: false }
              : template
          )
        );
        setDeleteTaskDialogOpen(false);
        setIsCheckboxChecked(false);
        console.log("Task template deleted successfully.");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorMessage =
          error.response.data?.message || "An error occurred.";
        console.error("Deletion prevented:", errorMessage);
        toast.error(errorMessage, {
          position: "bottom-center",
          autoClose: 2000,
        });
      } else {
        console.error("Unexpected error during deletion:", error.message);
        alert("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleDeleteAssociatedTask = async () => {
    if (selectedTTaskId.length > 0) {
      console.log("selected task id's", selectedTTaskId);
      try {
        // Make the API request to delete the selected tasks
        const response = await api.delete("/api/deletetasks", {
          data: { taskIds: selectedTTaskId }, // Send taskIds as an array
        });
        setSelectedTTaskId([]);
        setIndividualDeleteTaskDialogOpen(false);
        setIsCheckboxCheckedForTask(false);
        console.log(response.data.message); // Log success message or alert it to the user
        setTasksByPhase((prevTasksByPhase) => {
          return prevTasksByPhase.map((phase) => {
            // Filter out tasks that have been deleted
            const updatedTasks = phase.tasks.filter(
              (task) => !selectedTTaskId.includes(task.id)
            );

            // Return the updated phase object with the remaining tasks
            return {
              ...phase,
              tasks: updatedTasks,
            };
          });
        });
      } catch (error) {
        console.error("Error details:", error);
        console.error("Error response:", error.response);
        // Optionally, show a user-friendly error message
        toast.error(
          error.response?.data?.message ||
            "An error occurred while deleting tasks.",
          {
            position: "bottom-center",
            autoClose: 2000,
          }
        );
      } finally {
        setSelectedTTaskId([]);
        setIndividualDeleteTaskDialogOpen(false);
        setIsCheckboxCheckedForTask(false);
      }
    } else {
      console.log("No tasks selected for deletion.");
      alert("Please select at least one task to delete.");
    }
  };
  const handleAddNewTask = async () => {
    try {
      // Check if the role name is not empty
      if (!newTask.name.trim()) {
        toast.error("Task name cannot be empty", {
          position: "bottom-center",
          autoClose: 2000,
        });
        return;
      }

      // Send POST request to create a new role
      const response = await api.post(
        `/api/addusertasks/${taskTemplateIdToAdd}/${phaseId}`,
        {
          name: newTask.name,
        }
      );

      // Handle the response
      if (response.status === 201) {
        const newTask = response.data; // Example: { id: 101, name: 'New Task', phase_id: '213' }

        // Update tasksByPhase state to include the new task in the correct phase
        setTasksByPhase((prevTasksByPhase) => {
          return prevTasksByPhase.map((phase) => {
            if (String(phase.id) === String(taskTemplateIdToAdd)) {
              // Add the new task to the "tasks" array of the matching phase
              return {
                ...phase,
                tasks: [...phase.tasks, newTask],
              };
            }
            return phase; // Return unchanged phase if not matched
          });
        });
        toast.success("Task added successfully!", {
          position: "bottom-center",
          autoClose: 2000,
        });
        setAddTaskDial(false);
        setNewTask({ name: "" }); // Clear the input field
      }
    } catch (error) {
      console.error();
      toast.error("Failed to add new role:", error, {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  const handleTabChange = (index) => {
    setTabIndex(index);
    localStorage.setItem("tabIndex", index);
  };

  const handleOpenVariationDialog = () => {
    const suggestedVariationNo = `VO-${String(
      (variations?.length || 0) + 1
    ).padStart(2, "0")}`;
    setVariationNo(suggestedVariationNo);
    setVariationName(`${phase.phaseno}-${suggestedVariationNo}`);
    setSelectedTaskId("");
    setVariationHours(0);
    setIsEditMode(false);
    setOpenVariationDialog(true);
  };
  const handleOpenEditVariationDialog = (variation) => {
    setIsEditMode(true);
    setSelectedVariation(variation);
    setVariationNo(variation.variationno);
    setVariationName(variation.name);
    setSelectedTaskId(variation.taskId);
    setVariationHours(variation.plannedhours);
    setOpenVariationDialog(true);
  };
  const handleAddVariation = async () => {
    const newVariation = {
      variationNo,
      variationName,
      taskId: selectedTaskId,
      variationHours,
      projectId: phase.project_id,
      created_by: userID,
    };
    const addedVariation = await addVariationToPhase(
      phaseId,
      newVariation,
      setError
    );
    if (addedVariation) {
      setVariations((prev) => [...prev, addedVariation]);
      setOpenVariationDialog(false);
      setSelectedTaskId(""); // Reset selected task
      setVariationHours(0); // Reset variation hours
    }
  };

  const handleDeleteVariation = (e, variationId) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // Add phaseId to the selectedPhaseId array
      setVariationToDelete((prev) => [...prev, variationId]);
    } else {
      // Remove phaseId from the selectedPhaseId array
      setVariationToDelete((prev) => prev.filter((id) => id !== variationId));
    }
  };

  //delete variation
  const handleDeleteConfirm = async () => {
    if (variationToDelete.length > 0) {
      try {
        // Send the array of variation IDs to the backend
        await api.delete(`/api/phase/${phaseId}/variations`, {
          data: { variationIds: variationToDelete },
        });

        // Update the state to remove the deleted variations
        setVariations((prevVariations) =>
          prevVariations.filter(
            (variation) => !variationToDelete.includes(variation.id)
          )
        );
      } catch (error) {
        console.error("Error deleting variations:", error);
      } finally {
        setBulkActionVariation(false);
        setDeleteDialogOpen(false);
        setIsCheckboxChecked(false);
        setVariationToDelete([]); // Reset the variations to delete
      }
    } else {
      alert("Please select at least one variation to delete.");
    }
  };

  function normalizePlannedHours(input) {
    // If input is a decimal (e.g., "1.5"), convert it to HH:MM format
    if (/^[0-9]+([.][0-9]+)?$/.test(input)) {
      const hours = Math.floor(parseFloat(input));
      const minutes = Math.round((parseFloat(input) - hours) * 60);
      return `${hours}:${minutes.toString().padStart(2, "0")}`;
    }

    // If input is in HH:MM format, return it directly
    if (/^[0-9]+:[0-5][0-9]$/.test(input)) {
      return input;
    }

    // If input is a whole number, treat it as hours
    if (/^[0-9]+$/.test(input)) {
      return `${parseInt(input, 10)}:00`;
    }

    // For invalid formats, return an empty string
    return "";
  }

  // Handle save or add variation
  const handleSaveOrAddVariation = () => {
    if (isEditMode) {
      handleSaveVariation();
    } else {
      handleAddVariation();
    }
  };

  const handleSaveVariation = async () => {
    try {
      const updatedVariation = {
        ...selectedVariation,
        name: variationName,
        plannedhours: variationHours,
        task_id: selectedTaskId,
      };
      const response = await api.put(
        `/api/phase/${phaseId}/variation/${selectedVariation.id}`,
        updatedVariation
      );
      if (response.status === 200) {
        // Axios uses status codes for response validation
        setVariations(
          variations.map((v) =>
            v.id === selectedVariation.id ? updatedVariation : v
          )
        );
        setOpenVariationDialog(false);
      } else {
        console.error("Error updating variation:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving variation:", error);
    }
  };

  const getColorForStatus = (status) => {
    switch (status) {
      case "Approved":
        return "#a3d6a7";
      case "Rejected":
        return "#FA2929";
      case "Waiting":
        return "orange";
      default:
        return "black";
    }
  };

  const updateClaimStatus = async (
    variationId,
    newValue,
    fieldType,
    userId
  ) => {
    console.log(
      "Variation ID:",
      variationId,
      "New Value:",
      newValue,
      "Field Type:",
      fieldType
    );

    try {
      if (fieldType === "status" && newValue === "Unclaimed") {
        const confirmUnclaim = window.confirm(
          "Are you sure you want to change the status to Unclaimed?"
        );
        if (!confirmUnclaim) {
          return;
        }
      }

      const validFieldTypes = ["status", "date", "number", "variation_status"];
      if (!validFieldTypes.includes(fieldType)) {
        console.error("Invalid field type:", fieldType);
        return;
      }

      let requestData = {};
      if (fieldType === "status") {
        requestData = { status: newValue };
      } else if (fieldType === "date") {
        requestData = { date: newValue ? newValue.toISOString() : null };
      } else if (fieldType === "number") {
        requestData = { invoice_no: newValue };
      } else if (fieldType === "variation_status") {
        requestData = { variation_status: newValue, approved_by: userId };
      }

      console.log("Request Data:", requestData);

      const response = await api.put(
        `/api/phase/${variationId}/approval`,
        requestData
      );

      if (response.status === 200) {
        console.log("Response Data:", response.data);
        const updatedFields = {};
        if (requestData.status)
          updatedFields.invoice_status = requestData.status;
        if (requestData.date) updatedFields.invoice_date = requestData.date;
        if (requestData.invoice_no)
          updatedFields.invoice_no = requestData.invoice_no;
        if (requestData.variation_status)
          updatedFields.variation_status = requestData.variation_status;

        setVariations((prev) =>
          prev.map((v) =>
            v.id === variationId ? { ...v, ...updatedFields } : v
          )
        );

        fetchAllData(); // Refresh the UI data
      } else {
        console.error(
          "Failed to update variation:",
          response.statusText || "No status text"
        );
      }
    } catch (error) {
      console.error("Error updating variation:", error);
    }
  };

  const updateBulkVariationClaimStatus = async (variationIds, newStatus) => {
    try {
      // If the new status is "Unclaimed", show confirmation
      if (newStatus === "Unclaimed") {
        const confirmUnclaim = window.confirm(
          "Are you sure you want to change the status of selected phases to Unclaimed?"
        );

        // If user clicks "No", stop the function
        if (!confirmUnclaim) {
          return; // Exit without making changes
        }
      }

      // Send the bulk update request to the backend
      const response = await api.put("/api/variation/bulk-approval", {
        variationIds,
        status: newStatus,
      });

      console.log("Bulk update response:", response.data);

      if (response.status === 200) {
        // Show success message
        alert(response.data.message);
        setBulkActionVariation(false);
        setIsCheckboxChecked(false);
        setVariationToDelete([]);
        // Update the variations state directly based on the bulk update
        setVariations((prev) =>
          prev.map((v) =>
            variationIds.includes(v.id)
              ? { ...v, invoice_status: newStatus } // Update the status of the modified variations
              : v
          )
        );

        // Optionally fetch updated data
        fetchData(); // If the API updates more fields, you can call this function to refresh the data
      } else {
        // Handle other cases (e.g., no rows updated)
        alert(response.data.message || "No variations updated");
      }
    } catch (error) {
      console.error("Failed to update bulk statuses", error);
      alert(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        variationRef.current &&
        !variationRef.current.contains(event.target)
      ) {
        setBulkActionVariation(false);
      }
      if (issueRef.current && !issueRef.current.contains(event.target)) {
        setBulkAction(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
  }, []);

  //issues
  const addIssueToPhase = async (phaseId, issueData, setError) => {
    try {
      const response = await api.post(
        `/api/phase/${phaseId}/addIssue`,
        issueData
      );
      fetchAllData();
      updateIssueClaimStatus([]);
      setIssueAttendedBy([]);
      setIssueErrorBy([]);
      setIssueRaisedBy([]);
      setIssueType([]);
      setIssueName([]);
      // setIssueNo([]);
      // No need to manually parse JSON; Axios does that automatically
      return response.data;
    } catch (error) {
      setError(error);
    }
  };

  const handleAddIssue = async () => {
    const newIssue = {
      issueNo,
      issueName,
      issueType,
      issueRaisedBy,
      issueErrorBy,
      issueAttendedBy,
      // taskId: selectedTaskId,
      projectId: phase.project_id, // Assuming phase has a project_id field
    };
    console.log("multiple", issueErrorBy, issueAttendedBy);
    const addedIssue = await addIssueToPhase(phaseId, newIssue, setError);
    if (addedIssue) {
      setIssues((prev) => [...prev, addedIssue]);
      setOpenIssueDialog(false);
      setSelectedTaskId(""); // Reset selected task
      setIssueHours(0); // Reset issue hours
    }
  };

  const handleOpenIssueDialog = () => {
    const suggestedIssueNo = `IS-${String((issues?.length || 0) + 1).padStart(
      2,
      "0"
    )}`;
    setIssueNo(suggestedIssueNo);
    setIssueName(`${phase.phaseno}-${suggestedIssueNo}`);
    setSelectedTaskId("");
    setIssueHours(0);
    setIsIssueEditMode(false);
    setOpenIssueDialog(true);
  };

  const handleOpenEditIssueDialog = (issue) => {
    console.log("issue", issue);
    setIsIssueEditMode(true);
    setSelectedIssue(issue);
    setIssueNo(issue.issue_number);
    setIssueName(issue.name);
    setSelectedTaskId(issue.taskId);
    setIssueHours(issue.issuehours);
    setOpenIssueDialog(true);
    setIssueErrorBy(issue.errorby_ids);
    if (issue.type === "internal") {
      setIssueRaisedBy(issue.raisedby_user);
    }
    if (issue.type === "external") {
      setIssueRaisedBy(issue.raisedby_client);
    }
    setIssueType(issue.type);
    setIssueAttendedBy(issue.attendedby_ids);
  };

  const handleSaveIssue = async () => {
    try {
      const updatedIssue = {
        ...selectedIssue,
        name: issueName,
        issuehours: issueHours,
        task_id: selectedTaskId,
        type: issueType,
        ...(issueType === "internal"
          ? { raisedby_user: issueRaisedBy }
          : { raisedby_client: issueRaisedBy }),
        errorby: issueErrorBy,
        attendedby: issueAttendedBy,
      };

      const response = await api.put(
        `/api/phase/${phaseId}/issue/${selectedIssue.id}`,
        updatedIssue
      );

      if (response.status === 200) {
        // Axios does not throw on non-2xx responses, check status manually
        setIssues(
          issues.map((issue) =>
            issue.id === selectedIssue.id ? updatedIssue : issue
          )
        );
        fetchAllData();
        setOpenIssueDialog(false);
        updateIssueClaimStatus([]);
        setIssueAttendedBy([]);
        setIssueErrorBy([]);
        setIssueRaisedBy([]);
        setIssueType([]);
        setIssueName([]);
        // setIssueNo([]);
      } else {
        console.error("Error updating issue:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving issue:", error);
    } finally {
      setIssueAttendedBy([]);
      setIssueErrorBy([]);
      setIssueRaisedBy([]);
      setIssueType([]);
      setIssueName([]);
    }
  };

  const handleDeleteIssue = (e, issueId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add issueId to the state array
      setIssueToDelete((prev) => [...prev, issueId]);
    } else {
      // Remove issueId from the state array if unchecked
      setIssueToDelete((prev) => prev.filter((id) => id !== issueId));
    }
  };

  // Delete issue
  const handleDeleteIssueConfirm = async () => {
    if (issueToDelete.length > 0) {
      try {
        // Send the array of issue IDs to the backend for deletion
        await api.delete(`/api/phase/${phaseId}/issues`, {
          data: { issueIds: issueToDelete },
        });

        // Update the state to remove the deleted issues
        setIssues((prevIssues) =>
          prevIssues.filter((issue) => !issueToDelete.includes(issue.id))
        );
      } catch (error) {
        console.error("Error deleting issues:", error);
      } finally {
        setDeleteIssueDialogOpen(false);
        setIsIssueCheckboxChecked(false); // Reset the checkbox state
        setIssueToDelete([]); // Clear the selected issues after deletion
      }
    } else {
      alert("Please select at least one issue to delete.");
    }
  };

  const updateIssueClaimStatus = async (issueId, newStatus) => {
    try {
      // Make the API call to update the status
      const response = await api.put(`/api/phase/${issueId}/approved`, {
        status: newStatus,
      });

      // Assuming the response contains the updated issue data
      const updatedStatus = {
        id: issueId,
        invoice_status: newStatus, // Update status here
        // If you have other fields to update, include them here.
      };

      // Update the issues state by mapping through and updating the relevant issue
      setIssues((prev) =>
        prev.map((issue) =>
          issue.id === issueId ? { ...issue, ...updatedStatus } : issue
        )
      );
    } catch (error) {
      // Handle errors
      if (error.response) {
        console.error("Response status:", error.response.status);
        console.error("Response data:", error.response.data);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  const updateBulkIssueClaimStatus = async (issueIds, newStatus) => {
    try {
      const response = await api.put("/api/issue/bulk-approval", {
        issueIds,
        status: newStatus,
      });
      console.log("issue id", issueIds);
      if (response.status === 200) {
        alert(response.data.message); // Show success message
        // Optionally, refresh data
      } else {
        alert(response.data.message); // Handle other cases (e.g., no rows updated)
      }
    } catch (error) {
      console.error("Failed to update bulk statuses", error);
      alert(error.response?.data?.message || "An error occurred");
    }
  };

  if (loading)
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  if (error)
    return (
      <p color="error" className="error-message">
        Error: {error.message}
      </p>
    );
  if (!phase) return <Typography>No phase found</Typography>;

  const totalVariationHours = variations.reduce((total, variation) => {
    // Convert plannedhours (HH:mm:ss) into total minutes
    const timeParts = (variation.plannedhours || "00:00:00").split(":");
    const hoursInMinutes = parseInt(timeParts[0], 10) * 60; // Convert hours to minutes
    const minutes = parseInt(timeParts[1], 10); // Minutes

    const totalMinutes = hoursInMinutes + minutes;

    // Add the total minutes to the running total
    return total + totalMinutes;
  }, 0);

  // Convert total minutes back to HH:mm format
  const hours = Math.floor(totalVariationHours / 60);
  const minutes = totalVariationHours % 60;

  // Format the total time as HH:mm
  const formattedTotalVariationHours = `${hours
    .toString()
    .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

  const allUnclaimed = variations.every(
    (variation) => variation.invoice_status === "Unclaimed"
  );
  const allIssuesUnclaimed = issues.every(
    (issue) => issue.invoice_status === "Unclaimed"
  );

  const exportToExcelVariation = () => {
    // Helper function to format the date
    const formatDate = (dateString) => {
      if (!dateString) return "";
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    // Create an array to hold the worksheet data
    const wsData = [];

    // Add Phase Title as the first row in the worksheet
    const phaseHeader = [
      {
        v: `Phase Title: ${phase.title}`,
        s: { font: { bold: true, sz: 14 } },
      },
    ];
    wsData.push(phaseHeader);

    // Add headers for the table (Variation Number, Variation Name, etc.)
    const headers = [
      "S.No",
      "Variation Number",
      "Variation Name",
      "Planned Hours",
      "Actual Hours",
      "Claim Status",
      "Invoice Number",
      "Invoice Date",
    ];
    wsData.push(headers);

    // Loop through each variation and extract its data
    variations.forEach((variation, index) => {
      const rowData = [];

      // Add serial number (S.No)
      rowData.push(index + 1);

      // Add Variation Number
      rowData.push(variation.variationno);

      // Add Variation Name
      rowData.push(variation.name);

      // Add Planned Hours (substring to match hh:mm format)
      rowData.push(variation.plannedhours.substring(0, 5));

      // Add Actual Hours or default to 00:00
      rowData.push(variation.actualhours || "00:00");

      // Add Claim Status
      rowData.push(variation.invoice_status);

      // Add Invoice Number (direct from variation object)
      rowData.push(variation.invoice_no || "");

      // Add Invoice Date (formatted to dd-MM-yyyy)
      rowData.push(formatDate(variation.invoice_date));

      // Add the row to the worksheet data
      wsData.push(rowData);
    });
    console.log("ws data", wsData);

    // Create a new worksheet from the data
    const newWs = XLSX.utils.aoa_to_sheet(wsData);

    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, newWs, "Variations");

    // Generate file name using phase.title
    const fileName = `${phase.title.replace(
      /[^a-zA-Z0-9]/g,
      "_"
    )}_variations.xlsx`;

    // Trigger file download
    XLSX.writeFile(wb, fileName);
  };

  const exportToExcel = () => {
    // Create a new worksheet with custom headers including the phase title
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".variation-table")
    );

    // Set phase.title as the first row of the Excel file
    const phaseHeader = [
      { v: `Phase Title: ${phase.title}`, s: { font: { bold: true, sz: 14 } } },
    ];

    // Convert the worksheet data into an array of objects (JSON)
    const wsData = XLSX.utils.sheet_to_json(ws, { header: 1 });

    // Insert the 'S.No' column at the start of each row in wsData
    const dataWithSerialNo = wsData.map((row, index) => {
      if (index === 0) {
        // Add 'S.No' header for the first row
        return ["S.No", ...row];
      } else {
        // Add serial number for subsequent rows
        return [index, ...row];
      }
    });

    // Combine the phaseHeader with the dataWithSerialNo (Phase Title + Data)
    const fullData = [phaseHeader, ...dataWithSerialNo];

    // Create a new worksheet with the updated data
    const newWs = XLSX.utils.aoa_to_sheet(fullData);

    // Create the workbook and set the sheet name
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, newWs, "Issues");

    // Use the phase.title for the Excel file name
    const fileName = `${phase.title.replace(/[^a-zA-Z0-9]/g, "_")}_issues.xlsx`; // Replace spaces or special chars

    // Download the Excel file with the name based on phase.title
    XLSX.writeFile(wb, fileName);
  };

  return (
    <>
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      {!dialBoxopen && (
        <div className={isNavBarOpen ? "phase-details" : "phase-details-close"}>
          <div
            style={{
              marginTop: isNavBarOpen ? "0px" : "126px",
              marginLeft: isNavBarOpen ? "45px" : "0px",
              backgroundColor: "transparent",
              fontFamily: '"Lato", sans-serif',
              fontSize: "15px",
            }}
          >
            <div style={{ display: "flex" }}>
              <h3 className="phase-title">Phase No : {phase.phaseno}</h3>
              <h3 className="phase-title">Phase Title : {phase.title}</h3>

              <h3 className="phase-title">
                Planned Hours : {phase.total_planned_hours}
              </h3>
            </div>
            <div className="tabs">
              <button
                onClick={() => handleTabChange(0)}
                className={`tab-button ${tabIndex === 0 ? "active" : ""}`}
              >
                General
              </button>
              <button
                onClick={() => handleTabChange(1)}
                className={`tab-button ${tabIndex === 1 ? "active" : ""}`}
              >
                Variations
              </button>
              <button
                onClick={() => handleTabChange(2)}
                className={`tab-button ${tabIndex === 2 ? "active" : ""}`}
              >
                Issues
              </button>
            </div>
            <ToastContainer />
            {tabIndex === 0 && (
              <div className="phase-taks-template-table">
                {hasPermission("projects", "update") && (
                  <>
                    {taskTemplates.some(
                      (template) =>
                        !tasksByPhase.some((task) => task.id === template.id)
                    ) ? (
                      <>
                        <select
                          label="Task Template"
                          value={taskTemplateId}
                          style={{
                            backgroundColor: "white",
                            padding: "10px",
                            borderRadius: "3px",
                            fontFamily: '"Lato", sans-serif',
                            fontSize: "15px",
                            cursor: "pointer",
                          }}
                          onChange={(e) => setTaskTemplateId(e.target.value)}
                          displayEmpty
                          fullWidth
                        >
                          <option value="" disabled>
                            Select a task template
                          </option>
                          {taskTemplates
                            .filter(
                              (template) =>
                                !tasksByPhase.some(
                                  (task) => task.id === template.id
                                )
                            ) // Filtering unassociated templates
                            .map((template) => (
                              <option key={template.id} value={template.id}>
                                {template.name}
                              </option>
                            ))}
                        </select>
                        <button
                          onClick={handleAddTask}
                          variant="contained"
                          color="primary"
                          fullWidth
                          style={{
                            marginTop: "16px",
                            textWrap: "nowrap",
                            width: "auto",
                            fontFamily: '"Lato", sans-serif',
                            fontSize: "13px",
                            cursor: "pointer",
                          }}
                          className="add-btn"
                          title="Add Task"
                        >
                          Add Task Template
                        </button>
                      </>
                    ) : (
                      <p>All tasks are selected</p>
                    )}
                  </>
                )}
                {/* Display associated tasks */}
                <div style={{ display: "flex" }}>
                  <h3 className="task-name">Associated Tasks:</h3>
                  {hasPermission("projects", "delete") &&
                    selectedTTaskId.length !== 0 && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent toggle from firing
                          handleDeleteTask();
                        }}
                        className={
                          isNavBarOpen
                            ? "add-user-task-btn"
                            : "add-user-task-btn-close"
                        }
                        title="Delete Task"
                      >
                        {/* <DeleteIcon style={{ color: "red" }} /> */}
                        Delete Task
                      </button>
                    )}
                </div>

                <div className="phase-associated-tasks">
                  {tasksByPhase.length > 0 ? (
                    tasksByPhase.map((taskTemplate, index) => (
                      <div
                        key={taskTemplate.id}
                        className={
                          isNavBarOpen ? "accordion" : "accordion-close"
                        }
                      >
                        <div
                          className="accordion-summary"
                          style={{ display: "flex", fontWeight: "normal" }}
                          onClick={() => handleToggle(index)}
                        >
                          <h6 className="phase-table-title">
                            {taskTemplate.name}
                          </h6>

                          {hasPermission("projects", "delete") && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent toggle from firing
                                handleDeleteClick(taskTemplate.id);
                              }}
                              style={{
                                marginLeft: "auto",
                                marginRight: "10px",
                                border: "none",
                                cursor: "pointer",
                              }}
                              title="Delete Task Template"
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </button>
                          )}
                        </div>
                        {/* {expandedIndex === index && ( */}
                        <div className="accordion-details">
                          <table className="accordion-table">
                            <thead>
                              <tr>
                                {hasPermission("projects", "delete") && (
                                  <th style={{ width: "2px" }}>
                                    <FaChevronDown />
                                  </th>
                                )}
                                <th className="task-name">Task Name</th>
                                <th className="task-user-name">Assigned to</th>
                                <th className="general-associated-task-table">
                                  Start Date
                                </th>
                                <th className="general-associated-task-table">
                                  Due Date
                                </th>

                                <th className="general-associated-task-table">
                                  Planned Hours
                                </th>

                                <th className="actual-hours">Actual Hours</th>

                                <th className="actual-hours">Difference</th>

                                <th
                                  style={{
                                    width: !hasPermission("phases", "update")
                                      ? "1300px"
                                      : "300px",
                                  }}
                                ></th>
                              </tr>
                            </thead>
                            <tbody>
                              {taskTemplate.tasks &&
                                taskTemplate.tasks.map((task) => (
                                  <tr key={task.id}>
                                    {hasPermission("projects", "delete") && (
                                      <td style={{ textAlign: "center" }}>
                                        <input
                                          style={{ cursor: "pointer" }}
                                          type="checkbox"
                                          name={task.name}
                                          checked={selectedTTaskId.includes(
                                            task.id
                                          )}
                                          onChange={(e) =>
                                            handleCheckboxChange(e, task.id)
                                          }
                                        />
                                      </td>
                                    )}
                                    <td>{task.name}</td>
                                    <td>
                                      {hasPermission("phases", "update") ? (
                                        <MultiSelect
                                          options={options}
                                          value={
                                            task?.assigned_users?.map(
                                              (user) => ({
                                                label: user.label,
                                                value: parseInt(user.value, 10),
                                              })
                                            ) || []
                                          }
                                          onChange={(selected) =>
                                            handleTaskUser(task.id, selected)
                                          }
                                          labelledBy="Select Users"
                                          className="task-assigned-user"
                                        />
                                      ) : (
                                        <ul
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            listStyle: "none", // Removes bullet points
                                            padding: 0, // Resets padding for consistency
                                          }}
                                        >
                                          {task?.assigned_users
                                            ?.slice(0, 4)
                                            .map((user, index, arr) => (
                                              <li
                                                key={user.value}
                                                style={{
                                                  marginRight:
                                                    index < arr.length - 1
                                                      ? "5px"
                                                      : "0",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {user.label}
                                                {index < arr.length - 1 && ","}
                                              </li>
                                            ))}
                                          {task?.assigned_users?.length > 4 && (
                                            <li
                                              style={{
                                                marginLeft: "5px",
                                                whiteSpace: "nowrap",
                                              }}
                                            >
                                              + {task.assigned_users.length - 4}{" "}
                                              more
                                            </li>
                                          )}
                                        </ul>
                                      )}
                                    </td>
                                    <td style={{ padding: "0px" }}>
                                      <DatePicker
                                        placeholderText="Start Date"
                                        className="task-date-input"
                                        selected={
                                          task.start_date
                                            ? parseISO(task.start_date)
                                            : null
                                        } // Use parseISO for ISO date strings
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) =>
                                          handleDateChange(
                                            taskTemplate.id,
                                            task.id,
                                            "start_date",
                                            format(date, "yyyy-MM-dd") // Format to send to the server in 'yyyy-MM-dd'
                                          )
                                        }
                                        minDate={project_start_date} // Ensure it's a Date object (parsed as well)
                                        maxDate={project_due_date} // Ensure it's a Date object (parsed as well)
                                        disabled={
                                          !hasPermission("phases", "update")
                                        }
                                      />
                                    </td>

                                    <td style={{ padding: "0px" }}>
                                      <DatePicker
                                        placeholderText="Due Date"
                                        className="task-date-input"
                                        selected={
                                          task.due_date
                                            ? parseISO(task.due_date)
                                            : null
                                        } // Use parseISO for ISO date strings
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => {
                                          console.log(date); // Handle date selection (returns a Date object)
                                          handleDateChange(
                                            taskTemplate.id,
                                            task.id,
                                            "due_date",
                                            format(date, "yyyy-MM-dd") // Format to send to the server in 'yyyy-MM-dd'
                                          );
                                        }}
                                        minDate={project_start_date} // Ensure it's a Date object (parsed as well)
                                        maxDate={project_due_date} // Ensure it's a Date object (parsed as well)
                                        disabled={
                                          !hasPermission("phases", "update")
                                        }
                                      />
                                    </td>

                                    <td
                                      style={{
                                        padding: "0px",
                                        width: "110px",
                                      }}
                                    >
                                      <div className="task-planned-hours">
                                        <input
                                          type="text"
                                          defaultValue={
                                            task.planned_hours === "00:00"
                                              ? ""
                                              : task.planned_hours
                                          }
                                          onChange={(e) =>
                                            handlePlannedHoursChange(
                                              task.id,
                                              e,
                                              taskTemplate.id
                                            )
                                          }
                                          onBlur={() =>
                                            handleSavePlannedHours(task.id)
                                          }
                                          placeholder={"Planned hours"}
                                          className="planned-hous-phase-input"
                                          disabled={
                                            !hasPermission("projects", "update")
                                          }
                                          style={{
                                            cursor: hasPermission(
                                              "projects",
                                              "update"
                                            )
                                              ? "pointer"
                                              : "not-allowed",
                                          }}
                                        />
                                      </div>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <input
                                        type="text"
                                        defaultValue={task.actualhours}
                                        disabled
                                        placeholder="Actual hours"
                                        className="planned-hous-phase-input"
                                        style={{ cursor: "not-allowed" }}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        color:
                                          task.planned_hours && task.actualhours
                                            ? convertTimeToMinutes(
                                                task.planned_hours
                                              ) -
                                                convertTimeToMinutes(
                                                  task.actualhours
                                                ) >=
                                              0
                                              ? "green"
                                              : "red"
                                            : "inherit",
                                      }}
                                    >
                                      {task.planned_hours && task.actualhours
                                        ? convertMinutesToHHMM(
                                            convertTimeToMinutes(
                                              task.planned_hours
                                            ) -
                                              convertTimeToMinutes(
                                                task.actualhours
                                              )
                                          )
                                        : ""}
                                    </td>
                                    <td></td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {hasPermission("projects", "update") && (
                            <button
                              className="add-general-btn"
                              onClick={() => {
                                setAddTaskDial(true);
                                setTaskTemplateIdToAdd(taskTemplate.id);
                              }}
                            >
                              Add Task
                            </button>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="phase-image-container">
                      <img
                        className="phase-image-variations"
                        src="/AppLayout/variation.png"
                      />
                      <p className="phase-message-variations">
                        No tasks associated with this phase.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
            {deleteTaskDialogOpen && (
              <div className="delete-var-issue-dial">
                <div className="delete-var-dial">
                  <div className="delete-var-header">
                    <h3 className="delete-variation">Delete Task Template</h3>
                  </div>
                  <div style={{ backgroundColor: "white" }}>
                    <p className="delete-t-para">
                      Are you sure you want to delete this Task Template? This
                      action cannot be undone.
                    </p>
                    <input
                      style={{ cursor: "pointer" }}
                      type="checkbox"
                      checked={isCheckboxChecked}
                      id="tick"
                      onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                    />
                    <label
                      htmlFor="tick"
                      style={{
                        backgroundColor: "white",
                        userSelect: "none",
                        cursor: "pointer",
                      }}
                    >
                      I understand this action cannot be undone.
                    </label>
                  </div>
                  <div className="delete-btn-issue-del-con">
                    <button
                      onClick={handleDeleteTaskTemplate}
                      color="secondary"
                      variant="contained"
                      disabled={!isCheckboxChecked}
                      style={{
                        backgroundColor: isCheckboxChecked ? "red" : "grey",
                      }}
                      className="add-btn"
                    >
                      Delete
                    </button>
                    <button
                      onClick={() => {
                        setDeleteTaskDialogOpen(false);
                        setIsCheckboxChecked(false);
                      }}
                      className="cancel-btn"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            {deleteIndividualTaskDialogOpen && (
              <div className="delete-var-issue-dial">
                <div className="delete-var-dial">
                  <div className="delete-var-header">
                    <h3 className="delete-variation">Delete Task</h3>
                  </div>
                  <div style={{ backgroundColor: "white" }}>
                    <p className="delete-t-para">
                      Are you sure you want to delete this Task? This action
                      cannot be undone.
                    </p>
                    <input
                      style={{ cursor: "pointer" }}
                      type="checkbox"
                      checked={isCheckboxCheckedForTask}
                      id="tick"
                      onChange={() =>
                        setIsCheckboxCheckedForTask(!isCheckboxCheckedForTask)
                      }
                    />
                    <label
                      htmlFor="tick"
                      style={{
                        backgroundColor: "white",
                        userSelect: "none",
                        cursor: "pointer",
                      }}
                    >
                      I understand this action cannot be undone.
                    </label>
                  </div>
                  <div className="delete-btn-issue-del-con">
                    <button
                      onClick={handleDeleteAssociatedTask}
                      color="secondary"
                      variant="contained"
                      disabled={!isCheckboxCheckedForTask}
                      style={{
                        backgroundColor: isCheckboxCheckedForTask
                          ? "red"
                          : "grey",
                      }}
                      className="add-btn"
                    >
                      Delete
                    </button>
                    <button
                      onClick={() => {
                        setIndividualDeleteTaskDialogOpen(false);
                        setIsCheckboxCheckedForTask(false);
                      }}
                      className="cancel-btn"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            {addTaskDial && (
              <div className="add-role-dial">
                <div className="add-role-settings">
                  <div className="add-role-header">
                    <h3 className="add-new-user-heading">Add Task</h3>
                  </div>
                  <h3 className="label-header-element">Task Name</h3>
                  <input
                    className="user-element"
                    placeholder="Enter Task Name"
                    type="text"
                    value={newTask.name}
                    onChange={(e) => setNewTask({ name: e.target.value })}
                  />
                  <div className="task-btn-container">
                    <button className="add-btn" onClick={handleAddNewTask}>
                      Add
                    </button>
                    <button
                      className="cancel-btn"
                      onClick={() => {
                        setAddTaskDial(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/*Variation tab*/}
            {tabIndex === 1 && (
              <Box>
                <>
                  {variationToDelete.length > 0 && (
                    <button
                      onClick={() => setBulkActionVariation((prev) => !prev)}
                      className="add-btn mass-update-var-issue"
                    >
                      Mass Update
                    </button>
                  )}
                  {/* {hasPermission("projects", "delete") &&
                    variationToDelete.length > 0 && (
                      <button
                        className="add-btn add-issue-btn delete-var-issue-btn"
                        onClick={() => setDeleteDialogOpen(true)}
                        title="Delete Variation"
                      >
                        Delete
                      </button>
                    )} */}
                  {hasPermission("variations", "create") && (
                    <>
                      <button
                        onClick={exportToExcelVariation}
                        className="export-issue"
                      >
                        Export to Excel
                      </button>
                      <button
                        onClick={handleOpenVariationDialog}
                        className="add-btn variation-btn"
                        title="New Variation"
                      >
                        New Variation
                      </button>
                    </>
                  )}
                </>

                {variations.length === 0 && (
                  <div className="phase-image-container">
                    <img
                      className="phase-image-variations"
                      src="/AppLayout/variation.png"
                    />
                    <p className="phase-message-variations">
                      No variation available for this project
                    </p>
                  </div>
                )}

                {variations.length !== 0 && (
                  <div
                    className={
                      isNavBarOpen
                        ? "variation-table-container"
                        : "variation-table-container-close"
                    }
                  >
                    <table
                      className={
                        isNavBarOpen
                          ? "variation-table"
                          : "variation-table-close"
                      }
                    >
                      <thead>
                        <tr>
                          {hasPermission("variations", "delete") && (
                            <th style={{ width: "2px" }}>
                              <FaChevronDown />
                            </th>
                          )}

                          <th style={{ width: "150px" }}>Variation No</th>
                          <th style={{ width: "150px" }}>Variation Name</th>
                          <th style={{ width: "150px" }}>Planned Hours</th>
                          <th style={{ width: "150px" }}>Actual Hours</th>
                          <th style={{ width: "150px" }}>Claim Status</th>
                          <th style={{ width: "150px" }}>Variation Status</th>
                          <th>Created By</th>
                          <th>Approved By</th>
                          {!allUnclaimed && (
                            <>
                              <th style={{ width: "150px" }}>Invoice No</th>
                              <th style={{ width: "150px" }}>Invoice Date</th>
                              {/* <th></th> */}
                            </>
                          )}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {variations.map((variation, index) => (
                          <tr key={index}>
                            {hasPermission("variations", "delete") && (
                              <td>
                                <input
                                  style={{ cursor: "pointer" }}
                                  type="checkbox"
                                  name={variation.name}
                                  checked={variationToDelete.includes(
                                    variation.id
                                  )}
                                  onChange={(e) =>
                                    handleDeleteVariation(e, variation.id)
                                  }
                                />
                              </td>
                            )}
                            <td style={{ whiteSpace: "nowrap" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span style={{ marginTop: "6px" }}>
                                  {variation.variationno}
                                </span>
                                {hasPermission("projects", "update") && (
                                  <button
                                    style={{
                                      width: "50px",
                                      border: "none",
                                      paddingRight: "50px",
                                      color: "#0000ff",
                                      backgroundColor: "transparent",
                                    }}
                                    onClick={() =>
                                      handleOpenEditVariationDialog(variation)
                                    }
                                    className="edit-icon-wrapper"
                                    title="Edit Variation"
                                  >
                                    <EditIcon />
                                  </button>
                                )}
                              </div>
                            </td>{" "}
                            <td style={{ whiteSpace: "nowrap" }}>
                              {variation.name}
                            </td>
                            <td style={{ width: "10px", textAlign: "center" }}>
                              {variation.plannedhours.substring(
                                0,
                                variation.plannedhours.length - 3
                              )}
                            </td>
                            <td style={{ width: "10px", textAlign: "center" }}>
                              {variation.actualhours
                                ? variation.actualhours
                                : "00:00"}
                            </td>
                            <td
                              style={{
                                backgroundColor:
                                  variation.invoice_status === "Claimed"
                                    ? "white"
                                    : "null",
                                color: "white",
                                width: "140px",
                                textAlign: "center",
                                fontWeight: "500",
                              }}
                            >
                              <select
                                style={{
                                  border: "none",
                                  backgroundColor:
                                    variation.invoice_status === "Claimed"
                                      ? "#a3d6a7"
                                      : "#d2b48c",
                                  color: "white",
                                  borderRadius: "3px",
                                  paddingLeft: "38px",
                                  paddingRight: "28px",
                                  fontWeight: "500",
                                  cursor: !hasPermission("projects", "update")
                                    ? "not-allowed"
                                    : "",
                                  outline: "none",
                                  height: "34px",
                                  fontFamily: '"Lato", sans-serif',
                                  fontSize: "15px",
                                  cursor: "pointer",
                                }}
                                onChange={
                                  (e) =>
                                    updateClaimStatus(
                                      variation.id,
                                      e.target.value,
                                      "status"
                                    ) // Update only the status
                                }
                                value={variation.invoice_status}
                                disabled={!hasPermission("projects", "update")}
                              >
                                <option
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    fontWeight: "500",
                                  }}
                                  value="Unclaimed"
                                >
                                  Unclaimed
                                </option>
                                <option
                                  style={{
                                    backgroundColor: "white",
                                    color: "black",
                                    fontWeight: "500",
                                  }}
                                  value="Claimed"
                                >
                                  Claimed
                                </option>
                              </select>
                            </td>
                            <td>
                              <select
                                className="variation-status-dropdown"
                                value={variation.variation_status}
                                onChange={
                                  (e) =>
                                    updateClaimStatus(
                                      variation.id,
                                      e.target.value,
                                      "variation_status",
                                      userID
                                    ) // Update only the date
                                }
                                style={{
                                  backgroundColor: getColorForStatus(
                                    variation.variation_status
                                  ),
                                }}
                                disabled={
                                  !hasPermission("variations", "update")
                                }
                              >
                                <option value={""} disabled>
                                  Select Status
                                </option>
                                <option value={"Approved"}>Approved</option>
                                <option value={"Rejected"}>Rejected</option>
                                <option value={"Waiting"}>Waiting</option>
                              </select>
                            </td>
                            <td>{variation.created_by_name}</td>
                            <td>{variation.approved_by_name}</td>
                            {!allUnclaimed && (
                              <>
                                <td>
                                  <input
                                    value={variation.invoice_no}
                                    className="invoice-element"
                                    placeholder="Enter Invoice No"
                                    type="text"
                                    onChange={
                                      (e) =>
                                        updateClaimStatus(
                                          variation.id,
                                          e.target.value,
                                          "number"
                                        ) // Update only the date
                                    }
                                    style={{
                                      cursor: !hasPermission(
                                        "projects",
                                        "update"
                                      )
                                        ? "not-allowed"
                                        : "",
                                      width: "150px",
                                    }}
                                    disabled={
                                      variation.invoice_status ===
                                        "Unclaimed" ||
                                      !hasPermission("projects", "update")
                                    }
                                  />
                                </td>
                                <td>
                                  <DatePicker
                                    onChange={
                                      (date) =>
                                        updateClaimStatus(
                                          variation.id,
                                          date,
                                          "date"
                                        ) // Update only the date
                                    }
                                    selected={
                                      variation.invoice_date &&
                                      isValid(new Date(variation.invoice_date))
                                        ? new Date(variation.invoice_date)
                                        : null
                                    }
                                    disabled={
                                      variation.invoice_status ===
                                        "Unclaimed" ||
                                      !hasPermission("projects", "update")
                                    }
                                    placeholderText="Select Date"
                                    className="invoice-input"
                                    dateFormat="dd-MM-yyyy"
                                  />
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="variation-summary">
                      <p className="variation-para-hours">
                        Total Variation Hours: {formattedTotalVariationHours}
                      </p>
                      <p className="variation-para">
                        Total No of Variations: {variations.length}
                      </p>
                    </div>
                  </div>
                )}
                {bulkActionVariation && (
                  <div className="action-box-variation" ref={variationRef}>
                    {hasPermission("projects", "delete") &&
                      variationToDelete.length > 0 && (
                        <button
                          className="add-btn"
                          onClick={() => setDeleteDialogOpen(true)}
                          title="Delete Variation"
                          style={{
                            backgroundColor: "red",
                            fontFamily: '"Lato", sans-serif',
                            fontSize: "13px",
                            cursor: "pointer",
                          }}
                        >
                          Delete
                        </button>
                      )}
                    {hasPermission("projects", "update") && (
                      <button
                        onClick={() => setClaimStatusVariation((prev) => !prev)}
                        className="add-btn"
                        style={{
                          textWrap: "nowrap",
                          fontFamily: '"Lato", sans-serif',
                          fontSize: "13px",
                          cursor: "pointer",
                        }}
                      >
                        Claim Status
                      </button>
                    )}
                    {claimStatusVariation &&
                      hasPermission("projects", "update") && (
                        <select
                          onChange={(e) =>
                            updateBulkVariationClaimStatus(
                              variationToDelete,
                              e.target.value
                            )
                          }
                          className="bulk-select"
                        >
                          <option>Select Claim Status</option>
                          <option>Claimed</option>
                          <option>Unclaimed</option>
                        </select>
                      )}
                  </div>
                )}
                {/*add variation dial box*/}
                {openVariationDialog && (
                  <div className="add-variation-dial">
                    <div className="dial-v-header">
                      <h3 className="add-edit-variation">
                        {isEditMode ? "Edit Variation" : "Add Variation"}
                      </h3>
                    </div>
                    <div className="add-variation-content">
                      <h3 className="variation-label">Variation No</h3>
                      <input
                        label="Variation No"
                        value={variationNo || ""}
                        fullWidth
                        onChange={(e) => setVariationNo(e.target.value)}
                        margin="dense"
                        disabled={isEditMode} // Disable editing of variation number
                        className="variation-number"
                      />
                      <h3 className="variation-label">Variation Name</h3>
                      <input
                        label="Variation Name"
                        value={variationName || ""}
                        fullWidth
                        onChange={(e) => setVariationName(e.target.value)}
                        margin="dense"
                        className="variation-name"
                      />
                      <h3 className="variation-label">Variation Hours</h3>
                      <input
                        placeholder="Enter Variation Hours"
                        type="text"
                        value={variationHours || ""}
                        onChange={(e) => setVariationHours(e.target.value)}
                        onBlur={(e) =>
                          setVariationHours(
                            normalizePlannedHours(e.target.value)
                          )
                        }
                        fullWidth
                        margin="dense"
                        className="variation-hours"
                      />
                      {/* {isEditMode && (
                        <>
                          <h3 className="variation-label">Invoice Status</h3>
                          <select
                            className="variation-hours"
                            onChange={
                              (e) =>
                                updateClaimStatus(
                                  selectedVariation.id,
                                  e.target.value
                                ) // Update status
                            }
                            value={selectedVariation.invoice_status}
                          >
                            <option disabled>Select Invoice Status</option>
                            <option>Unclaimed</option>
                            <option>Claimed</option>
                          </select>
                        </>
                      )} */}
                    </div>

                    <div className={"add-variation-btn-con-second"}>
                      <button
                        onClick={handleSaveOrAddVariation}
                        style={{ marginLeft: "21px" }}
                        color="primary"
                        variant="contained"
                        className="add-edit-issue-btn"
                      >
                        {isEditMode ? "Save" : "Add"}
                      </button>
                      <button
                        onClick={() => setOpenVariationDialog(false)}
                        color="secondary"
                        className="cancel-btn"
                        style={{ marginRight: "140px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}

                {/* Delete Confirmation Dialog */}
                {deleteDialogOpen && (
                  <div className="delete-var-issue-dial">
                    <div className="delete-var-dial">
                      <div className="delete-var-header">
                        <h3 className="delete-variation">Delete Variation</h3>
                      </div>
                      <div style={{ backgroundColor: "white" }}>
                        <p className="delete-t-para">
                          Are you sure you want to delete this variation? This
                          action cannot be undone.
                        </p>
                        <input
                          style={{ cursor: "pointer" }}
                          type="checkbox"
                          checked={isCheckboxChecked}
                          id="tick"
                          onChange={() =>
                            setIsCheckboxChecked(!isCheckboxChecked)
                          }
                        />
                        <label
                          htmlFor="tick"
                          style={{
                            backgroundColor: "white",
                            userSelect: "none",
                            cursor: "pointer",
                          }}
                        >
                          I understand this action cannot be undone.
                        </label>
                      </div>
                      <div className="delete-btn-issue-del-con">
                        <button
                          onClick={handleDeleteConfirm}
                          color="secondary"
                          variant="contained"
                          disabled={!isCheckboxChecked}
                          style={{
                            backgroundColor: isCheckboxChecked ? "red" : "grey",
                          }}
                          className="add-btn"
                        >
                          Delete
                        </button>
                        <button
                          onClick={() => {
                            setDeleteDialogOpen(false);
                            setIsCheckboxChecked(false);
                            setVariationToDelete([]);
                          }}
                          className="cancel-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {/*delete task dial*/}
              </Box>
            )}
            {/*Issue tab*/}
            {tabIndex === 2 && (
              <Box>
                <>
                  {issueToDelete.length > 0 && (
                    <button
                      onClick={() => setBulkAction((prev) => !prev)}
                      className="add-btn mass-update-var-issue"
                    >
                      Mass Update
                    </button>
                  )}
                  {/* {hasPermission("projects", "delete") &&
                    issueToDelete.length > 0 && (
                      <button
                        className="add-btn add-issue-btn delete-var-issue-btn"
                        onClick={() => setDeleteIssueDialogOpen(true)}
                        title="Delete Issue"
                      >
                        Delete
                      </button>
                    )} */}
                  {hasPermission("issues", "create") && (
                    <>
                      <button onClick={exportToExcel} className="export-issue">
                        Export to Excel
                      </button>
                      <button
                        onClick={handleOpenIssueDialog}
                        style={{ textWrap: "nowrap", width: "auto" }}
                        className="add-btn add-issue-btn"
                        title="New Issue"
                      >
                        New Issue
                      </button>
                    </>
                  )}
                </>

                <div
                  className={
                    isNavBarOpen
                      ? "variation-table-container"
                      : "variation-table-container-close"
                  }
                >
                  {issues.length === 0 && (
                    <div className="phase-image-container">
                      <img
                        className="phase-image-issues"
                        src="/AppLayout/Issue.png"
                      />
                      <p className="phase-message-issues">
                        No issue available for this project
                      </p>
                    </div>
                  )}

                  {issues.length !== 0 && (
                    <div
                      className={
                        isNavBarOpen
                          ? "variation-table-container"
                          : "variation-table-container-close"
                      }
                    >
                      <table
                        className={
                          isNavBarOpen
                            ? "variation-table"
                            : "variation-table-close"
                        }
                      >
                        <thead>
                          <tr>
                            {hasPermission("projects", "delete") && (
                              <th style={{ width: "2px" }}>
                                <FaChevronDown />
                              </th>
                            )}

                            <th style={{ width: "150px" }}>Issue No</th>
                            <th style={{ width: "150px" }}>Issue Name</th>
                            <th style={{ width: "150px" }}>Issue Type</th>
                            <th style={{ width: "150px" }}>Raised By</th>
                            <th style={{ width: "150px" }}>Error By</th>
                            <th style={{ width: "150px" }}>Attended By</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {issues.map((issue, index) => (
                            <tr key={index}>
                              {hasPermission("issues", "delete") && (
                                <td>
                                  <input
                                    style={{ cursor: "pointer" }}
                                    type="checkbox"
                                    name={issue.name}
                                    checked={issueToDelete.includes(issue.id)} // Make sure this works properly
                                    onChange={(e) =>
                                      handleDeleteIssue(e, issue.id)
                                    }
                                  />
                                </td>
                              )}
                              <td style={{ whiteSpace: "nowrap" }}>
                                {" "}
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span style={{ marginTop: "6px" }}>
                                    {issue.issue_number}
                                  </span>
                                  {hasPermission("issues", "update") && (
                                    <button
                                      style={{
                                        width: "50px",
                                        border: "none",
                                        paddingRight: "50px",
                                        color: "#0000ff",
                                        backgroundColor: "transparent",
                                      }}
                                      onClick={() =>
                                        handleOpenEditIssueDialog(issue)
                                      }
                                      className="edit-icon-wrapper"
                                      title="Edit Issue"
                                    >
                                      <EditIcon />
                                    </button>
                                  )}{" "}
                                </div>
                              </td>{" "}
                              <td style={{ whiteSpace: "nowrap" }}>
                                {issue.name}
                              </td>
                              <td
                                style={{ width: "10px", textAlign: "center" }}
                              >
                                {issue.type.charAt(0).toUpperCase() +
                                  issue.type.slice(1)}
                              </td>
                              <td>{issue.raisedby_name}</td>
                              <td>
                                <ul>
                                  {issue.errorby_names?.map(
                                    (user, index, arr) => (
                                      <li>{user},</li>
                                    )
                                  )}
                                </ul>
                              </td>
                              <td>
                                <ul>
                                  {issue.attendedby_names?.map(
                                    (user, index, arr) => (
                                      <li>
                                        {user},{""}{" "}
                                      </li>
                                    )
                                  )}
                                </ul>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="issue-summary">
                        <p className="issue-para">
                          Total No of Issues: {issues.length}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {bulkAction && (
                  <div className="action-box-variation" ref={issueRef}>
                    {hasPermission("projects", "delete") && (
                      <button
                        className="add-btn"
                        onClick={() => setDeleteIssueDialogOpen(true)}
                        title="Delete Issue"
                        style={{ backgroundColor: "red" }}
                      >
                        Delete
                      </button>
                    )}
                    {/* {hasPermission("projects", "update") && (
                      <button
                        onClick={() => setClaimStatusIssue((prev) => !prev)}
                        className="add-btn"
                        style={{ textWrap: "nowrap" }}
                      >
                        Claim Status
                      </button>
                    )}
                    {claimStatusIssue &&
                      hasPermission("projects", "update") && (
                        <select
                          onChange={(e) =>
                            updateBulkIssueClaimStatus(
                              issueToDelete,
                              e.target.value
                            )
                          }
                          className="bulk-select"
                        >
                          <option>Select Claim Status</option>
                          <option>Claimed</option>
                          <option>Unclaimed</option>
                        </select>
                      )} */}
                  </div>
                )}
                {/* Add/Edit Issue Dialog */}
                {openIssueDialog && (
                  <div className="add-variation-dial">
                    <div className="dial-v-header">
                      <h3 className="add-edit-variation">
                        {isIssueEditMode ? "Edit Issue" : "Add Issue"}
                      </h3>
                    </div>
                    <div className="add-variation-content">
                      <h3 className="issue-label">Issue No</h3>
                      <input
                        label="Issue No"
                        className="issue-number"
                        value={issueNo || ""}
                        fullWidth
                        onChange={(e) => setIssueNo(e.target.value)}
                        margin="dense"
                        disabled={isIssueEditMode} // Disable editing of issue number
                      />
                      <h3 className="issue-label">Issue Name</h3>
                      <input
                        label="Issue Name"
                        className="issue-name"
                        value={issueName || ""}
                        fullWidth
                        onChange={(e) => setIssueName(e.target.value)}
                        margin="dense"
                      />

                      <h3 className="issue-label">Issue Type</h3>
                      <select
                        className="variation-hours"
                        value={issueType}
                        onChange={(e) => setIssueType(e.target.value)}
                      >
                        <option value="">Select Issue Type</option>
                        <option value="internal">Internal</option>
                        <option value="external">External</option>
                      </select>
                      <h3 className="issue-label">Raised By</h3>
                      {issueType === "internal" ? (
                        <select
                          className="variation-hours"
                          value={issueRaisedBy}
                          onChange={(e) => setIssueRaisedBy(e.target.value)}
                        >
                          <option>Select User</option>
                          {users
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((user) => (
                              <option key={user.id} value={user.id}>
                                {user.name}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <select
                          className="variation-hours"
                          value={issueRaisedBy}
                          onChange={(e) => setIssueRaisedBy(e.target.value)}
                        >
                          <option value={""}>Select Contact</option>
                          {/* Check if customer is an object */}
                          {Array.isArray(customer.contacts) &&
                            customer.contacts.map((contact) => (
                              <option
                                key={contact.contact_id}
                                value={contact.contact_id}
                              >
                                {contact.contact_name}
                              </option>
                            ))}
                        </select>
                      )}
                      <h3 className="issue-label">Error By</h3>
                      <MultiSelect
                        className="multiselect-issue "
                        options={
                          users && Array.isArray(users)
                            ? users.map((user) => ({
                                label: user.name, // Fallback for missing name
                                value: user.id, // The value that will be stored in `issueErrorBy`
                              }))
                            : [] // Fallback to an empty array if `users` is undefined or not an array
                        }
                        labelledBy="Select Users"
                        value={issueErrorBy
                          .map((id) => {
                            // Map IDs in `issueErrorBy` back to the format MultiSelect expects
                            const user = users.find((u) => u.id === id);
                            return user
                              ? { label: user.name, value: user.id }
                              : null;
                          })
                          .filter(Boolean)} // Remove any `null` values
                        onChange={
                          (selected) =>
                            setIssueErrorBy(selected.map((item) => item.value)) // Update `issueErrorBy` with selected IDs
                        }
                      />
                      <h3 className="issue-label">Attended By</h3>
                      <MultiSelect
                        className="multiselect-issue"
                        options={
                          users && Array.isArray(users)
                            ? users.map((user) => ({
                                label: user.name, // Fallback for missing name
                                value: user.id, // The value that will be stored in `issueErrorBy`
                              }))
                            : [] // Fallback to an empty array if `users` is undefined or not an array
                        }
                        labelledBy="Select Users"
                        value={issueAttendedBy
                          .map((id) => {
                            const user = users.find((u) => u.id === id);
                            return user
                              ? { label: user.name, value: user.id }
                              : null;
                          })
                          .filter(Boolean)} // Remove any `null` values
                        onChange={
                          (selected) =>
                            setIssueAttendedBy(
                              selected.map((item) => item.value)
                            ) // Update `issueErrorBy` with selected IDs
                        }
                      />
                    </div>

                    <div className={"add-issue-btn-con-second"}>
                      <button
                        onClick={
                          isIssueEditMode ? handleSaveIssue : handleAddIssue
                        }
                        color="primary"
                        variant="contained"
                        className="add-edit-issue-btn"
                      >
                        {isIssueEditMode ? "Save" : "Add"}
                      </button>
                      <button
                        onClick={() => {
                          setOpenIssueDialog(false);
                          setIssueAttendedBy([]);
                          setIssueErrorBy([]);
                          setIssueRaisedBy([]);
                          setIssueType([]);
                        }}
                        color="secondary"
                        className="cancel-btn"
                        style={{ marginRight: "140px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}

                {/* Delete Confirmation Dialog */}
                {deleteIssueDialogOpen && (
                  <div className="delete-issue-dial">
                    <div className="delete-var-dial">
                      <div className="delete-var-header">
                        <h3 className="delete-variation">Delete Issue</h3>
                      </div>
                      <div style={{ backgroundColor: "white" }}>
                        <p className="delete-t-para">
                          Are you sure you want to delete this issue? This
                          action cannot be undone.
                        </p>
                        <input
                          style={{
                            backgroundColor: "white",
                            cursor: "pointer",
                          }}
                          type="checkbox"
                          id="tick-issue"
                          checked={isIssueCheckboxChecked}
                          onChange={() =>
                            setIsIssueCheckboxChecked(!isIssueCheckboxChecked)
                          }
                        />
                        <label
                          htmlFor="tick-issue"
                          style={{
                            backgroundColor: "white",
                            userSelect: "none",
                            cursor: "pointer",
                          }}
                        >
                          I understand this action cannot be undone.
                        </label>
                      </div>
                      <div className="delete-btn-issue-del-con">
                        <button
                          onClick={handleDeleteIssueConfirm}
                          color="primary"
                          variant="contained"
                          disabled={!isIssueCheckboxChecked}
                          style={{
                            backgroundColor: isIssueCheckboxChecked
                              ? "red"
                              : "grey",
                          }}
                          className="add-btn"
                        >
                          Delete
                        </button>
                        <button
                          onClick={() => {
                            setDeleteIssueDialogOpen(false);
                            setIsIssueCheckboxChecked(false);
                          }}
                          color="secondary"
                          style={{ marginRight: "140px" }}
                          className="cancel-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Box>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PhaseDetails;
