import React, { useState, useContext, useEffect } from "react";
import "../../styles/layouts/AdminLayout.css";
import { UserSettings } from "./UserSettings";
import EmailSettings from "./EmailSettings";
import { ProjectContext } from "../../App";
import TestEmail from "./TestEmail";
import GroupList from "../GroupList";
import CustomerList from "../CustomerList";
import RoleSettings from "./RoleSettings";
import TaskTemplate from "./TaskTemplate";
import EstimateTaskTemplate from "./EstimateTaskTemplate";
import { Helmet } from "react-helmet";

const Settings = () => {
  const { isNavBarOpen } = useContext(ProjectContext);
  const [openSection, setOpenSection] = useState(
    localStorage.getItem("openSection") || "userSettings"
  );
  useEffect(() => {
    localStorage.setItem("openSection", openSection);
  }, [openSection]);
  const toggleSection = (section) => {
    setOpenSection(openSection === section ? section : section);
  };

  return (
    <div
      className={
        isNavBarOpen ? "settings-main-details" : "settings-main-details-close"
      }
    >
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      <div className="left-right-con">
        <div className="left-Navbar">
          <h3
            className="settings-label"
            style={{
              color: openSection === "userSettings" ? "#6200ee" : "black",
            }}
            onClick={() => toggleSection("userSettings")}
          >
            User
          </h3>
          <h3
            className="settings-label"
            onClick={() => toggleSection("emailSettings")}
            style={{
              color: openSection === "emailSettings" ? "#6200ee" : "black",
            }}
          >
            Email
          </h3>
          <h3
            className="settings-label"
            onClick={() => toggleSection("projectSettings")}
            style={{
              color: openSection === "projectSettings" ? "#6200ee" : "black",
            }}
          >
            Project
          </h3>
          <h3
            className="settings-label"
            onClick={() => toggleSection("roleSettings")}
            style={{
              color: openSection === "roleSettings" ? "#6200ee" : "black",
            }}
          >
            Roles
          </h3>
          <h3
            className="settings-label"
            onClick={() => toggleSection("groupSettings")}
            style={{
              color: openSection === "groupSettings" ? "#6200ee" : "black",
            }}
          >
            Group
          </h3>
          <h3
            className="settings-label"
            onClick={() => toggleSection("customerSettings")}
            style={{
              color: openSection === "customerSettings" ? "#6200ee" : "black",
            }}
          >
            Customer
          </h3>
          <h3
            className="settings-label"
            onClick={() => toggleSection("taskTemplateSettings")}
            style={{
              color:
                openSection === "taskTemplateSettings" ? "#6200ee" : "black",
            }}
          >
            Task Template
          </h3>
          <h3
            className="settings-label"
            onClick={() => toggleSection("estimateTaskTemplateSettings")}
            style={{
              color:
                openSection === "estimateTaskTemplateSettings"
                  ? "#6200ee"
                  : "black",
              whiteSpace: "wrap",
            }}
          >
            Estimate Task Template
          </h3>
        </div>
        <div className="right-container"></div>
      </div>
      <div className="user-settings"></div>

      {openSection === "userSettings" && (
        <div
          className={
            isNavBarOpen ? "setting-container" : "setting-container-close"
          }
        >
          <h3>User Settings</h3>
          <div className="settings-details">
            <UserSettings />
          </div>
        </div>
      )}

      {openSection === "projectSettings" && (
        <div
          className={
            isNavBarOpen ? "setting-container" : "setting-container-close"
          }
        >
          <h3>Project Settings</h3>
          <div className="settings-details">
            <p>Project settings details go here.</p>
          </div>
        </div>
      )}

      {openSection === "emailSettings" && (
        <div
          className={
            isNavBarOpen ? "setting-container" : "setting-container-close"
          }
        >
          <h3>Email Settings</h3>
          <div className="email-test-settings">
            <EmailSettings />
            <TestEmail />
          </div>
        </div>
      )}

      {/* Roles Settings Section */}

      {openSection === "roleSettings" && (
        <div
          className={
            isNavBarOpen ? "setting-container" : "setting-container-close"
          }
        >
          <h3>Roles Settings</h3>
          <div className="settings-details">
            <RoleSettings />
          </div>
        </div>
      )}
      {openSection === "groupSettings" && (
        <div
          className={
            isNavBarOpen ? "setting-container" : "setting-container-close"
          }
        >
          <h3>Group Settings</h3>
          <div className="settings-details">
            <GroupList />
          </div>
        </div>
      )}
      {openSection === "customerSettings" && (
        <div
          className={
            isNavBarOpen ? "setting-container" : "setting-container-close"
          }
        >
          <h3>Customer Settings</h3>
          <div className="settings-details">
            <CustomerList />
          </div>
        </div>
      )}
      {openSection === "taskTemplateSettings" && (
        <div
          className={
            isNavBarOpen ? "setting-container" : "setting-container-close"
          }
        >
          <h3>Task Template Settings</h3>
          <div className="settings-details">
            <TaskTemplate />
          </div>
        </div>
      )}
      {openSection === "estimateTaskTemplateSettings" && (
        <div
          className={
            isNavBarOpen ? "setting-container" : "setting-container-close"
          }
        >
          <h3>Estimate Task Template Settings</h3>
          <div className="settings-details">
            <EstimateTaskTemplate />
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
