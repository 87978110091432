import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import "../../styles/projects/ProjectDetails.css";
import { ProjectContext } from "../../App";
import { IoMdClose } from "react-icons/io";
import DatePicker from "react-datepicker";
import { isValid } from "date-fns";
import { useRolePermissions } from "../Settings/RoleContext";
import api from "../../api/axiosConfig";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress } from "@mui/material";
import { FaChevronDown } from "react-icons/fa";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { Helmet } from "react-helmet";

const fetchData = async (url, setData, setError) => {
  try {
    const response = await api.get(url); // Axios automatically adds the baseURL and headers
    setData(response.data); // Axios automatically parses JSON
  } catch (error) {
    console.error("Error fetching data:", error);
    setError(error); // Handle error
  }
};
const deletePhase = async (phaseIds, setError) => {
  try {
    const url = `/api/estimatephases`; // No phaseId in the URL, we're using the request body
    await api.delete(url, { data: { phaseIds } }); // Send phaseIds in the request body
  } catch (error) {
    if (error.response) {
      // If the server responded with an error
      const serverError = error.response.data;
      console.error("Server error:", serverError);
      setError(
        serverError.message ||
          "An error occurred while deleting phases. Please try again."
      );
      alert(
        serverError.message ||
          "An error occurred while deleting phases. Please try again."
      );
      window.location.reload();
    } else {
      // Generic error handling
      console.error("Unexpected error:", error);
      setError("Unexpected error occurred. Please try again.");
    }
  }
};
const EstimatePhases = () => {
  const { estimateId } = useParams();
  const { hasPermission } = useRolePermissions();
  const [estimates, setEstimates] = useState({ phases: [] });
  const [selectedPhaseId, setSelectedPhaseId] = useState([]);
  const [totalPlannedHours, setTotalPlannedHours] = useState("");
  const [totalDesignHours, setTotalDesignHours] = useState("");
  const [talDetailingHours, setTotalDetailingHours] = useState("");
  const [newPhase, setNewPhase] = useState({
    title: "",
    phaseno: "",
  });
  const [error, setError] = useState(null); // To handle errors
  const [loading, setLoading] = useState(true); // To show loading state
  const [newPhaseDial, setNewPhaseDial] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUserssPerPage] = useState(13);
  const [editingPhase, setEditingPhase] = useState(null);
  const [formValues, setFormValues] = useState({
    phasename: "",
    phaseno: "",
  });
  const [phaseEditDialogOpen, setPhaseEditDialogOpen] = useState(false);

  const updateProjectsPerPage = () => {
    if (window.innerWidth < 1919) {
      setUserssPerPage(9); // Small screens (e.g., mobile)
    } else if (window.innerWidth >= 1920) {
      setUserssPerPage(13); // Medium screens (e.g., tablet)
    } else {
      setUserssPerPage(10); // Large screens (e.g., desktop)
    }
  };
  // useEffect(() => {
  //   const calculateTotalPlannedHours = () => {
  //     const totalMinutes = estimates.phases.reduce((acc, phase) => {
  //       const [hours, minutes] = phase.total_planned_hours
  //         .split(":")
  //         .map(Number);
  //       const phaseMinutes = (hours || 0) * 60 + (minutes || 0);
  //       return acc + phaseMinutes;
  //     }, 0);

  //     const totalHours = Math.floor(totalMinutes / 60);
  //     const remainingMinutes = totalMinutes % 60;

  //     // Format as HH:MM
  //     const formattedTime = `${String(totalHours).padStart(2, "0")}:${String(
  //       remainingMinutes
  //     ).padStart(2, "0")}`;

  //     setTotalPlannedHours(formattedTime);
  //   };
  //   if (estimates) {
  //     calculateTotalPlannedHours();
  //   }
  //   // Set initial projects per page
  // }, [estimates]);
  useEffect(() => {
    const calculateTotalPlannedHours = () => {
      // Initialize totals
      let totalDesignMinutes = 0;
      let totalDetailingMinutes = 0;
      let totalPlannedMinutes = 0;

      // Calculate totals for each phase
      estimates.phases.forEach((phase) => {
        const [designHours, designMinutes] = (
          phase.design_planned_hours || "00:00"
        )
          .split(":")
          .map(Number);
        const [detailingHours, detailingMinutes] = (
          phase.detailing_planned_hours || "00:00"
        )
          .split(":")
          .map(Number);
        const [totalHours, totalMinutes] = (
          phase.total_planned_hours || "00:00"
        )
          .split(":")
          .map(Number);

        // Convert hours to minutes and sum up
        totalDesignMinutes += (designHours || 0) * 60 + (designMinutes || 0);
        totalDetailingMinutes +=
          (detailingHours || 0) * 60 + (detailingMinutes || 0);
        totalPlannedMinutes += (totalHours || 0) * 60 + (totalMinutes || 0);
      });

      // Convert minutes back to hours and minutes format
      const formatTime = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}`;
      };

      // Set state with formatted time
      setTotalDesignHours(formatTime(totalDesignMinutes));
      setTotalDetailingHours(formatTime(totalDetailingMinutes));
      setTotalPlannedHours(formatTime(totalPlannedMinutes));
    };

    // Call the calculation if estimates are available
    if (estimates.phases.length > 0) {
      calculateTotalPlannedHours();
    }
  }, [estimates]);

  const fetchInitialData = async () => {
    // Fetch data when estimateId changes
    fetchData(
      `/api/estimates/${estimateId}/phases`,
      setEstimates,
      setError
    ).finally(() => setLoading(false)); // Set loading to false after fetching
  };
  useEffect(() => {
    fetchInitialData();
  }, [estimateId]);
  const handleAddPhase = async () => {
    if (!newPhase.title.trim() || !newPhase.phaseno.trim()) {
      setError("Phase name and phase number are required");
      return;
    }

    try {
      // API call to add a new phase
      const response = await api.post(`/api/estimates/${estimateId}/phases`, {
        phase_name: newPhase.title, // Send phase name as phase_name
        phase_number: newPhase.phaseno, // Send phase number as phase_number
      });
      let addedPhase = response.data; // Assuming API returns the added phase object
      if (!addedPhase.total_planned_hours) {
        addedPhase = { ...addedPhase, total_planned_hours: "00:00" };
      }
      fetchInitialData();
      // Update the state with the newly added phase
      setEstimates((prev) => ({
        ...prev,
        phases: prev.phases
          ? [...prev.phases, addedPhase] // Add new phase to the existing phases array
          : [addedPhase], // Handle the case where phases is undefined
      }));
      setNewPhaseDial(false);
      setNewPhase(""); // Clear the input field
      setError(""); // Clear any existing errors
    } catch (error) {
      console.error("Error adding phase:", error);
      setError("Error adding phase.");
    }
  };

  const handleEditClick = (phase) => {
    console.log("phase", phase.id);
    setEditingPhase(phase.id);

    setFormValues({
      phasename: phase.phase_name,
      phaseno: phase.phase_number,
    });
    setPhaseEditDialogOpen(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value, // Update the specific field by name
    }));
  };

  const handleSaveClick = async () => {
    const { phasename, phaseno } = formValues; // Use the correct property name
    const phaseId = editingPhase;
    console.log("phase id", editingPhase);

    try {
      const response = await api.put(`/api/estimatephase/${editingPhase}`, {
        phase_name: phasename, // Use correct name
        phase_number: phaseno, // Use correct name
      });

      const updatedPhase = response.data; // Expect updated phase details

      // Update the state with the new phase details
      setEstimates((prevEstimates) => ({
        ...prevEstimates,
        phases: prevEstimates.phases.map((phase) =>
          phase.id === editingPhase
            ? { ...phase, ...updatedPhase } // Replace with updated data
            : phase
        ),
      }));

      setPhaseEditDialogOpen(false); // Close dialog
      setEditingPhase(null); // Clear editing phase
    } catch (error) {
      let errorMessage = "Failed to update phase";
      if (error.response) {
        errorMessage = error.response.data.error || errorMessage;
      }
      console.error("Error updating phase:", errorMessage);
      alert(errorMessage); // Display error to the user
    }
  };

  const handleDeletePhaseClick = (e, id) => {
    const isChecked = e.target.checked;
    console.log("ischecked", isChecked);
    console.log("phaseId", id);
    if (isChecked) {
      // Add phaseId to the selectedPhaseId array
      setSelectedPhaseId((prev) => [...prev, id]);
    } else {
      // Remove phaseId from the selectedPhaseId array
      setSelectedPhaseId((prev) => prev.filter((id) => id !== id));
    }
  };

  const handleDeleteConfirm = async () => {
    if (isCheckboxChecked) {
      try {
        // Call the deletePhase function with the selected phase IDs
        await deletePhase(selectedPhaseId, setError);
        // Update the state after deletion
        setEstimates((prev) => {
          // Filter out the phases whose IDs are in the selectedPhaseId array
          const updatedPhases = prev.phases.filter(
            (phase) => !selectedPhaseId.includes(phase.id) // Remove phases whose IDs are in selectedPhaseId
          );

          return {
            ...prev,
            phases: updatedPhases, // Update phases after deletion
          };
        });

        // Close the delete dialog and reset UI states
        setDeleteDialogOpen(false);

        setIsCheckboxChecked(false); // Reset the checkbox state
        setSelectedPhaseId([]); // Clear the selected phases after deletion
      } catch (error) {
        console.error("Failed to delete phase:", error); // Log the error for debugging
        setError(error); // Display the error message to the user
      }
    } else {
      alert("Please check the checkbox to confirm deletion."); // Alert if no checkbox is checked
    }
  };

  //close dial and remove no-scroll
  const handleCloseDial = () => {
    setIsCheckboxChecked(false);
    setDeleteDialogOpen(false);
    setSelectedPhaseId([]);
  };

  const totalPages = Math.ceil(estimates.phases.length / usersPerPage);

  // Get current projects to display
  const indexOfLastUsers = currentPage * usersPerPage;
  const indexOfFirstUsers = indexOfLastUsers - usersPerPage;
  // const currentUsers = customers.slice(indexOfFirstUsers, indexOfLastUsers);

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
  const handleFirstPage = () => {
    if (currentPage > 1) setCurrentPage(1);
  };
  const handleLastPage = () => {
    if (currentPage < totalPages) setCurrentPage(totalPages);
  };

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    ); // Show loading spinner while fetching data
  }

  return (
    <>
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      <div className="estimate-phase-container">
        <Link to="/estimate">
          <IoMdClose className="estimate-phase-close" />
        </Link>
        {hasPermission("phases", "create") && selectedPhaseId.length > 0 && (
          <button
            className="delete-estimate-phase-btn"
            onClick={() => setDeleteDialogOpen(true)}
          >
            Delete Phase
          </button>
        )}
        {hasPermission("phases", "create") && (
          <button
            className="add-estimate-phase-btn"
            onClick={() => setNewPhaseDial(true)}
          >
            New Phase
          </button>
        )}

        <>
          {estimates.phases.length > 0 && (
            <>
              <h3 className={isNavBarOpen ? "es-pj-name" : "es-pj-name-close"}>
                Estimate :
                <span style={{ marginLeft: "15px" }}>
                  {estimates.estimationno}
                </span>
              </h3>
              <h3
                className={isNavBarOpen ? "es-pj-number" : "es-pj-number-close"}
              >
                Project :
                <span style={{ marginLeft: "26px" }}>
                  {estimates.projectname}
                </span>
              </h3>
            </>
          )}
          <table
            className={
              isNavBarOpen
                ? "estimate-phase-table"
                : "estimate-phase-table-close"
            }
          >
            <thead>
              <tr>
                <th style={{ width: "2px" }}>
                  <FaChevronDown />
                </th>
                <th className="phase-header">Phase No</th>
                <th className="phase-header">Phase Title</th>
                <th className="phase-header">Design Planned Hours</th>
                <th className="phase-header">Detailing Planned Hours</th>
                <th className="phase-header">Total Planned Hours</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {estimates.phases &&
              Array.isArray(estimates.phases) &&
              estimates.phases.length > 0 ? (
                estimates.phases.map((phase, index) => (
                  <tr key={index}>
                    <td className="check-box-column">
                      <input
                        style={{ cursor: "pointer" }}
                        type="checkbox"
                        name={phase.phase_name}
                        checked={selectedPhaseId.includes(phase.id)}
                        onChange={(e) => handleDeletePhaseClick(e, phase.id)}
                      />
                    </td>
                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Link
                          to={`/estimate/phases/${estimateId}/${phase.id}`}
                          className="select-project"
                          style={{ marginTop: "5px" }}
                        >
                          {phase.phase_number}
                        </Link>
                        {hasPermission("phases", "update") && (
                          <button
                            onClick={() => {
                              handleEditClick(phase);
                            }}
                            className="edit-icon-wrapper"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              marginRight: "0px",
                            }}
                            title="Edit Phase"
                          >
                            <EditIcon
                              style={{
                                color: "blue",
                                backgroundColor: "transparent",
                                cursor: "pointer",
                              }}
                            />
                          </button>
                        )}
                      </div>
                    </td>
                    <td>{phase.phase_name}</td>
                    <td
                      className="phase-hr-header"
                      style={{ color: "orange", fontWeight: "800" }}
                    >
                      {phase.design_planned_hours || "00:00"}
                    </td>
                    <td
                      className="phase-hr-header"
                      style={{ color: "blue", fontWeight: "800" }}
                    >
                      {phase.detailing_planned_hours || "00:00"}
                    </td>
                    <td
                      className="phase-hr-header"
                      style={{ color: "green", fontWeight: "800" }}
                    >
                      {phase.total_planned_hours || "00:00"}
                    </td>
                    <td></td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" style={{ textAlign: "center" }}>
                    No phases available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div
            className={
              isNavBarOpen
                ? "est-phase-hours-summary"
                : "est-phase-hours-summary-close"
            }
          >
            <h3
              className={
                isNavBarOpen
                  ? "est-phase-details-text"
                  : "est-phase-details-text-close"
              }
            >
              Phase Planned Hours Summary :{" "}
              <span
                className={isNavBarOpen ? "phase-total" : "phase-total-close"}
              >
                {totalPlannedHours}
              </span>
              <span
                className={
                  isNavBarOpen
                    ? "phase-detailing-total"
                    : "phase-detailing-total-close"
                }
              >
                {talDetailingHours}
              </span>
              <span
                className={
                  isNavBarOpen
                    ? "phase-design-total"
                    : "phase-design-total-close"
                }
              >
                {totalDesignHours}
              </span>
            </h3>
            <div
              className={
                isNavBarOpen
                  ? "est-phase-pagination"
                  : "est-phase-pagination-close"
              }
            >
              <h3 className="pagination-total">
                Total : {estimates.phases.length}
              </h3>
              <button
                onClick={handleFirstPage}
                disabled={currentPage === 1}
                className="phase-arrow-btn"
              >
                <MdKeyboardDoubleArrowLeft />
              </button>
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="phase-arrow-btn"
              >
                {/* */}
                <MdKeyboardArrowLeft />
              </button>
              <span
                style={{
                  marginTop: "-15px",
                  textWrap: "nowrap",
                  backgroundColor: "transparent",
                }}
              >
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="phase-arrow-btn"
              >
                <MdKeyboardArrowRight />
              </button>
              <button
                onClick={handleLastPage}
                disabled={currentPage === totalPages}
                className="phase-arrow-btn"
              >
                <MdKeyboardDoubleArrowRight />
              </button>
            </div>
          </div>
        </>
        {/* ) : (
          "No Phases available for this estimate"
        )} */}
      </div>
      {newPhaseDial && (
        <div className="estimate-phase-dial">
          <div className="e-phase-content">
            <div className="ephase-header">
              <h3 className="e-header">Create Phase</h3>
            </div>
            <h3 className="label-estimate-element">Phase No</h3>
            <input
              type="text"
              placeholder="Enter Phase No"
              className="user-element"
              onChange={(e) =>
                setNewPhase({ ...newPhase, phaseno: e.target.value })
              }
            />
            <h3 className="label-estimate-element">Phase Title</h3>
            <input
              type="text"
              placeholder="Enter Phase Title"
              className="user-element"
              onChange={(e) =>
                setNewPhase({ ...newPhase, title: e.target.value })
              }
            />

            <div className="task-e-btn-con">
              <button className="add-btn" onClick={handleAddPhase}>
                Add
              </button>
              <button
                onClick={() => setNewPhaseDial(false)}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {phaseEditDialogOpen && (
        <div className="estimate-phase-dial">
          <div className="e-phase-content">
            <div className="ephase-header">
              <h3 className="e-header">Edit Phase</h3>
            </div>
            <h3 className="label-estimate-element">Phase No</h3>
            <input
              type="text"
              name="phaseno"
              placeholder="Enter Phase No"
              className="user-element"
              value={formValues.phaseno}
              onChange={handleInputChange}
            />
            <h3 className="label-estimate-element">Phase Title</h3>
            <input
              type="text"
              name="phasename"
              placeholder="Enter Phase Name"
              className="user-element"
              value={formValues.phasename}
              onChange={handleInputChange}
            />

            <div className="task-e-btn-con">
              <button className="add-btn" onClick={handleSaveClick}>
                Save
              </button>
              <button
                onClick={() => setPhaseEditDialogOpen(false)}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {deleteDialogOpen && (
        <div className={`delete-phase-dial ${dialBoxopen ? "open" : "close"} `}>
          <div className="delete-dial">
            <div className="dial-d-header">
              <h3
                style={{ backgroundColor: "transparent", marginLeft: "15px" }}
              >
                Delete Phase
              </h3>
            </div>
            <div style={{ backgroundColor: "white" }}>
              <p style={{ backgroundColor: "white" }}>
                Are you sure you want to delete this phase? This action cannot
                be undone.
              </p>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                id="delete-phase"
                checked={isCheckboxChecked}
                onChange={() => setIsCheckboxChecked((prev) => !prev)}
              />
              <label
                htmlFor="delete-phase"
                style={{ backgroundColor: "white", cursor: "pointer" }}
              >
                I understand this action cannot be undone.
              </label>
            </div>
            <div className="delete-dial-btn-con">
              <button
                onClick={handleDeleteConfirm}
                color="secondary"
                variant="contained"
                disabled={!isCheckboxChecked}
                className="add-btn"
                style={{ backgroundColor: isCheckboxChecked ? "red" : "gray" }}
              >
                Delete
              </button>
              <button onClick={handleCloseDial} className="cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default EstimatePhases;
