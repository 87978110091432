import React, { useState, useEffect } from "react";
import api from "../../api/axiosConfig";
import UpdateTemplates from "./UpdateTemplates"; // Import UpdateTemplates component
import { IoMdClose } from "react-icons/io";
import "../../styles/projects/UpdatePhases.css";

const UpdatePhases = ({ estimateId, onPhaseUpdate, goBack }) => {
  const [phases, setPhases] = useState([]);
  const [newPhase, setNewPhase] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // Fetch existing phases for the estimate
    const fetchPhases = async () => {
      try {
        const response = await api.get(`/api/estimates/${estimateId}/phases`);
        setPhases(response.data);
      } catch (error) {
        setErrorMessage("Failed to fetch phases.");
      }
    };
    fetchPhases();
  }, [estimateId]);

  const handleAddPhase = async () => {
    if (!newPhase.trim()) {
      setErrorMessage("Phase name cannot be empty");
      return;
    }

    try {
      // API call to add a new phase
      const response = await api.post(`/api/estimates/${estimateId}/phases`, {
        phase_name: newPhase,
      });

      // Update local state and parent component
      setPhases((prev) => [...prev, response.data]);
      setNewPhase("");
      setErrorMessage("");
      onPhaseUpdate();
    } catch (error) {
      setErrorMessage("Error adding phase.");
    }
  };

  return (
    <div className="phases-fullpage-container">
      {/* Header with Back button */}
      <div className="phases-header">
        <h3>Update Phases</h3>
        <div className="phase-e-input">
          {phases.length === 0 && (
            <h3 className="add-e-phase-message">
              Please add phases to estimate.
            </h3>
          )}
          <input
            type="text"
            value={newPhase}
            placeholder="Enter new phase name"
            onChange={(e) => setNewPhase(e.target.value)}
          />
          <button onClick={handleAddPhase} className="add-e-phase-btn">
            Add Phase
          </button>
        </div>
        <button className="back-button" onClick={goBack}>
          <IoMdClose />
        </button>
      </div>

      {/* Error Message */}
      {errorMessage && <p className="error">{errorMessage}</p>}

      {/* Phases List */}
      <ul className="phases-list">
        {phases.map((phase) => (
          <li key={phase.id} className="phase-item">
            <h3> {phase.phase_name}</h3>
            {/* Render UpdateTemplates for each phase */}
            <UpdateTemplates estimateId={estimateId} phaseId={phase.id} />
          </li>
        ))}
      </ul>

      {/* Input Section */}
    </div>
  );
};

export default UpdatePhases;
