import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Authentication/AuthContext";
import api from "../../api/axiosConfig";
import { GoEye } from "react-icons/go";
import { GoEyeClosed } from "react-icons/go";
import "./SignIn1.css";

const SignIn = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login, isAuthenticated } = useAuth();

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleIdentifierChange = (e) => setIdentifier(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const onhandleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting login with identifier:", identifier); // Debug: Check value of identifier
    console.log("Base URL:", api.defaults.baseURL); // Debug: Check the base URL of the API

    // Check if fields are empty after trimming
    if (identifier.trim() === "" || password.trim() === "") {
      alert("Please fill in all fields");
      return;
    }

    try {
      // Send request to login API
      const response = await api.post("/login", {
        identifier: identifier.trim(), // Trim identifier before sending
        password: password.trim(), // Trim password before sending
      });

      // Check if the login was successful based on the response message or data
      if (response.data.message === "Login successful") {
        const userDetails = response.data.userDetails;
        login(userDetails); // Call login function with user details
        navigate("/home"); // Redirect to home page
      }
    } catch (error) {
      // Handle different types of errors
      if (error.response) {
        if (error.response.status === 401) {
          setError("Invalid identifier"); // Invalid login
        } else {
          setError("Server error, please try again later"); // Server-side error
        }
      } else {
        setError("Network error, please check your connection"); // Network-related error
      }
    }
  };

  return (
    <>
      {isAuthenticated !== true && (
        <div className="main-container-signin">
          <div className="type-email-parent">
            <form onSubmit={onhandleSubmit}>
              <input
                className="type-email"
                placeholder="Email, Mobile, or Employee Code"
                type="text"
                value={identifier}
                onChange={handleIdentifierChange}
              />
              <input
                className="type-email"
                style={{ marginTop: "50px" }}
                placeholder="Enter Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
              />
              {showPassword ? (
                <GoEye
                  className="login-icon"
                  onClick={handleClickShowPassword}
                />
              ) : (
                <GoEyeClosed
                  className="login-icon"
                  onClick={handleClickShowPassword}
                />
              )}
              <button
                type="submit"
                className="button2"
                style={{
                  borderRadius: "8px",
                  width: "320px",
                  marginTop: "20px",
                }}
              >
                Sign In
              </button>
              <p
                className="forgot-password1 forgot"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password?
              </p>
              {error && <span className="sigin-error">{error}</span>}
              <img
                className="task-bro-1-icon1"
                alt="man-logo"
                src="/taskbro-1@2x.jpg"
              />
              <img
                className="group-158-1"
                alt="MSES Group"
                src="/group-158-1@2x.png"
              />
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SignIn;
