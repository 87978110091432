import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../api/axiosConfig";
import "../../styles/layouts/ProjectLayout.css";
import { ProjectContext } from "../../App";
import ProjectIndividualDetails from "./ProjectIndividualStatus";
import { useRolePermissions } from "../Settings/RoleContext";
import { Helmet } from "react-helmet";

const ProjectLayout = () => {
  const { projectId } = useParams();
  const { hasPermission } = useRolePermissions();
  const { dialBoxopen, onFrameButtonClick, isNavBarOpen } =
    useContext(ProjectContext);
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activePLink, setPActiveLink] = useState(() => {
    return localStorage.getItem("activePLink") || "dashboard";
  });

  const handleLinkClick = (link) => {
    setPActiveLink(link); // Update the active link
    localStorage.setItem("activePLink", link);
    localStorage.removeItem("tabIndex");
  };

  useEffect(() => {
    if (projectId) {
      const fetchProjectDetails = async () => {
        try {
          const res = await api.get(`/api/projects/${projectId}`);
          const projectData = res.data; // Assuming this is a single project object
          const formattedProject = {
            ...projectData,
            start_date:
              projectData.start_date && projectData.start_date !== "0000-00-00"
                ? new Date(projectData.start_date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "Invalid Start Date", // Fallback for '0000-00-00' or missing start_date
            end_date:
              projectData.end_date && projectData.end_date !== "0000-00-00"
                ? new Date(projectData.end_date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "Invalid End Date", // Fallback for '0000-00-00' or missing end_date
          };
          setProject(formattedProject);
          setLoading(false);
        } catch (error) {
          console.error("Error:", error);
          setError(error.message);
          setLoading(false);
        }
      };
      fetchProjectDetails();
    } else {
      setError("Project ID is undefined");
      setLoading(false);
    }
  }, [projectId]);

  if (loading)
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );

  const projectName = project ? project.name : "Loading";
  const projectNumber = project ? project.projectno : "Loading";
  return (
    <>
      {" "}
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      {dialBoxopen ? (
        <>
          <ProjectIndividualDetails />
        </>
      ) : (
        <div className="home-navbar">
          <div
            className={isNavBarOpen ? "project-navbar" : "project-navbar-close"}
          >
            <div
              className={
                isNavBarOpen
                  ? "project-name-container"
                  : "project-name-container-close"
              }
            >
              <h1 className="project-layout-name">
                {projectNumber}-{projectName}
              </h1>
              <button
                className="open-details-btn"
                onClick={onFrameButtonClick}
                title="Project Details"
              >
                <img
                  src="/view_btn.png"
                  style={{ height: "17px", marginRight: "7px" }}
                />
                View
              </button>
            </div>
            <div
              className={
                isNavBarOpen
                  ? "project-layout-parent"
                  : "project-layout-parent-close"
              }
            >
              <div>
                <Link
                  className={`layout-name ${
                    activePLink === "dashboard" ? "active" : ""
                  }`}
                  to={`/project/${projectId}/dashboard`}
                  onClick={() => handleLinkClick("dashboard")}
                >
                  Dashboard
                </Link>
              </div>
              {hasPermission("phases", "read") && (
                <div>
                  <Link
                    className={`layout-name ${
                      activePLink === "phase" ? "active" : ""
                    }`}
                    to={`/project/phases/${projectId}`}
                    onClick={() => handleLinkClick("phase")}
                  >
                    Phase
                  </Link>
                </div>
              )}
              {hasPermission("timesheets", "read") && (
                <>
                  <div>
                    <Link
                      className={`layout-name ${
                        activePLink === "timesheet" ? "active" : ""
                      }`}
                      to={`/project/${projectId}/timesheets`}
                      onClick={() => handleLinkClick("timesheet")}
                    >
                      Timesheet
                    </Link>
                  </div>

                  <div>
                    <Link
                      className={`layout-name ${
                        activePLink === "general" ? "active" : ""
                      }`}
                      to={`/project/${projectId}/timesheets/general`}
                      onClick={() => handleLinkClick("general")}
                    >
                      General
                    </Link>
                  </div>
                  <div>
                    <Link
                      className={`layout-name ${
                        activePLink === "variations" ? "active" : ""
                      }`}
                      to={`/project/${projectId}/timesheets/variation`}
                      onClick={() => handleLinkClick("variations")}
                    >
                      Variations
                    </Link>
                  </div>
                  <div>
                    <Link
                      className={`layout-name ${
                        activePLink === "issues" ? "active" : ""
                      }`}
                      to={`/project/${projectId}/timesheets/issue`}
                      onClick={() => handleLinkClick("issues")}
                    >
                      Issues
                    </Link>
                  </div>
                </>
              )}
              <div>
                {/* <Link
                  className={`layout-name ${
                    activePLink === "report" ? "active" : ""
                  }`}
                  onClick={() => handleLinkClick("report")}
                >
                  Report
                </Link> */}
              </div>
              {hasPermission("projects", "read") && (
                <div>
                  <Link
                    className={`layout-name ${
                      activePLink === "users" ? "active" : ""
                    }`}
                    to={`/project/${projectId}/users`}
                    onClick={() => handleLinkClick("users")}
                  >
                    Users
                  </Link>
                </div>
              )}
              <div>
                <Link
                  className={`layout-name ${
                    activePLink === "pages" ? "active" : ""
                  }`}
                  onClick={() => handleLinkClick("pages")}
                >
                  Pages
                </Link>
              </div>
            </div>
            <hr className="horizontal-project-layout-line" />
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectLayout;
