import React, { useState, useContext, useEffect, useRef } from "react";
import api from "../../api/axiosConfig";
import { TfiFilter } from "react-icons/tfi";
import { useParams } from "react-router-dom";
import { ProjectContext } from "../../App";
import DateRangePickerComp from "./DateRangePicker";
import { useAuth } from "../Authentication/AuthContext";
import { FaCheckCircle } from "react-icons/fa";
import "./TimeSheetTable.css";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { format } from "date-fns";

const FilterTimeSheet = ({
  refresh: refreshTrigger = () => {},
  onTimesheetUpdated,
}) => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const { userDetails } = useAuth();
  const userID = userDetails.id;
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [user, setUser] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState([]);
  const [logTitle, setLogTitle] = useState(""); // New log title field
  const [logUserCondition, setLogUserCondition] = useState("is");
  const [ProjectCondition, setProjectCondition] = useState("is");
  const [logTitleCondition, setLogTitleCondition] = useState("is");
  const [billableCondition, setBillableCondition] = useState("is");
  const [approvalStatusCondition, setApprovalStatusCondition] = useState("is");
  const [approvalByCondition, setApprovalByCondition] = useState("is");
  const [fromDate, setFromDate] = useState(null); // Updated state for fromDate
  const [toDate, setToDate] = useState(null); // Updated state for toDate
  const [billable, setBillable] = useState("");
  const [approvalstatus, setApprovalStatus] = useState("");
  const { projectId } = useParams();
  const [loading, setLoading] = useState(true);
  const [logUsersOpen, setLogUserOpen] = useState(true);
  const [projectsOpen, setProjectsOpen] = useState(true);
  const [logTitleOpen, setLogTitleOpen] = useState(true);
  const [isBillable, setIsBillable] = useState(true);
  const [approvalStatusOpen, setApprovalStatusOpen] = useState(true);
  const [approvedbyOpen, setApprovedbyOpen] = useState(true);
  const [range, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const onClickFilterTimesheet = () => {
    setOpenDialog(true);
    document.body.classList.add("no-scroll");
  };

  const onClosePopUp = () => {
    setOpenDialog(false);
    document.body.classList.remove("no-scroll");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint =
          userDetails.role_name === "Administrator"
            ? `/api/projects1`
            : `/api/aprojects/${userID}`;

        const [usersRes, userAdmin, projectRes] = await Promise.all([
          api.get(`/api/users`),
          api.get(`/api/users/admin`),
          api.get(endpoint),
        ]);
        if (Array.isArray(userAdmin.data)) {
          setAdmins(userAdmin.data);
        } else {
          console.error("Unexpected data structure:", userAdmin.data);
        }
        const activeUsers = usersRes.data.filter(
          (member) => member.status !== "Inactive"
        );
        setUsers(activeUsers);
        setProjects(projectRes.data);
        console.log("project data", projectRes.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectId]);

  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown and profile
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) //&&
      // profileRef.current &&  !profileRef.current.contains(event.target)
    ) {
      setShowDropdown(false); // Close the dropdown
    }
  };

  // Add event listener for clicks outside the component
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(e.target.value);
    setSelectedProjectName("");

    const filtered = projects.filter((project) => {
      const name = project.name || ""; // Fallback to an empty string
      const projectno = project.projectno || ""; // Fallback to an empty string
      const displayName = project.displayName || ""; // Fallback to an empty string

      return (
        name.toLowerCase().includes(searchValue) ||
        projectno.toLowerCase().includes(searchValue) ||
        displayName.toLowerCase().includes(searchValue)
      );
    });

    console.log("filtered", filtered);

    setFilteredProjects(filtered);
    setShowDropdown(true); // Show the dropdown when the user types
  };

  const handleFocus = () => {
    setSearchTerm(""); // Reset the search term to show all projects
    setFilteredProjects(projects); // Show all projects when focusing on the input
    setShowDropdown(true); // Show the dropdown
  };

  const handleDateRangeChange = (range) => {
    // Extracting startDate and endDate from the range, ensuring they are valid dates
    const startDate = new Date(range.startDate); // Convert to Date object
    const endDate = new Date(range.endDate); // Convert to Date object

    // Ensure that the dates are valid before proceeding
    if (!isNaN(startDate) && !isNaN(endDate)) {
      // Set the state with valid Date objects
      setFromDate(startDate);
      setToDate(endDate);
      setDateRange(startDate, endDate);

      // Automatically apply filters if both dates are selected
      handleApplyFilters(startDate, endDate);
    } else {
    }
  };

  const handleApplyFilters = (startDate, endDate) => {
    const filters = {};

    if (user)
      filters.user = { value: selectedUsers, condition: logUserCondition };
    if (logTitle)
      filters.logTitle = { value: logTitle, condition: logTitleCondition };
    if (selectedProjects)
      filters.selectedProjects = {
        value: selectedProjects,
        condition: ProjectCondition,
      };
    // Use the passed in Date objects
    if (startDate && endDate) {
      const startDateAdjusted = new Date(startDate);

      startDateAdjusted.setHours(0, 0, 0, 0); // Start of the day

      const endDateAdjusted = new Date(endDate);
      endDateAdjusted.setHours(23, 59, 59, 999); // End of the day

      // Custom function to format date as YYYY-MM-DD without converting to UTC
      const formatDateToLocal = (date) => {
        return `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      };

      filters.dateFilterType = "range";
      filters.fromDate = formatDateToLocal(startDateAdjusted); // Use adjusted start date without timezone issues
      filters.toDate = formatDateToLocal(endDateAdjusted); // Use adjusted end date

      // Log filters after setting date range
    }

    if (billable)
      filters.billable = { value: billable, condition: billableCondition };
    if (approvalstatus)
      filters.approvalstatus = {
        value: approvalstatus,
        condition: approvalStatusCondition,
      };
    if (selectedAdmin)
      filters.selectedAdmin = {
        value: selectedAdmin,
        condition: approvalByCondition,
      };
    if (typeof refreshTrigger === "function") {
      refreshTrigger(filters);
    } else {
      console.error("refreshTrigger is not a function");
    }
    onClosePopUp();
  };
  const toggleUserSelection = (user_details) => {
    console.log("user", user_details);
    // Check if the user is already selected
    if (selectedUsers.includes(user_details.name)) {
      // Remove user from the selectedUsers array
      setSelectedUsers(
        selectedUsers.filter((selected) => selected !== user_details.name)
      );
    } else {
      // Add user to the selectedUsers array
      setSelectedUsers([...selectedUsers, user_details.name]);
    }
  };
  const toggleProjectSelection = (p) => {
    setSearchTerm("");
    setShowDropdown(false);
    setSelectedProject(p);
    // Check if the user is already selected
    console.log("p", p);
    if (selectedProjects.includes(p.name)) {
      // Remove user from the selectedUsers array
      setSelectedProjects(
        selectedProjects.filter((selected) => selected !== p.name)
      );
    } else {
      // Add user to the selectedUsers array
      setSelectedProjects([...selectedProjects, p.name]);
    }
  };

  useEffect(() => {
    console.log("selected project", selectedProjects);
  }, [selectedProjects]);

  const toggleAdminSelection = (adminId) => {
    // Check if the admin ID is already selected
    if (selectedAdmin.includes(adminId)) {
      // Remove admin ID from selectedAdmin
      setSelectedAdmin(selectedAdmin.filter((id) => id !== adminId));
    } else {
      // Add admin ID to selectedAdmin
      setSelectedAdmin([...selectedAdmin, adminId]);
    }
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      // Add value to the billable array if checked
      setBillable((prev) => [...prev, value]);
    } else {
      // Remove value from the billable array if unchecked
      setBillable((prev) => prev.filter((item) => item !== value));
    }
  };
  const handleCheckboxApprovalStatusChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      // Add value to the billable array if checked
      setApprovalStatus((prev) => [...prev, value]);
    } else {
      // Remove value from the billable array if unchecked
      setApprovalStatus((prev) => prev.filter((item) => item !== value));
    }
  };

  return (
    <div>
      <div>
        <div
          className="date-range span-input"
          value={
            range[0].startDate && range[0].endDate
              ? `${format(range[0].startDate, "MM-dd-yyyy")} to ${format(
                  range[0].endDate,
                  "MM-dd-yyyy"
                )}`
              : "Select a date range"
          }
        >
          <span>
            <DateRangePickerComp onDateRangeChange={handleDateRangeChange} />
          </span>
        </div>
        <button onClick={onClickFilterTimesheet} style={{ border: "none" }}>
          <TfiFilter
            title="Filter"
            className={
              isNavBarOpen ? "time-log-filter-btn" : "time-log-filter-btn-close"
            }
            style={{ zIndex: "9", left: "16px" }}
          />
        </button>
      </div>

      {openDialog && (
        <div className={`filter-dial  ${dialBoxopen ? "open" : "close"}`}>
          <div
            className="new-time-log"
            style={{ marginBottom: "10px", width: "23%" }}
          >
            <h3 style={{ backgroundColor: "transparent", marginLeft: "10px" }}>
              Filter Options
            </h3>
          </div>
          <div className="overview-filter-scrollable-t-content">
            <div
              className={
                logUsersOpen ? "accordion-u-header" : "accordion-header"
              }
              style={{ marginBottom: "-1px", borderTop: "none" }}
            >
              <h3
                className="accordion-label"
                onClick={() => setLogUserOpen((prev) => !prev)}
              >
                Log Users{" "}
                {selectedUsers.length > 0 && (
                  <span className="users-count">{selectedUsers.length}</span>
                )}
              </h3>
              {!logUsersOpen && (
                <select
                  className="is-not"
                  value={logUserCondition}
                  onChange={(e) => setLogUserCondition(e.target.value)}
                >
                  <option value="is">Is</option>
                  <option value="not">Is Not</option>
                </select>
              )}

              <span
                onClick={() => setLogUserOpen((prev) => !prev)}
                className="span-element"
              >
                {logUsersOpen ? (
                  <FaChevronRight className="arrow-icon" />
                ) : (
                  <FaChevronDown className="arrow-d-icon" />
                )}
              </span>
            </div>
            <div
              className={logUsersOpen ? "hide" : "filter-name-field"}
              style={{
                height:
                  userDetails.role_name === "Administrator" ? "133px" : "auto",
              }}
            >
              {userDetails.role_name === "Administrator" ? (
                <ul className="user-ul">
                  {users
                    .sort((a, b) => a.name.localeCompare(b.name)) // Sort users alphabetically by name
                    .map((user_details) => (
                      <li
                        key={user_details.id}
                        onClick={() => toggleUserSelection(user_details)} // Pass correct user object
                        className={"user-name-list"}
                      >
                        <span className="initial">
                          {selectedUsers.includes(user_details.name) ? (
                            <FaCheckCircle
                              style={{ backgroundColor: "transparent" }}
                            />
                          ) : (
                            user_details.name
                              .charAt(user_details.name.length - 1)
                              .toUpperCase() +
                            user_details.name.charAt(0).toUpperCase()
                          )}
                        </span>{" "}
                        <span
                          style={{
                            backgroundColor: "transparent",
                            marginTop: "2px",
                          }}
                        >
                          {user_details.name}
                        </span>
                      </li>
                    ))}
                </ul>
              ) : (
                <ul className="users-ul">
                  <li
                    key={userDetails.id}
                    onClick={() => toggleUserSelection(userDetails)} // Pass correct user object
                    className={"user-name-list"}
                  >
                    <span className="initial">
                      {selectedUsers.includes(userDetails.name) ? (
                        <FaCheckCircle
                          style={{ backgroundColor: "transparent" }}
                        />
                      ) : (
                        userDetails.name
                          .charAt(userDetails.name.length - 1)
                          .toUpperCase() +
                        userDetails.name.charAt(0).toUpperCase()
                      )}
                    </span>{" "}
                    <span
                      style={{
                        backgroundColor: "transparent",
                        marginTop: "2px",
                      }}
                    >
                      {userDetails.name}
                    </span>
                  </li>
                </ul>
              )}
            </div>
            <div
              className={
                projectsOpen ? "accordion-u-header" : "accordion-header"
              }
              style={{ marginBottom: "-1px" }}
            >
              <h3
                className="accordion-label"
                onClick={() => setProjectsOpen((prev) => !prev)}
              >
                Projects{" "}
                {selectedProjects.length > 0 && (
                  <span className="users-count">{selectedProjects.length}</span>
                )}
              </h3>
              {!projectsOpen && (
                <select
                  className="is-not"
                  value={ProjectCondition}
                  onChange={(e) => setProjectCondition(e.target.value)}
                >
                  <option value="is">Is</option>
                  <option value="not">Is Not</option>
                </select>
              )}

              <span
                onClick={() => setProjectsOpen((prev) => !prev)}
                className="span-element"
              >
                {projectsOpen ? (
                  <FaChevronRight className="arrow-icon" />
                ) : (
                  <FaChevronDown className="arrow-d-icon" />
                )}
              </span>
            </div>
            <div
              className={projectsOpen ? "hide" : "filter-name-field"}
              style={{
                height:
                  userDetails.role_name === "Administrator" ? "133px" : "auto",
              }}
            >
              {/* <ul className="user-ul">
                {projects
                  .sort((a, b) => a.name.localeCompare(b.name)) // Sort users alphabetically by name
                  .map((p) => (
                    <li
                      key={p.id}
                      onClick={() => toggleProjectSelection(p)} // Pass correct user object
                      className={"user-name-list"}
                    >
                      <span className="initial">
                        {selectedProjects.includes(p.name) ? (
                          <FaCheckCircle
                            style={{ backgroundColor: "transparent" }}
                          />
                        ) : (
                          p.name.charAt(p.name.length - 1).toUpperCase() +
                          p.name.charAt(0).toUpperCase()
                        )}
                      </span>{" "}
                      <span
                        style={{
                          backgroundColor: "transparent",
                          marginTop: "2px",
                        }}
                      >
                        {p.name}
                      </span>
                    </li>
                  ))}
              </ul> */}
              <div className="filter-project-search">
                <input
                  ref={dropdownRef}
                  className="filter-p-input"
                  placeholder="Search Projects"
                  value={searchTerm || selectedProjectName}
                  onChange={handleSearchChange}
                  onFocus={handleFocus}
                  onMouseDown={() => handleClickOutside}
                />
                {showDropdown &&
                  (showDropdown && filteredProjects?.length > 0 ? (
                    <ul
                      style={{ zIndex: "50" }}
                      ref={dropdownRef}
                      className="project-display-box-overview"
                    >
                      {filteredProjects.map((project) => (
                        <li
                          key={project.id}
                          value={project.id}
                          onClick={() => toggleProjectSelection(project)}
                          onMouseDown={() => handleClickOutside}
                          className="project-dropdown-item"
                        >
                          {project.projectno}-{project.name}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No Projects available</p>
                  ))}
              </div>
            </div>
            <div
              className={
                logTitleOpen ? "accordion-u-header" : "accordion-header"
              }
              style={{ marginBottom: !logTitleOpen ? "0px" : "-23px" }}
            >
              <h3
                className="accordion-label"
                onClick={() => setLogTitleOpen((prev) => !prev)}
              >
                Log Title
              </h3>
              {!logTitleOpen && (
                <select
                  className="is-not"
                  value={logTitleCondition}
                  onChange={(e) => setLogTitleCondition(e.target.value)}
                >
                  <option value="is">Is</option>
                  <option value="not">Is Not</option>
                </select>
              )}

              <span
                onClick={() => setLogTitleOpen((prev) => !prev)}
                className="span-element"
              >
                {logTitleOpen ? (
                  <FaChevronRight className="arrow-icon" />
                ) : (
                  <FaChevronDown className="arrow-d-icon" />
                )}
              </span>
            </div>
            <div className="filter-field">
              <input
                className={logTitleOpen ? "hide" : "filter-t-input"}
                placeholder="Search by Log Title"
                type="text"
                value={logTitle}
                onChange={(e) => setLogTitle(e.target.value)}
              />
            </div>
            <div
              className={isBillable ? "accordion-u-header" : "accordion-header"}
              style={{ marginBottom: !isBillable ? "0px" : "-23px" }}
            >
              <h3
                className="accordion-label"
                onClick={() => setIsBillable((prev) => !prev)}
              >
                Billable
              </h3>
              {!isBillable && (
                <select
                  className="is-not"
                  value={billableCondition}
                  onChange={(e) => setBillableCondition(e.target.value)}
                >
                  <option value="is">Is</option>
                  <option value="not">Is Not</option>
                </select>
              )}
              <span
                onClick={() => setIsBillable((prev) => !prev)}
                className="span-element"
              >
                {isBillable ? (
                  <FaChevronRight className="arrow-icon" />
                ) : (
                  <FaChevronDown className="arrow-d-icon" />
                )}
              </span>
            </div>
            <div className="filter-field">
              <div className={isBillable ? "hide" : "filter-billing-checkbox"}>
                <label>
                  <input
                    type="checkbox"
                    value="1"
                    checked={billable.includes("1")}
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                    onChange={(e) => handleCheckboxChange(e)}
                  />
                  Billable
                </label>

                <label>
                  <input
                    type="checkbox"
                    value="0"
                    checked={billable.includes("0")}
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                    onChange={(e) => handleCheckboxChange(e)}
                  />
                  Non-Billable
                </label>
              </div>
            </div>
            <div
              className={
                approvalStatusOpen ? "accordion-u-header" : "accordion-header"
              }
              style={{
                textWrap: "nowrap",
                marginBottom: approvalStatusOpen ? "-36px" : "",
              }}
            >
              <h3
                className="accordion-label"
                onClick={() => setApprovalStatusOpen((prev) => !prev)}
              >
                Approval Status
              </h3>
              {!approvalStatusOpen && (
                <select
                  className="is-not"
                  value={approvalStatusCondition}
                  onChange={(e) => setApprovalStatusCondition(e.target.value)}
                >
                  <option value="is">Is</option>
                  <option value="not">Is Not</option>
                </select>
              )}

              <span
                onClick={() => setApprovalStatusOpen((prev) => !prev)}
                className="span-element"
              >
                {approvalStatusOpen ? (
                  <FaChevronRight
                    className="arrow-icon"
                    style={{ marginLeft: approvalStatusOpen ? "188px" : "" }}
                  />
                ) : (
                  <FaChevronDown className="arrow-d-icon" />
                )}
              </span>
            </div>
            <div className={approvalStatusOpen ? "hide" : "filter-field"}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "18px",
                }}
              >
                <label>
                  <input
                    type="checkbox"
                    value="Pending"
                    checked={approvalstatus.includes("Pending")}
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                    onChange={(e) => handleCheckboxApprovalStatusChange(e)}
                  />
                  Pending
                </label>

                <label>
                  <input
                    type="checkbox"
                    value="Approved"
                    checked={approvalstatus.includes("Approved")}
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                    onChange={(e) => handleCheckboxApprovalStatusChange(e)}
                  />
                  Approved
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="Rejected"
                    checked={approvalstatus.includes("Rejected")}
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                      cursor: "pointer",
                    }}
                    onChange={(e) => handleCheckboxApprovalStatusChange(e)}
                  />
                  Rejected
                </label>
              </div>
            </div>
            <div
              className={
                approvedbyOpen ? "accordion-u-header" : "accordion-header"
              }
              style={{ marginTop: approvalStatusOpen ? "35px" : "0px" }}
            >
              <h3
                className="accordion-label"
                onClick={() => setApprovedbyOpen((prev) => !prev)}
              >
                Approved by{" "}
                {selectedAdmin.length > 0 && (
                  <span className="users-count">{selectedAdmin.length}</span>
                )}
              </h3>
              {!approvedbyOpen && (
                <select
                  className="is-not"
                  value={approvalByCondition}
                  onChange={(e) => setApprovalByCondition(e.target.value)}
                >
                  <option value="is">Is</option>
                  <option value="not">Is Not</option>
                </select>
              )}

              <span
                onClick={() => setApprovedbyOpen((prev) => !prev)}
                className="span-element"
              >
                {approvedbyOpen ? (
                  <FaChevronRight
                    className="arrow-icon"
                    style={{ marginLeft: "210px" }}
                  />
                ) : (
                  <FaChevronDown className="arrow-d-icon" />
                )}
              </span>
            </div>
            <div className={approvedbyOpen ? "hide" : "filter-name-field "}>
              <ul className="user-ul">
                {Array.isArray(admins) &&
                  admins
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((user_details) => (
                      <li
                        key={user_details.id}
                        onClick={() => toggleAdminSelection(user_details.id)} // Use user ID for selection
                        className={"user-name-list"}
                      >
                        <span className="initial">
                          {selectedAdmin.includes(user_details.id) ? ( // Adjust the class based on selection
                            <FaCheckCircle
                              style={{ backgroundColor: "transparent" }}
                            />
                          ) : (
                            user_details.name
                              .charAt(user_details.name.length - 1)
                              .toUpperCase() +
                            user_details.name.charAt(0).toUpperCase()
                          )}
                        </span>{" "}
                        <span
                          style={{
                            backgroundColor: "transparent",
                            marginTop: "2px",
                          }}
                        >
                          {user_details.name}
                        </span>
                      </li>
                    ))}
              </ul>
            </div>
          </div>

          <div className="overview-filter-btn-container-add-timesheet">
            <button
              className="add-btn"
              onClick={handleApplyFilters}
              style={{ marginLeft: "5px", fontWeight: "600px" }}
            >
              Apply
            </button>
            <button
              onClick={onClosePopUp}
              className="cancel-btn"
              style={{ fontWeight: "600px" }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterTimeSheet;
