import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { FaChevronDown } from "react-icons/fa";
import "./CustomerList.css";
import { useAuth } from "./Authentication/AuthContext";
import api from "../api/axiosConfig";
import { ProjectContext } from "../App";
import { useRolePermissions } from "../components/Settings/RoleContext.js";
import { IoMdClose } from "react-icons/io";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";

const CustomerList = () => {
  const { customerId } = useParams();
  const { hasPermission } = useRolePermissions();
  const { isNavBarOpen } = useContext(ProjectContext);
  const [customers, setCustomers] = useState([]);
  const [customerNumber, setCustomerNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [contactsByCustomer, setContactsByCustomer] = useState({});
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [activeCustomerLink, setCustomerActiveLink] =
    useState("activecontacts");
  const [editContactDialBox, setEditContactDialBox] = useState(false);
  const [editContactId, setEditContactId] = useState(null);
  const [editableContact, setEditableContact] = useState(null);
  const [deleteContactDialBox, setDeleteContactDialBox] = useState(false);
  const [contactToDelete, setContactToDelete] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleContactLinkClick = (link) => {
    setCustomerActiveLink(link); // Update the active link
  };
  const [contacts, setContact] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userDetails } = useAuth();
  const userRole = userDetails.role;
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [contactForm, setContactForm] = useState({
    contact_name: "",
    contactno: "",
    email: "",
    designation: "",
  });
  const [currentPage, setCurrentPage] = useState(() => {
    // Retrieve the current page from localStorage, or default to page 1
    const savedPage = localStorage.getItem("currentPage");
    return savedPage ? Number(savedPage) : 1;
  });
  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);
  const [usersPerPage, setUserssPerPage] = useState(13);
  const updateProjectsPerPage = () => {
    if (window.innerWidth < 1919) {
      setUserssPerPage(9); // Small screens (e.g., mobile)
    } else if (window.innerWidth >= 1920) {
      setUserssPerPage(13); // Medium screens (e.g., tablet)
    } else {
      setUserssPerPage(10); // Large screens (e.g., desktop)
    }
  };
  useEffect(() => {
    // Set initial projects per page
    updateProjectsPerPage();

    // Update projects per page when the window is resized
    window.addEventListener("resize", updateProjectsPerPage);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateProjectsPerPage);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint =
          userDetails.role_name === "Administrator"
            ? `/api/customers`
            : `/api/customers/${userDetails.id}`;
        const res = await api.get(endpoint);
        const customersData = res.data;
        setCustomers(customersData);

        const contactsPromises = customersData.map((customer) =>
          api.get(`/api/customers/${customer.id}/contacts`)
        );

        const contactsResponses = await Promise.all(contactsPromises);
        const contactsMap = {};
        contactsResponses.forEach((response, index) => {
          contactsMap[customersData[index].id] = response.data;
        });
        setContactsByCustomer(contactsMap);
      } catch (error) {
        setError(error);
        console.error(
          "Error fetching customer or contact data:",
          error.message || error
        );
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userRole, userDetails.id, refreshTrigger]);

  const fetchInitialData = async () => {
    try {
      const endpoint = `/api/customers/${customerId}/contacts`;
      const res = await api.get(endpoint);
      console.log("res data", res.data);
      setCustomerName(res.data.customer_name);
      setCustomerNumber(res.data.customerno);
      console.log(
        customerName,
        "customerName",
        customerNumber,
        "customerNumber"
      );
      // Assuming the response is an object with a 'contacts' key
      const contactData = res.data.contacts || [];
      console.log("contact", contactData);

      let contactFilterData = [];

      // Apply filter based on activeCustomerLink
      if (activeCustomerLink === "activecontacts") {
        contactFilterData = contactData.filter(
          (contact) => contact.status === "Active"
        );
      } else if (activeCustomerLink === "inactivecontacts") {
        contactFilterData = contactData.filter(
          (contact) => contact.status === "Inactive"
        );
      } else {
        contactFilterData = contactData;
      }

      setContact(contactFilterData); // Safe to set even if empty
      console.log("Filtered contact data:", contactFilterData);
    } catch (error) {
      setError(error);
      console.error(
        "Error fetching customer or contact data:",
        error.message || error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (customerId) {
      fetchInitialData();
    }
  }, [
    customerId,
    userRole,
    userDetails.id,
    refreshTrigger,
    activeCustomerLink,
  ]);

  const closeContactDialog = () => {
    setContactForm("");
    setOpenContactDialog(false);
    document.body.classList.remove("no-scroll");
  };

  const handleAddContact = async () => {
    if (selectedCustomerId === null) {
      toast.error("Please select a customer.", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }
    if (!contactForm.contact_name) {
      toast.error("Please fill all the fields.", {
        position: "bottom-center",
        autoClose: 2000,
      });
      return;
    }

    try {
      const response = await api.post(
        `/api/customers/${selectedCustomerId}/contacts`,
        contactForm
      );
      if (response.status === 201) {
        toast.success("Contact added successfully!", {
          position: "bottom-center",
          autoClose: 2000,
        });
        setRefreshTrigger(!refreshTrigger);
        closeContactDialog();
        setContactForm("");
      }
    } catch (error) {
      console.error(
        "Error adding contact:",
        error.response ? error.response.data : error.message
      );
      toast.error("Failed to add contact", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  const handleOpenAddContactDialog = (customerId) => {
    setSelectedCustomerId(customerId);
    setOpenContactDialog(true);
    document.body.classList.add("no-scroll");
  };

  const handleContactInputChange = (e) => {
    const { name, value } = e.target;
    setContactForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContactEditClick = (contact) => {
    setEditContactDialBox(true); // Opens the dialog
    setEditableContact(contact);
    // Sets the customer to be edited
    setEditContactId(contact.contact_id); // Sets the customer ID
  };

  const handleEditContact = async () => {
    const contactId = editContactId;
    const updatedData = editableContact;
    try {
      const response = await api.put(`/api/contacts/${contactId}`, updatedData);
      setRefreshTrigger(!refreshTrigger);
      setEditContactDialBox(false);
    } catch (error) {
      console.error("Error updating contact:", error.message || error);
    }
  };

  const handleDeleteContact = async () => {
    try {
      await api.delete("/api/contacts", {
        data: {
          contactIds: contactToDelete.map((contact) => contact.contact_id),
        },
      });
      // Update the state to remove the contacts from the list
      setRefreshTrigger(!refreshTrigger); // This should trigger a refresh of the contacts
      setDeleteContactDialBox(false); // Close the dialog
      setIsCheckboxChecked(false); // Reset checkbox state
      setContactToDelete([]); // Clear the list of contacts to delete
    } catch (error) {
      console.error("Error deleting contacts:", error.message || error);
    }
  };

  const handleDeleteClick = (e, contact) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setContactToDelete((prev) => [...prev, contact]);
    } else {
      setContactToDelete((prev) =>
        prev.filter((c) => c.contact_id !== contact.contact_id)
      );
    }
  };

  const handleContactStatusChange = async (contactId, value) => {
    console.log(contactId, value);
    try {
      const response = await api.put(`/api/contacts-status/${contactId}`, {
        value,
      });

      if (response.status === 200) {
        setContact((prevCustomers) =>
          prevCustomers.map((customer) =>
            customer.contact_id === contactId
              ? { ...customer, status: value } // Update only the status field
              : customer
          )
        );
        fetchInitialData();
        toast.success("Contact updated successfully!", {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error("Failed to update customer", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  const handleCloseDeleteCustomerDial = () => {
    setDeleteContactDialBox(false); // Close dialog on cancel
    setIsCheckboxChecked(false); // Reset checkbox state
  };
  const totalPages = Math.ceil(contacts.length / usersPerPage);

  // Get current projects to display
  const indexOfLastUsers = currentPage * usersPerPage;
  const indexOfFirstUsers = indexOfLastUsers - usersPerPage;
  const currentUsers = contacts.slice(indexOfFirstUsers, indexOfLastUsers);

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
  const handleFirstPage = () => {
    if (currentPage > 1) setCurrentPage(1);
  };
  const handleLastPage = () => {
    if (currentPage < totalPages) setCurrentPage(totalPages);
  };
  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  }

  return (
    <>
      <div
        className="project-nav-bar-details"
        style={{ left: isNavBarOpen ? "0px" : "-77px" }}
      >
        <Link to="/settings">
          <IoMdClose className={"contacts-exit-arrow"} />
        </Link>{" "}
        <h3>Contacts</h3>
        {/* {contacts.length > 0 && ( */}
        <h3>
          Customer : {customerNumber} - {customerName}
          {/* {contacts[0]
              ? `${contacts[0].customerno} - ${contacts[0].customer_name}`
              : ""} */}
        </h3>
        {/* )} */}
        <div className="project-tab-details">
          <Link
            className={`proj-links ${
              activeCustomerLink === "activecontacts" ? "active" : ""
            }`}
            onClick={() => handleContactLinkClick("activecontacts")}
          >
            Active Contacts
          </Link>
          <Link
            className={`proj-links ${
              activeCustomerLink === "inactivecontacts" ? "active" : ""
            }`}
            onClick={() => handleContactLinkClick("inactivecontacts")}
          >
            Inactive Contacts
          </Link>
          <Link
            className={`proj-links ${
              activeCustomerLink === "overallcontacts" ? "active" : ""
            }`}
            onClick={() => handleContactLinkClick("overallcontacts")}
          >
            Overall Contacts
          </Link>
        </div>
        <hr className="proj-line" />
      </div>
      {hasPermission("customers", "delete") && contactToDelete.length > 0 && (
        <button
          className="delete-contact-heading"
          style={{
            backgroundColor: "red",
            marginRight: "-60px",
          }}
          onClick={() => setDeleteContactDialBox(true)}
          title="Delete Contact"
        >
          Delete
        </button>
      )}
      {hasPermission("customers", "create") && (
        <button
          className="add-contact-heading"
          onClick={() => handleOpenAddContactDialog(customerId)}
          title="New Contact"
        >
          New Contact
        </button>
      )}
      <ToastContainer />
      <div
        className={isNavBarOpen ? "contact-table" : "contact-table-close"}
        style={{ left: isNavBarOpen ? " " : "0px" }}
      >
        {contacts.length > 0 && contacts[0].contact_id !== null ? (
          <table
            className={
              isNavBarOpen
                ? "project-content-table-contacts"
                : "project-content-table-contacts-close"
            }
          >
            <thead>
              <tr>
                {hasPermission("customers", "delete") && (
                  <th style={{ width: "2px", textAlign: "center" }}>
                    <FaChevronDown />{" "}
                  </th>
                )}
                <th
                  style={{
                    whiteSpace: "nowrap",
                    width: "200px",
                    textAlign: "left",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    whiteSpace: "nowrap",
                    width: "200px",
                    textAlign: "left",
                  }}
                >
                  Email
                </th>
                <th
                  style={{
                    whiteSpace: "nowrap",
                    width: "200px",
                    textAlign: "left",
                  }}
                >
                  Status
                </th>
                <th
                  style={{
                    whiteSpace: "nowrap",
                    width: "200px",
                    textAlign: "left",
                  }}
                >
                  Contact No
                </th>
                <th
                  style={{
                    whiteSpace: "nowrap",
                    width: "200px",
                    textAlign: "left",
                  }}
                >
                  Designation
                </th>

                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.length > 0 ? (
                currentUsers.map((contact, index) => (
                  <tr key={contact.contact_id || `contact-${index}`}>
                    {hasPermission("customers", "delete") && (
                      <td>
                        <input
                          style={{ cursor: "pointer" }}
                          type="checkbox"
                          name={contact.name || ""}
                          checked={
                            Array.isArray(contactToDelete) &&
                            contactToDelete.some(
                              (c) => c.contact_id === contact.contact_id
                            )
                          }
                          onChange={(e) => handleDeleteClick(e, contact)}
                        />
                      </td>
                    )}
                    <td>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          border: "none",
                          justifyContent: "space-between",
                        }}
                      >
                        {contact.contact_name || "Unnamed Contact"}
                        {hasPermission("customers", "update") && (
                          <EditIcon
                            style={{
                              backgroundColor: "transparent",
                              color: "blue",
                            }}
                            className="edit-icon-wrapper"
                            onClick={() => handleContactEditClick(contact)}
                          />
                        )}
                      </div>
                    </td>
                    <td>{contact.email || ""}</td>
                    <td>
                      <select
                        className="customer-status-dd"
                        value={contact.status || ""}
                        onChange={(e) =>
                          handleContactStatusChange(
                            contact.contact_id,
                            e.target.value
                          )
                        }
                      >
                        <option value={""} disabled>
                          Select Status
                        </option>
                        <option key={"Active"} value={"Active"}>
                          Active
                        </option>
                        <option key={"Inactive"} value={"Inactive"}>
                          Inactive
                        </option>
                      </select>
                    </td>
                    <td>{contact.contactno || ""}</td>
                    <td>{contact.designation || ""}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <th colSpan="6" style={{ textAlign: "center" }}>
                    No Contacts available
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <div>
            <img className="timesheet-image" src="/AppLayout/Contact.png" />
            <p className="timesheet-message">
              No contacts available for this customer
            </p>
          </div>
        )}
      </div>
      {/* {contacts[0].contact_id === null && (
          <>
            <img className="timesheet-image" src="/AppLayout/Contact.png" />
            <p className="timesheet-message">
              No contacts available for this customer
            </p>
          </>
        )}
      </div> */}
      <div
        className="contact-pagination"
        // style={{ marginTop: "783px", marginLeft: "1660px" }}
      >
        <h3>Total : {currentUsers.length}</h3>
        <button
          onClick={handleFirstPage}
          disabled={currentPage === 1}
          className="arrow-project-btn"
        >
          <MdKeyboardDoubleArrowLeft title="First Page" />
        </button>
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="arrow-project-btn"
        >
          {/* */}
          <MdKeyboardArrowLeft title="Previous Page" />
        </button>
        <span
          style={{
            marginTop: "16px",
            textWrap: "nowrap",
          }}
        >
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="arrow-project-btn"
        >
          <MdKeyboardArrowRight title="Next Page" />
        </button>
        <button
          onClick={handleLastPage}
          disabled={currentPage === totalPages}
          className="arrow-project-btn"
        >
          <MdKeyboardDoubleArrowRight title="Last Page" />
        </button>
      </div>
      {/* Add contact dialog */}
      {openContactDialog && (
        <div className="add-contact-dial">
          <div className="add-project-content">
            <div
              className="add-project-user-header"
              style={{ marginLeft: "0px" }}
            >
              <h3
                className="add-project-user-heading"
                style={{ marginLeft: "8px", fontWeight: "550px" }}
              >
                Add New Contact
              </h3>
            </div>
            <div className="customer-form">
              <label className="customer-label">Contact Name:</label>
              <input
                className="customer-input"
                type="text"
                placeholder="Enter contact name"
                name="contact_name"
                value={contactForm.contact_name}
                onChange={handleContactInputChange}
              />

              <label className="customer-label">Email:</label>
              <input
                className="customer-input"
                type="email"
                placeholder="Enter email address"
                name="email"
                value={contactForm.email}
                onChange={handleContactInputChange}
              />

              <label className="customer-label">Contact No:</label>
              <input
                className="customer-input"
                type="number"
                placeholder="Enter contact no"
                name="contactno"
                value={contactForm.contactno}
                onChange={handleContactInputChange}
              />

              <label className="customer-label">Designation:</label>
              <input
                className="customer-input"
                type="text"
                placeholder="Enter designation"
                name="designation"
                value={contactForm.designation}
                onChange={handleContactInputChange}
              />
            </div>
            <div className="add-contact-btn-container">
              <button
                onClick={handleAddContact}
                style={{ marginLeft: "25px" }}
                className="add-btn"
              >
                Add Contact
              </button>
              <button onClick={closeContactDialog} className="cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {deleteContactDialBox && (
        <div className="delete-phase-dial" style={{ marginTop: "-8px" }}>
          <div className="delete-dial">
            <div className="delete-header">
              <h3
                style={{ backgroundColor: "transparent", marginLeft: "13px" }}
              >
                Delete Contact
              </h3>
            </div>
            <div style={{ backgroundColor: "white" }}>
              <p style={{ backgroundColor: "white" }}>
                Are you sure you want to delete this contact? This action cannot
                be undone.
              </p>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                id="delete-phase"
                checked={isCheckboxChecked}
                onChange={() => setIsCheckboxChecked((prev) => !prev)}
              />
              <label
                htmlFor="delete-phase"
                style={{ backgroundColor: "white", cursor: "pointer" }}
              >
                I understand this action cannot be undone.
              </label>
            </div>
            <div className="delete-dial-btn-con">
              <button
                onClick={handleDeleteContact}
                disabled={!isCheckboxChecked}
                className="add-btn"
                style={{ backgroundColor: isCheckboxChecked ? "red" : "gray" }}
              >
                Delete
              </button>
              <button
                onClick={handleCloseDeleteCustomerDial}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {editContactDialBox && (
        <div className="add-contact-dial">
          <div className="add-project-content">
            <div
              className="add-project-user-header"
              style={{ marginLeft: "0px" }}
            >
              <h3
                className="add-project-user-heading"
                style={{ marginLeft: "15px", fontWeight: "550px" }}
              >
                Edit Contact
              </h3>
            </div>
            <div className="customer-form">
              <label className="customer-label">Contact Name:</label>
              <input
                type="text"
                className="customer-input"
                name="customerno"
                value={editableContact.contact_name}
                onChange={(e) =>
                  setEditableContact({
                    ...editableContact,
                    contact_name: e.target.value,
                  })
                }
              />
              <label className="customer-label">Contact Email:</label>
              <input
                type="text"
                className="customer-input"
                name="customerno"
                value={editableContact.email}
                onChange={(e) =>
                  setEditableContact({
                    ...editableContact,
                    email: e.target.value,
                  })
                }
              />
              <label className="customer-label">Contact No:</label>
              <input
                type="text"
                className="customer-input"
                name="customerno"
                value={editableContact.contactno}
                onChange={(e) =>
                  setEditableContact({
                    ...editableContact,
                    contactno: e.target.value,
                  })
                }
              />
              <label className="customer-label">Designation:</label>
              <input
                type="text"
                className="customer-input"
                name="customerno"
                value={editableContact.designation}
                onChange={(e) =>
                  setEditableContact({
                    ...editableContact,
                    designation: e.target.value,
                  })
                }
              />
            </div>
            <div className="edit-contact-btn-container">
              <button
                onClick={handleEditContact}
                style={{ marginLeft: "24px" }}
                className="add-btn"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setEditContactDialBox(false);
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerList;
