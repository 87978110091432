import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import api from "../../api/axiosConfig";
import { useAuth } from "../Authentication/AuthContext";
import { IoMdClose } from "react-icons/io";

const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(":");
  return `${hours}:${minutes}`;
};

const IndividualEstimation = () => {
  const [estimate, setEstimate] = useState(null); // Initialize as null to handle loading state
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [totalPlannedHours, setTotalPlannedHours] = useState();
  const [updatedTasks, setUpdatedTasks] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState("");
  const { estimateId } = useParams();
  const { userDetails } = useAuth();
  console.log("estimate", totalPlannedHours);

  const fetchInitialData = async () => {
    try {
      const [customerRes, taskTemplateRes, statuRes, estimatesRes] =
        await Promise.all([
          api.get("/api/customers"),
          api.get("/api/estimatestasktemplates"),
          api.get("/api/get-status-values"),
          api.get(`/api/getEstimateDetails/${estimateId}`),
        ]);

      // Assuming the response data structure, updating the state accordingly
      setTaskTemplates(estimatesRes.data.taskTemplates);
      setTotalPlannedHours(estimatesRes.data.totalPlannedHours);
      setEstimate(estimatesRes.data.estimate); // The estimate data
      setStatus(statuRes.data);
      console.log("status", statuRes.data);
    } catch (error) {
      // Enhanced error handling
      if (error.response) {
        setErrorMessage(
          `Server Error: ${
            error.response.data.error || error.response.statusText
          }`
        );
      } else if (error.request) {
        setErrorMessage("No response received from the server.");
      } else {
        setErrorMessage(`Request Error: ${error.message}`);
      }
    }
  };
  useEffect(() => {
    fetchInitialData();
  }, [estimateId]); // Runs when `estimateId` changes

  const handleSaveChanges = async () => {
    console.log("estimateId inside handlesavefunction", estimateId);
    try {
      // Send updated planned hours to the backend
      await api.put(`/api/updatePlannerHours/${estimateId}`, {
        updatedTasks,
      });
      console.log("updated tasks", updatedTasks);
      alert("Changes saved successfully!");

      // Reload data after successful save
      await fetchInitialData();
    } catch (error) {
      console.error("Error saving changes", error);
      alert("Error saving changes.");
    }
  };

  const handlePlannedHoursChange = (taskId, value) => {
    setUpdatedTasks((prev) => ({
      ...prev,
      [taskId]: value, // Store updated value for the task
    }));
  };

  // Check if there's an error or if estimate is null before rendering
  if (errorMessage) return <p>Error: {errorMessage}</p>;

  if (!estimate) {
    return <p>Loading...</p>;
  }

  return (
    <div className="estimation-i-container">
      {/* Safe check using optional chaining (?.) */}
      <Link to="/estimate">
        <IoMdClose className="est-exit-icon" />
      </Link>
      <h3>
        Estimation Number{" "}
        <span className="est-no-content">{estimate?.estimationno}</span>
      </h3>
      <div>
        <h3>
          Date
          <DatePicker
            value={estimate?.date}
            className="input-d-est"
            type="date"
          />
        </h3>
        <h3>
          Project Name{" "}
          <span className="est-name-content">{estimate?.projectname}</span>
        </h3>
        <h3>
          Description
          <input value={estimate?.description} className="input-est" />
        </h3>
        <div className="status-est-con">
          <h3>Status</h3>{" "}
          <select value={estimate?.status} className="status-est-dropdown">
            <option>Select Status</option>
            {status.map((statusItem, index) => (
              <option key={index} value={statusItem}>
                {statusItem}
              </option>
            ))}
          </select>
        </div>
        <h3>
          Total Planned Hours{" "}
          <span className="est-hours-content">{totalPlannedHours}</span>
        </h3>
      </div>

      <div className="task-template-map-container">
        {taskTemplates?.map((template, index) => {
          // Calculate the total planned hours for this task template
          const totalPlannedHoursForTemplate = template?.tasks?.reduce(
            (sum, task) => {
              const [hours, minutes] = task?.planned_hours
                .split(":")
                .map(Number);
              return sum + hours * 60 + minutes; // Convert hours to minutes and add minutes
            },
            0
          );

          // Format the total planned hours back to hh:mm
          const formattedTotalPlannedHours = `${Math.floor(
            totalPlannedHoursForTemplate / 60
          )}:${String(totalPlannedHoursForTemplate % 60).padStart(2, "0")}`;

          return (
            <div key={index} className="task-template">
              <h3>{template?.taskTemplate?.template_name}</h3>
              <ul className="task-ph-ul">
                {template?.tasks?.map((task, taskIndex) => (
                  <li key={taskIndex} className="task-ph-li">
                    <strong>{task?.task_name}</strong>
                    <input
                      className="est-planned-hours"
                      type="text"
                      value={
                        updatedTasks[task.id] || formatTime(task.planned_hours) // Format time when displaying
                      }
                      onChange={(e) =>
                        handlePlannedHoursChange(task.id, e.target.value)
                      }
                    />
                  </li>
                ))}
              </ul>
              {/* Display total planned hours for this task template */}
              <p className="total-template-hours">
                <strong>
                  Total Planned Hours for{" "}
                  {template?.taskTemplate?.template_name}:
                </strong>{" "}
                {formattedTotalPlannedHours}
              </p>
            </div>
          );
        })}
      </div>

      <button onClick={handleSaveChanges} className="add-est-btn">
        Save Changes
      </button>
    </div>
  );
};

export default IndividualEstimation;
