import React, { useState, useEffect } from "react";
import api from "../../api/axiosConfig"; // Ensure you're using the correct API instance

const UpdateTemplates = ({ estimateId, phaseId }) => {
  const [templates, setTemplates] = useState([]); // State to store templates
  const [selectedTemplates, setSelectedTemplates] = useState([]); // State to store selected template IDs
  const [groupedTasks, setGroupedTasks] = useState({}); // State to store grouped tasks
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (success || error) {
      const timer = setTimeout(() => {
        setSuccess(false);
        setError(false);
      }, 3000); // 5 seconds

      // Clean up the timeout when the component unmounts or if success changes
      return () => clearTimeout(timer);
    }
  }, [success]);
  // Fetch templates from the backend
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await api.get("/api/estimatestasktemplates"); // Fetch available templates
        setTemplates(response.data); // Store templates in state
        console.log("estimate template", response.data.length);
      } catch (err) {
        console.error("Failed to fetch templates", err);
        setError("Failed to fetch templates.");
      }
    };

    fetchTemplates();
  }, []);

  // Fetch tasks for the phase, grouped by tasktemplate_id
  const fetchGroupedTasks = async () => {
    try {
      const response = await api.get(
        `/api/estimate/${estimateId}/phase/${phaseId}/tasks`
      );
      setGroupedTasks(response.data); // Store grouped tasks in state
      console.log("grouped tasks", Object.keys(response.data).length);

      // Access the estimate_id of tasktemplate 118 (if it exists in the data)
    } catch (err) {
      console.error("Failed to fetch tasks", err);
      setError("Failed to fetch tasks.");
    }
  };

  // Handle template selection change
  const handleTemplateChange = (e) => {
    const { options } = e.target;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setSelectedTemplates(selectedValues); // Update selected templates state
  };

  // Handle updating templates for the estimate and phase
  const handleUpdateTemplates = async () => {
    setLoading(true);
    setError(null);
    setSuccess(false);
    setSelectedTemplates([]);

    try {
      // Send selected template IDs to backend along with estimateId and phaseId
      const response = await api.post(
        `/api/estimate/${estimateId}/phase/${phaseId}/update-templates`,
        {
          selectedTemplateIds: selectedTemplates, // Use the selected templates state
        }
      );
      // If successful, show the success message
      if (response.status === 200) {
        // Assuming response.data contains the new tasks to be added, you can merge them with the existing state
        fetchGroupedTasks();
        setSuccess(true);
      }
    } catch (err) {
      // Handle any errors
      console.error("Error updating templates:", err);
      setError("Failed to update templates and tasks.");
    } finally {
      setLoading(false);
    }
  };

  // Use effect to load grouped tasks when the component mounts
  useEffect(() => {
    fetchGroupedTasks();
  }, [estimateId, phaseId]); // Re-fetch when estimateId or phaseId changes
  const handlePlannedHoursChange = (
    tasktemplateId,
    taskId,
    newPlannedHours
  ) => {
    console.log(
      "template id",
      tasktemplateId,
      "task id",
      taskId,
      "plannedhours",
      newPlannedHours
    );
    setGroupedTasks((prevGroupedTasks) => {
      const updatedGroupedTasks = { ...prevGroupedTasks };
      updatedGroupedTasks[tasktemplateId].tasks = updatedGroupedTasks[
        tasktemplateId
      ].tasks.map((task) =>
        task.id === taskId ? { ...task, planned_hours: newPlannedHours } : task
      );
      return updatedGroupedTasks;
    });
  };
  const updatePlannedHours = async (
    estimateId,
    taskId,
    phaseId,
    plannedHours
  ) => {
    console.log("es id", estimateId);
    try {
      const response = await api.put(
        `/api/estimate/${estimateId}/phase/${phaseId}/update-task-planned-hours`,
        {
          taskId,
          planned_hours: plannedHours,
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to update planned hours");
      }

      console.log(response.data.message); // Assuming `response.data` contains the result
    } catch (error) {
      console.error("Error updating planned hours:", error);
    }
  };

  if (loading) {
    return <p>Loading tasks...</p>;
  }
  return (
    <div>
      <h3>
        {templates.length !== Object.keys(groupedTasks).length
          ? "Update Templates for Estimate"
          : "Update Planned hours for Estimate tasks"}{" "}
      </h3>
      {/* Show loading message */}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span className="loading-animation">LOADING...</span>
        </div>
      )}
      {/* Show error message */}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {/* Show success message */}
      {success && (
        <p style={{ color: "green" }}>Templates updated successfully!</p>
      )}
      {/* Dropdown to select multiple templates */}
      {templates.length !== Object.keys(groupedTasks).length && (
        <div>
          <label>Select Templates:</label>
          <select
            className="e-select-template"
            // multiple
            value={selectedTemplates} // Bind selectedTemplates state to the dropdown
            onChange={handleTemplateChange}
            // size={5}
          >
            <option value={""} disabled>
              Select Template
            </option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
        </div>
      )}
      {/* Display grouped tasks */}
      <div>
        <h3>Grouped Tasks: </h3>
        {Object.keys(groupedTasks).length > 0 ? (
          Object.keys(groupedTasks).map((tasktemplateId) => (
            <div key={tasktemplateId}>
              <h3>
                Task Template: {groupedTasks[tasktemplateId].template_name}
              </h3>
              <ul>
                {groupedTasks[tasktemplateId].tasks.map((task) => (
                  <li key={task.id} className="e-task-list">
                    <label className="task-e-name">{task.task_name}</label>{" "}
                    {/* (Phase ID: {task.phase_id}) */}
                    <label className="task-pl-hr">Planned Hours: </label>
                    <input
                      className="e-ph-hr"
                      type="text"
                      value={task.planned_hours || ""}
                      placeholder="HH:MM"
                      pattern="([01]?[0-9]|2[0-3]):([0-5]?[0-9])"
                      onChange={(e) =>
                        handlePlannedHoursChange(
                          tasktemplateId,
                          task.id,
                          e.target.value
                        )
                      }
                      onBlur={(e) =>
                        updatePlannedHours(
                          estimateId,
                          task.id,
                          task.phase_id,
                          e.target.value
                        )
                      }
                    />
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <p>No tasks found for this phase.</p>
        )}
      </div>
      {/* Button to submit the selected templates */}
      {templates.length !== Object.keys(groupedTasks).length && (
        <button
          onClick={handleUpdateTemplates}
          disabled={loading}
          className="update-e-template-btn"
        >
          Update Templates
        </button>
      )}
    </div>
  );
};

export default UpdateTemplates;
