import React, { useState, useEffect } from "react";
import api from "../../api/axiosConfig";

const TestEmail = () => {
  const [emailSettings, setEmailSettings] = useState(null);
  const [recipient, setRecipient] = useState("");

  useEffect(() => {
    const fetchEmailSettings = async () => {
      try {
        const response = await api.get("/api/email-settings");
        setEmailSettings(response.data);
        console.log(emailSettings);
      } catch (error) {
        console.error("Error fetching email settings:", error);
      }
    };

    fetchEmailSettings();
  }, []);

  const handleTestEmail = async () => {
    if (!emailSettings) {
      alert("No email settings found!");
      return;
    }

    try {
      await api.post("/api/send-test-email", {
        ...emailSettings,
        to: recipient,
      });
      alert("Test email sent successfully!");
    } catch (error) {
      console.error("Error sending test email:", error);
      alert("Error sending test email. Please check your settings.");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3>Send Test Email</h3>
      <h3 className="email-service-header">Email:</h3>
      <input
        type="email"
        placeholder="Recipient Email"
        value={recipient}
        onChange={(e) => setRecipient(e.target.value)}
        required
        className="email-input"
      />
      <button
        onClick={handleTestEmail}
        className="add-btn"
        style={{
          marginTop: "12px",
          marginLeft: "145px",
          textWrap: "nowrap",
          marginRight: "40px",
        }}
      >
        Send Test Email
      </button>
    </div>
  );
};

export default TestEmail;
