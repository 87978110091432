import React, { useState, useEffect, useContext } from "react";
import { ProjectContext } from "../App.js";
import { Link } from "react-router-dom";
import { useRolePermissions } from "../components/Settings/RoleContext.js";
import EditIcon from "@mui/icons-material/Edit";
import { FaChevronDown } from "react-icons/fa";
import "./CustomerList.css";
import { useAuth } from "./Authentication/AuthContext";
import api from "../api/axiosConfig";
import { Helmet } from "react-helmet";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardArrowRight,
} from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";

const CustomerList = () => {
  const { isNavBarOpen } = useContext(ProjectContext);
  const [customers, setCustomers] = useState([]);
  // const [customerFilterbyActiveStatus, setCustomerFilterbyActiveStatus] =
  //   useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "customerno",
    direction: "asc",
  });
  const { hasPermission } = useRolePermissions();
  const [contactsByCustomer, setContactsByCustomer] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [editCustomerId, setEditCustomerId] = useState(null);
  const [editableCustomer, setEditableCustomer] = useState({});
  const [activeCustomerLink, setCustomerActiveLink] =
    useState("activecustomers");
  const [editCutomerDialBox, setEditCutomerDialBox] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedCustomerIdToDelete, setSelectedCustomerIdtoDelete] = useState(
    []
  );
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCustomerLinkClick = (link) => {
    setCustomerActiveLink(link); // Update the active link
  };

  const [newCustomer, setNewCustomer] = useState({
    name: "",
    customerno: "",
    contact_name: "",
    contactno: "",
    email: "",
    designation: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userDetails } = useAuth();
  const userRole = userDetails.role;
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUserssPerPage] = useState(13);
  const updateProjectsPerPage = () => {
    if (window.innerWidth < 1919) {
      setUserssPerPage(8); // Small screens (e.g., mobile)
    } else if (window.innerWidth >= 1920) {
      setUserssPerPage(12); // Medium screens (e.g., tablet)
    } else {
      setUserssPerPage(10); // Large screens (e.g., desktop)
    }
  };
  useEffect(() => {
    // Set initial projects per page
    updateProjectsPerPage();

    // Update projects per page when the window is resized
    window.addEventListener("resize", updateProjectsPerPage);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateProjectsPerPage);
  }, []);

  const fetchInitialData = async () => {
    try {
      const endpoint =
        userDetails.role_name === "Administrator"
          ? `/api/customers`
          : `/api/customers/${userDetails.id}`;
      const res = await api.get(endpoint);
      let customersFilterData;
      const customersData = res.data;

      // Apply filter based on activeCustomerLink
      if (activeCustomerLink === "activecustomers") {
        customersFilterData = customersData.filter(
          (customer) => customer.status === "Active"
        );
      } else if (activeCustomerLink === "inactivecustomers") {
        customersFilterData = customersData.filter(
          (customer) => customer.status === "Inactive"
        );
      } else {
        customersFilterData = customersData; // No filter
      }
      setCustomers(customersFilterData);
      console.log("cus data", customersData);
      const contactsPromises = customersData.map((customer) =>
        api.get(`/api/customers/${customer.id}/contacts`)
      );

      const contactsResponses = await Promise.all(contactsPromises);
      const contactsMap = {};
      contactsResponses.forEach((response, index) => {
        contactsMap[customersData[index].id] = response.data;
      });
      setContactsByCustomer(contactsMap);
    } catch (error) {
      setError(error);
      console.error(
        "Error fetching customer or contact data:",
        error.message || error
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchInitialData();
  }, [userRole, userDetails.id, refreshTrigger, activeCustomerLink]);

  const onHandleAddCustomer = () => {
    setOpenDialog(true);
    document.body.classList.add("no-scroll");
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setNewCustomer({
      name: "",
      customerno: "",
      contact_name: "",
      contactno: "",
      email: "",
      designation: "",
    });
    document.body.classList.remove("no-scroll");
  };

  const handleAddNewCustomer = async () => {
    try {
      const response = await api.post("/api/newcustomers", newCustomer);
      if (response.status === 201) {
        const addedCustomer = response.data;
        setCustomers((prevCustomers) => [...prevCustomers, addedCustomer]);
        toast.success("Customer added successfully!", {
          position: "bottom-center",
          autoClose: 2000,
        });
        setRefreshTrigger(!refreshTrigger);
        setNewCustomer({
          name: "",
          customerno: "",
          contact_name: "",
          contactno: "",
          email: "",
          designation: "",
        });
        closeDialog();
      } else {
        toast.error("Failed to add customer. Please try again.", {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      // toast.error("Failed to add customer", {
      //   position: "bottom-center",
      //   autoClose: 2000,
      // });
      if (error.response && error.response.status === 409) {
        // Handle duplicate customer name error
        toast.error(error.response.data.error, {
          position: "bottom-center",
          autoClose: 2000,
        });
      } else {
        toast.error("Failed to add customer", {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
    }
  };

  const handleEditClick = (customer) => {
    setEditCutomerDialBox(true); // Opens the dialog
    setEditableCustomer(customer); // Sets the customer to be edited
    setEditCustomerId(customer.id); // Sets the customer ID
  };

  const handleSaveEdit = async () => {
    try {
      const response = await api.put(
        `/api/customers/${editCustomerId}`,
        editableCustomer
      );
      if (response.status === 200) {
        setCustomers((prevCustomers) =>
          prevCustomers.map((customer) =>
            customer.id === editCustomerId ? response.data : customer
          )
        );
        toast.success("Customer updated successfully!", {
          position: "bottom-center",
          autoClose: 2000,
        });
        setRefreshTrigger(!refreshTrigger);
        setEditCustomerId(null);
        setEditCutomerDialBox(false);
      }
    } catch (error) {
      toast.error("Failed to update customer", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };
  const handleCustomerStatusChange = async (customerId, value) => {
    console.log(customerId, value);
    try {
      const response = await api.put(`/api/customers-status/${customerId}`, {
        value,
      });

      if (response.status === 200) {
        setCustomers((prevCustomers) =>
          prevCustomers.map((customer) =>
            customer.id === customerId
              ? { ...customer, status: value } // Update only the status field
              : customer
          )
        );
        fetchInitialData();
        toast.success("Customer updated successfully!", {
          position: "bottom-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error("Failed to update customer", {
        position: "bottom-center",
        autoClose: 2000,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCustomer((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDeleteCustomer = async () => {
    const customerIds = selectedCustomerIdToDelete; // Get the array of selected customer IDs
    setIsCheckboxChecked(false);
    setDeleteDialogOpen(false);
    try {
      await api.delete("/api/deletecustomers", {
        // No customer ID in the URL
        data: { customerIds }, // Send the array of IDs in the body
      });
      // Refresh data or update state accordingly
      setRefreshTrigger(!refreshTrigger);
      setSelectedCustomerIdtoDelete([]);
    } catch (error) {
      console.error("Error deleting customers:", error.message || error);
    }
  };

  const handleDeleteCustomerClick = (e, customerId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedCustomerIdtoDelete((prev) => [...prev, customerId.id]); // Add customer ID to the list
    } else {
      setSelectedCustomerIdtoDelete(
        (prev) => prev.filter((id) => id !== customerId.id) // Use the ID directly for filtering
      );
    }
  };

  //close delete dial box
  const handleCloseDial = () => {
    setIsCheckboxChecked(false);
    setDeleteDialogOpen(false);
    // setSelectedCustomerIdtoDelete("");
    document.body.classList.remove("no-scroll");
  };

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  }
  // if (customers && activeCustomerLink) {
  //   let filteredCustomers = [];

  //   // Conditional logic for filtering customers
  //   if (activeCustomerLink === "activecustomers") {
  //     filteredCustomers = customers.filter(
  //       (customer) => customer.status === "Active"
  //     );
  //   } else if (activeCustomerLink === "inactivecustomers") {
  //     filteredCustomers = customers.filter(
  //       (customer) => customer.status === "Inactive"
  //     );
  //   } else if (activeCustomerLink === "overallcustomers") {
  //     filteredCustomers = customers;
  //   }

  //   // Update the state
  //   setCustomerFilterbyActiveStatus(filteredCustomers);
  // }

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedProjects = [...customers].sort((a, b) => {
    // Apply sorting based on the `sortConfig.key` and `sortConfig.direction`
    if (!sortConfig.key) return 0;

    const key = sortConfig.key || "customerno";
    const direction = sortConfig.direction === "asc" ? 1 : -1;

    let aValue = a[key];
    let bValue = b[key];

    // Handle specific cases for sorting by displayed values
    switch (key) {
      case "customerno":
        // Sorting by Customer No as string
        aValue = aValue || "";
        bValue = bValue || "";
        break;
      case "name":
        // Sorting by Customer Name as string
        aValue = aValue?.toLowerCase() || "";
        bValue = bValue?.toLowerCase() || "";
        break;
      case "contact_details":
        // Sorting by the number of contacts per customer
        aValue = contactsByCustomer[a.id]?.length || 0;
        bValue = contactsByCustomer[b.id]?.length || 0;
        break;
      default:
        // Handle other string fields
        if (typeof aValue === "string") aValue = aValue.toLowerCase();
        if (typeof bValue === "string") bValue = bValue.toLowerCase();
        break;
    }

    if (aValue < bValue) return -1 * direction;
    if (aValue > bValue) return 1 * direction;
    return 0;
  });

  const totalPages = Math.ceil(customers.length / usersPerPage);

  // Get current projects to display
  const indexOfLastUsers = currentPage * usersPerPage;
  const indexOfFirstUsers = indexOfLastUsers - usersPerPage;
  let currentUsers = sortedProjects.slice(indexOfFirstUsers, indexOfLastUsers);
  // if (activeCustomerLink === "Active") {
  //   currentUsers = sortedProjects.slice(indexOfFirstUsers, indexOfLastUsers);
  // }
  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
  const handleFirstPage = () => {
    if (currentPage > 1) setCurrentPage(1);
  };
  const handleLastPage = () => {
    if (currentPage < totalPages) setCurrentPage(totalPages);
  };
  return (
    <div className="group-main-container" style={{ height: "848px" }}>
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      <ToastContainer />
      <div
        className="project-nav-bar-details"
        style={{ top: "0px", left: "-92px", position: "relative" }}
      >
        <div className="project-tab-details">
          <Link
            className={`proj-links ${
              activeCustomerLink === "activecustomers" ? "active" : ""
            }`}
            onClick={() => handleCustomerLinkClick("activecustomers")}
          >
            Active Customers
          </Link>
          <Link
            className={`proj-links ${
              activeCustomerLink === "inactivecustomers" ? "active" : ""
            }`}
            onClick={() => handleCustomerLinkClick("inactivecustomers")}
          >
            Inactive Customers
          </Link>
          <Link
            className={`proj-links ${
              activeCustomerLink === "overallcustomers" ? "active" : ""
            }`}
            onClick={() => handleCustomerLinkClick("overallcustomers")}
          >
            Overall Customers
          </Link>
        </div>
        <hr className="proj-line" />
      </div>
      {hasPermission("customers", "delete") &&
        selectedCustomerIdToDelete.length > 0 && (
          <button
            className="delete-customer"
            onClick={() => setDeleteDialogOpen(true)}
            title="Delete Customer"
          >
            Delete
          </button>
        )}
      {hasPermission("customers", "create") && (
        <button
          className="add-new-customer"
          onClick={onHandleAddCustomer}
          // style={{ top: "-136px" }}
          title="New Customer"
        >
          New Customer
        </button>
      )}

      <div
        className={
          isNavBarOpen ? "customer-container" : "customer-container-close"
        }
      >
        <table className="customer-table">
          <thead style={{ position: "sticky", top: "-1px", zIndex: "1" }}>
            <tr>
              {hasPermission("customers", "delete") && (
                <th style={{ width: "2px", textAlign: "center" }}>
                  <FaChevronDown />
                </th>
              )}
              <th
                style={{ width: "200px" }}
                onClick={() => handleSort("customerno")}
              >
                Customer ID
                {sortConfig.key === "customerno" && (
                  <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                )}
              </th>
              <th style={{ width: "200px" }} onClick={() => handleSort("name")}>
                Customer Name
                {sortConfig.key === "name" && (
                  <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                )}
              </th>
              <th
                style={{ width: "200px" }}
                onClick={() => handleSort("status")}
              >
                Status
                {sortConfig.key === "status" && (
                  <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                )}
              </th>
              <th
                style={{ width: "200px" }}
                onClick={() => handleSort("contact_details")}
              >
                Contact Details
                {sortConfig.key === "contact_details" && (
                  <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                )}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.map((customer, index) => (
              <React.Fragment key={customer.id}>
                <tr>
                  {hasPermission("customers", "delete") && (
                    <td>
                      <input
                        style={{ cursor: "pointer" }}
                        type="checkbox"
                        name={customer.name}
                        checked={selectedCustomerIdToDelete.includes(
                          customer.id
                        )}
                        onChange={(e) => handleDeleteCustomerClick(e, customer)}
                      />
                    </td>
                  )}
                  <td>{customer.customerno}</td>
                  <td>
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        border: "none",
                        justifyContent: "space-between",
                      }}
                    >
                      {hasPermission("customers", "update") ? (
                        <Link
                          to={`/api/customers/${customer.id}/contacts`}
                          className="group-name"
                        >
                          {customer.name}
                        </Link>
                      ) : (
                        <span> {customer.name}</span>
                      )}
                      {hasPermission("customers", "update") && (
                        <EditIcon
                          style={{
                            backgroundColor: "transparent",
                            color: "blue",
                          }}
                          className="edit-icon-wrapper"
                          onClick={() => {
                            handleEditClick(customer); // Pass the customer object to handleEditClick
                          }}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    <select
                      className="customer-status-dd"
                      value={customer.status}
                      onChange={(e) =>
                        handleCustomerStatusChange(customer.id, e.target.value)
                      }
                    >
                      <option value={""} disabled>
                        Select Status
                      </option>
                      <option key={"Active"} value={"Active"}>
                        Active
                      </option>
                      <option key={"Inactive"} value={"Inactive"}>
                        Inactive
                      </option>
                    </select>
                  </td>
                  <td>
                    {customer.contacts && customer.contacts.length === 0
                      ? "No Contacts available"
                      : `${customer.contacts?.length || 0} Contact${
                          customer.contacts?.length > 1 ? "s" : ""
                        } available`}
                  </td>
                  <td></td>
                </tr>
              </React.Fragment>
            ))}
            {currentUsers.length === 0 && (
              <tr>
                <td colSpan={6} style={{ textAlign: "center" }}>
                  Customers not available for{" "}
                  {activeCustomerLink.charAt(0).toUpperCase() +
                    activeCustomerLink.slice(1)}{" "}
                  category
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div
          className={
            isNavBarOpen ? "customer-pagination" : "customer-pagination-close"
          }
        >
          <h3>Total : {customers.length}</h3>
          <button
            onClick={handleFirstPage}
            disabled={currentPage === 1}
            className="arrow-project-btn"
          >
            <MdKeyboardDoubleArrowLeft title="First Page" />
          </button>
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="arrow-project-btn"
          >
            {/* */}
            <MdKeyboardArrowLeft title="Previous Page" />
          </button>
          <span
            style={{
              marginTop: "16px",
              textWrap: "nowrap",
            }}
          >
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="arrow-project-btn"
          >
            <MdKeyboardArrowRight title="Next Page" />
          </button>
          <button
            onClick={handleLastPage}
            disabled={currentPage === totalPages}
            className="arrow-project-btn"
          >
            <MdKeyboardDoubleArrowRight title="Last Page" />
          </button>
        </div>
      </div>

      {openDialog && (
        <div className="add-customer-dial">
          <div className="add-customer-content">
            <div
              className="add-project-user-header"
              style={{ marginLeft: "0px" }}
            >
              <h3
                className="add-project-user-heading"
                style={{ marginLeft: "8px", fontWeight: "550px" }}
              >
                Add New Customer
              </h3>
            </div>
            <div className="customer-form">
              <label className="customer-label">Customer ID</label>
              <input
                type="text"
                name="customerno"
                placeholder="Enter customer ID"
                className="customer-input"
                value={newCustomer.customerno}
                onChange={handleInputChange}
              />

              <label className="customer-label">Customer Name:</label>
              <input
                type="text"
                name="name"
                className="customer-input"
                placeholder="Enter customer name"
                value={newCustomer.name}
                onChange={handleInputChange}
              />

              <label className="customer-label">Contact Name:</label>
              <input
                type="text"
                name="contact_name"
                placeholder="Enter contact name"
                className="customer-input"
                value={newCustomer.contact_name}
                onChange={handleInputChange}
              />

              <label className="customer-label">Designation:</label>
              <input
                type="text"
                name="designation"
                placeholder="Enter designation"
                className="customer-input"
                value={newCustomer.designation}
                onChange={handleInputChange}
              />

              <label className="customer-label">Email:</label>
              <input
                type="text"
                name="email"
                placeholder="Enter email address"
                className="customer-input"
                value={newCustomer.email}
                onChange={handleInputChange}
              />

              <label className="customer-label">Contact No:</label>
              <input
                type="text"
                name="contactno"
                placeholder="Enter contact no"
                className="customer-input"
                value={newCustomer.contactno}
                onChange={handleInputChange}
              />
            </div>
            <div className="customer-btn-container">
              <button
                onClick={handleAddNewCustomer}
                style={{ marginLeft: "24px" }}
                className="add-btn"
              >
                Add
              </button>
              <button onClick={closeDialog} className="cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {editCutomerDialBox && (
        <div className="edit-customer-dial">
          <div className="edit-customer-content">
            <div
              className="add-project-user-header"
              style={{ marginLeft: "0px" }}
            >
              <h3
                className="add-project-user-heading"
                style={{ marginLeft: "15px", fontWeight: "550px" }}
              >
                Edit Customer
              </h3>
            </div>
            <div className="customer-form">
              <label className="customer-label">Customer ID:</label>
              <input
                type="text"
                className="customer-input"
                name="customerno"
                value={editableCustomer.customerno}
                onChange={(e) =>
                  setEditableCustomer({
                    ...editableCustomer,
                    customerno: e.target.value,
                  })
                }
              />
              <label className="customer-label">Customer Name:</label>
              <input
                type="text"
                className="customer-input"
                name="customerno"
                value={editableCustomer.name}
                onChange={(e) =>
                  setEditableCustomer({
                    ...editableCustomer,
                    name: e.target.value,
                  })
                }
              />
            </div>
            <div className="edit-customer-btn-container">
              <button
                onClick={handleSaveEdit}
                style={{ marginLeft: "24px" }}
                className="add-btn"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setEditCutomerDialBox(false);
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {deleteDialogOpen && (
        <div className="delete-phase-dial" style={{ marginTop: "-8px" }}>
          <div className="delete-dial">
            <div className="delete-header">
              <h3
                style={{ backgroundColor: "transparent", marginLeft: "13px" }}
              >
                Delete Customer
              </h3>
            </div>
            <div style={{ backgroundColor: "white" }}>
              <p style={{ backgroundColor: "white" }}>
                Are you sure you want to delete this Customer? This action
                cannot be undone.
              </p>
              <input
                style={{ cursor: "pointer" }}
                type="checkbox"
                id="delete-phase"
                checked={isCheckboxChecked}
                onChange={() => setIsCheckboxChecked((prev) => !prev)}
              />
              <label
                htmlFor="delete-phase"
                style={{ backgroundColor: "white", cursor: "pointer" }}
              >
                I understand this action cannot be undone.
              </label>
            </div>
            <div className="delete-dial-btn-con">
              <button
                onClick={handleDeleteCustomer}
                color="secondary"
                variant="contained"
                disabled={!isCheckboxChecked}
                className="add-btn"
                style={{ backgroundColor: isCheckboxChecked ? "red" : "gray" }}
              >
                Delete
              </button>

              <button onClick={handleCloseDial} className="cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerList;
