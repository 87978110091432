import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { ProjectContext } from "../../App";
import { useRolePermissions } from "../Settings/RoleContext";
import api from "../../api/axiosConfig";
import { IoMdClose } from "react-icons/io";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const parseTimeToHours = (timeString) => {
  if (!timeString || timeString === "00:00") return 0; // Handle empty or 00:00
  const [hours, minutes = 0] = timeString.split(":").map(Number);
  return hours + minutes / 60; // Convert to decimal hours
};

const calculateTotalHours = (tasks) => {
  return tasks
    .map((task) => {
      const time = task.total_plannedhours || "00:00";
      return parseTimeToHours(time);
    })
    .reduce((sum, hours) => sum + hours, 0);
};

// Convert decimal hours to HH:MM format
const formatHoursToHHMM = (decimalHours) => {
  const hours = Math.floor(decimalHours);
  const minutes = Math.round((decimalHours - hours) * 60);
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

const EstimateTaskTable = ({
  tasks,
  revision,
  exclusion,
  design,
  detailing,
  designRevise,
  detailingRevise,
}) => {
  console.log("revision", tasks);
  const { estimateId, phaseId } = useParams();
  const [designExclusion, setDesignExclusion] = useState();
  const [detailingExclusion, setDetailingExclusion] = useState();
  const [designSave, setDesignSave] = useState(false);
  const [designNo, setDesignNo] = useState(false);
  const [detailingSave, setDetailingSave] = useState(false);
  const [detailingNo, setDetailingNo] = useState(false);
  const [submitValue, setSubmitValue] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const updateSaveStatus = async (
      value,
      template_name,
      phase_id,
      estimate_id
    ) => {
      try {
        const response = await api.put("/api/update-save-status", {
          value,
          template_name,
          phase_id,
          estimate_id,
        });
        console.log(response.data.message); // Log success message
      } catch (error) {
        console.error(
          "Error updating save status:",
          error.response?.data || error.message
        );
      }
    };

    if (designSave) {
      console.log("Attempting to update save status:", {
        template_name: "Design",
        phase_id: phaseId,
        estimate_id: estimateId,
      });
      toast.success("Estimate submitted successfully!", {
        position: "bottom-center",
        autoClose: 2000,
      });
      setDesignSave(false);
      updateSaveStatus("Yes", "Design", phaseId, estimateId);
    }

    if (detailingSave) {
      console.log("Attempting to update save status:", {
        template_name: "Detailing",
        phase_id: phaseId,
        estimate_id: estimateId,
      });
      toast.success("Estimate submitted successfully!", {
        position: "bottom-center",
        autoClose: 2000,
      });
      setDetailingSave(false);
      fetchData();
      updateSaveStatus("Yes", "Detailing", phaseId, estimateId);
    }
    if (detailingNo) {
      console.log("Attempting to update save status:", {
        template_name: "Detailing",
        phase_id: phaseId,
        estimate_id: estimateId,
      });
      fetchData();
      setDetailingNo(false);
      updateSaveStatus("No", "Detailing", phaseId, estimateId);
    }
    if (designNo) {
      console.log("Attempting to update save status:", {
        template_name: "Detailing",
        phase_id: phaseId,
        estimate_id: estimateId,
      });
      fetchData();
      setDesignNo(false);
      updateSaveStatus("No", "Design", phaseId, estimateId);
    }
    fetchData();
  }, [designSave, phaseId, estimateId, detailingSave, detailingNo, designNo]);

  const fetchData = async () => {
    try {
      const response = await api.get(
        `/api/estimatestasktemplates/submitstatus/${estimateId}/${phaseId}`
      ); // Axios automatically adds the baseURL and headers
      setSubmitValue(response.data); // Axios automatically parses JSON
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error); // Handle error
    }
  };
  useEffect(() => {
    if (exclusion) {
      exclusion.forEach((e) => {
        if (e.template_name === "Design") {
          setDesignExclusion(e.exclusion);
        } else if (e.template_name === "Detailing") {
          setDetailingExclusion(e.exclusion);
        }
      });
    }
  }, [exclusion]);

  const { isNavBarOpen } = useContext(ProjectContext);

  // Split tasks into design and detailing categories
  const designTasks = tasks.filter((task) =>
    task.task_category.startsWith("D")
  );

  const detailingTasks = tasks.filter((task) =>
    task.task_category.startsWith("S")
  );

  // Helper function to convert "HH:MM:SS" to total seconds
  const timeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  // Helper function to convert total seconds back to "HH:MM:SS"
  const secondsToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${secs}`;
  };

  // Calculate total planned hours for CD and MD
  const totalHours = designTasks.reduce(
    (acc, task) => {
      const seconds = timeToSeconds(task.total_plannedhours);
      if (task.code === "CD") {
        acc.CD += seconds;
      } else if (task.code === "MD") {
        acc.MD += seconds;
      }
      return acc;
    },
    { CD: 0, MD: 0 } // Initial accumulator
  );

  // Convert back to "HH:MM:SS" format
  const totalCDHours = secondsToTime(totalHours.CD);
  const totalMDHours = secondsToTime(totalHours.MD);

  console.log("Total CD Hours:", totalCDHours);
  console.log("Total MD Hours:", totalMDHours);

  const designTotalDecimalHours = calculateTotalHours(designTasks);
  const detailingTotalDecimalHours = calculateTotalHours(detailingTasks);

  const designTotalHours = formatHoursToHHMM(designTotalDecimalHours);
  const detailingTotalHours = formatHoursToHHMM(detailingTotalDecimalHours);
  // Convert "HH:MM" to total hours as a numeric value
  const convertToHours = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours + minutes / 60;
  };
  // Custom rounding logic for man-days
  const roundManDays = (value) => {
    const roundedValue = Math.floor(value); // Take the whole number part
    const fractionalPart = value - roundedValue; // Extract fractional part

    if (fractionalPart < 0.25) {
      return roundedValue; // Round down to the nearest whole number
    } else if (fractionalPart < 0.75) {
      return roundedValue + 0.5; // Round to 0.5
    } else {
      return roundedValue + 1; // Round up to the next whole number
    }
  };

  const designTotalHoursNumeric = convertToHours(designTotalHours);
  const connectionDesignTotalHoursNumeric = convertToHours(totalCDHours);
  const memberDesignTotalHoursNumeric = convertToHours(totalMDHours);
  const designManDays = roundManDays(designTotalHoursNumeric / 8);
  const connectionDesignManDays = roundManDays(
    connectionDesignTotalHoursNumeric / 8
  );
  const memberDesignManDays = roundManDays(memberDesignTotalHoursNumeric / 8);

  const detailingTotalHoursNumeric = convertToHours(detailingTotalHours);
  const detailingManDays = roundManDays(detailingTotalHoursNumeric / 8);

  const handleChangeExlusion = async (fieldToUpdate, e) => {
    const updatedExclusion = e.target.value;
    try {
      const response = await api.put(
        `/api/grouped-estimate-exclusion/${estimateId}/${phaseId}`,
        {
          fieldToUpdate,
          updatedExclusion,
        }
      );
      if (response.status !== 200) {
        throw new Error("Failed to update exclusion");
      }
    } catch (error) {
      console.error("Error updating exclusion:", error);
      alert("Failed to update exclusion. Please try again.");
    }
  };

  return (
    <>
      <ToastContainer />
      <div
        className={
          isNavBarOpen
            ? "estimate-details-container"
            : "estimate-details-container-close"
        }
      >
        {designTasks.length > 0 && (
          <table
            className="estimate-details-table"
            style={{ left: detailingTasks.length === 0 ? "-914px" : "-58px" }}
          >
            <thead>
              <tr>
                <th colSpan={4}>
                  Design Tasks{" "}
                  {designTasks.length > 0 && (
                    <>
                      <button
                        className="add-btn"
                        onClick={() => {
                          design(true);
                          setDesignNo(true);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="add-btn"
                        onClick={() => designRevise(true)}
                      >
                        Revise
                      </button>
                      {submitValue.Design === "No" && (
                        <button
                          className="add-btn"
                          onClick={() => setDesignSave(true)}
                          style={{
                            backgroundColor:
                              submitValue.Design === "Yes" ? "Grey" : "#6200ee",
                          }}
                          disabled={submitValue.Design === "Yes"}
                        >
                          Submit
                        </button>
                      )}
                    </>
                  )}
                </th>
              </tr>
              <tr>
                <th>Description</th>
                <th>Total Planned Hours</th>
                <th>Unit Price</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {designTasks.length > 0 ? (
                <>
                  <tr className="group-header">
                    <td
                      colSpan="4"
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      Member Design Tasks
                    </td>
                  </tr>
                  {designTasks
                    .filter((task) => task.code === "MD")
                    .sort((a, b) =>
                      a.task_category.localeCompare(b.task_category)
                    )
                    .map((task, index) => (
                      <tr key={index}>
                        <td style={{ whiteSpace: "wrap" }}>
                          {task.task_category}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {task.total_plannedhours.substring(0, 5) === "00:00"
                            ? ""
                            : task.total_plannedhours.substring(0, 5) || ""}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    ))}
                  <tr className="group-header">
                    <td
                      colSpan="4"
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      Connection Design Tasks
                    </td>
                  </tr>
                  {designTasks
                    .filter((task) => task.code === "CD")
                    .sort((a, b) =>
                      a.task_category.localeCompare(b.task_category)
                    )
                    .map((task, index) => (
                      <tr key={index}>
                        <td style={{ whiteSpace: "wrap" }}>
                          {task.task_category}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {task.total_plannedhours.substring(0, 5) === "00:00"
                            ? ""
                            : task.total_plannedhours.substring(0, 5) || ""}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    ))}
                  <tr>
                    <td>Total</td>
                    <td style={{ textAlign: "center" }}>
                      {designTotalHours === "00:00"
                        ? ""
                        : `${designTotalHours} `}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr style={{ border: "none" }}>
                    <td style={{ border: "none" }}></td>
                  </tr>

                  <tr style={{ border: "none" }}>
                    <td>Connection Design Man days</td>
                    <td style={{ textAlign: "center" }}>
                      {connectionDesignManDays === 0
                        ? ""
                        : connectionDesignManDays}
                    </td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td>Member Design Man days</td>
                    <td style={{ textAlign: "center" }}>
                      {memberDesignManDays === 0 ? "" : memberDesignManDays}
                    </td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td>Total Man days</td>
                    <td style={{ textAlign: "center" }}>
                      {designManDays === 0 ? "" : designManDays}
                    </td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr>
                    <td>Exclusion</td>
                    <td
                      style={{
                        textAlign: "center",
                        width: "650px",
                        padding: "0px",
                      }}
                    >
                      <textarea
                        onChange={(e) => setDesignExclusion(e.target.value)}
                        onBlur={(e) => handleChangeExlusion("Design", e)}
                        value={designExclusion}
                        className={
                          designTasks.length > 0 && detailingTasks.length === 0
                            ? "est-exclusion"
                            : "est-exclusion-close"
                        }
                        maxLength={250}
                      />
                    </td>
                    <td colSpan="2" style={{ border: "0.5px solid white" }}>
                      {revision.tasks && revision.tasks.Design?.length > 0 && (
                        <Link
                          className="revision-para"
                          to={`/estimate/revision/design/${estimateId}/${phaseId}`}
                        >
                          {revision.tasks.Design.length} Revision available
                        </Link>
                      )}
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {detailingTasks.length > 0 && (
          <table
            className={"estimate-details-table"}
            style={{ left: designTasks.length === 0 ? "-914px" : "-58px" }}
          >
            <thead>
              <tr>
                <th colSpan={4}>
                  Detailing Tasks
                  {detailingTasks.length > 0 && (
                    <>
                      <button
                        className="add-btn"
                        onClick={() => {
                          detailing(true);
                          setDetailingNo(true);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="add-btn"
                        onClick={() => detailingRevise(true)}
                      >
                        Revise
                      </button>
                      {submitValue.Detailing === "No" && (
                        <button
                          className="add-btn"
                          style={{
                            backgroundColor:
                              submitValue.Detailing === "Yes"
                                ? "Grey"
                                : "#6200ee",
                          }}
                          disabled={submitValue.Detailing === "Yes"}
                          onClick={() => setDetailingSave(true)}
                        >
                          Submit
                        </button>
                      )}
                    </>
                  )}
                </th>
              </tr>
              <tr>
                <th>Description</th>
                <th>Total Planned Hours</th>
                <th>Unit Price</th>
                <th>Total Price</th>
              </tr>
            </thead>
            <tbody>
              {detailingTasks.length > 0 ? (
                <>
                  {detailingTasks
                    .sort((a, b) =>
                      a.task_category.localeCompare(b.task_category)
                    )
                    .map((task, index) => (
                      <tr key={index}>
                        <td style={{ whiteSpace: "wrap" }}>
                          {task.task_category}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {task.total_plannedhours.substring(0, 5) === "00:00"
                            ? ""
                            : task.total_plannedhours.substring(0, 5) || ""}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    ))}
                  {/* Add the total rows at the end */}
                  <tr>
                    <td>Total</td>
                    <td style={{ textAlign: "center" }}>
                      {detailingTotalHours === "00:00"
                        ? ""
                        : `${detailingTotalHours} `}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td>GA drawing for approval</td>
                    <td style={{ textAlign: "center" }}></td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td>Fabrication drawing</td>
                    <td style={{ textAlign: "center" }}></td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td>Erection drawing</td>
                    <td style={{ textAlign: "center" }}></td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td>Total Man Days</td>
                    <td style={{ textAlign: "center" }}>
                      {detailingManDays === 0 ? "" : detailingManDays}
                    </td>
                    <td style={{ border: "none" }}></td>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr style={{ border: "none" }}>
                    <td style={{ border: "none" }}></td>
                  </tr>
                  <tr>
                    <td>Exclusion</td>
                    <td
                      style={{
                        textAlign: "center",
                        width: "650px",
                        padding: "0px",
                        height: "38px",
                      }}
                    >
                      <textarea
                        onChange={(e) => setDetailingExclusion(e.target.value)}
                        onBlur={(e) => handleChangeExlusion("Detailing", e)}
                        value={detailingExclusion}
                        className={
                          designTasks.length === 0 && detailingTasks.length > 0
                            ? "est-exclusion"
                            : "est-exclusion-close"
                        }
                        maxLength={250}
                      />
                    </td>
                    <td colSpan="2" style={{ border: "0.5px solid white" }}>
                      {revision.tasks &&
                        revision.tasks.Detailing?.length > 0 && (
                          <Link
                            style={{ marginTop: "30px" }}
                            className="revision-para"
                            to={`/estimate/revision/detailing/${estimateId}/${phaseId}`}
                          >
                            {revision.tasks.Detailing.length} Revision available
                          </Link>
                        )}
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      {/* <div className="revision-summary">
        {revision.tasks && revision.tasks.Design?.length > 0 ? (
          <Link
            className="revision-para"
            to={`/estimate/revision/design/${estimateId}/${phaseId}`}
          >
            Design :{revision.tasks.Design.length} Revision available
          </Link>
        ) : (
          <p className="revision-para">Design : No revision available</p>
        )}
        {revision.tasks && revision.tasks.Detailing?.length > 0 ? (
          <Link
            style={{ marginTop: "30px" }}
            className="revision-para"
            to={`/estimate/revision/detailing/${estimateId}/${phaseId}`}
          >
            Detailing : {revision.tasks.Detailing.length} Revision available
          </Link>
        ) : (
          <p className="revision-para"> Detailing : No revision available</p>
        )}
      </div> */}
    </>
  );
};

export default EstimateTaskTable;
