import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../api/axiosConfig";
import { IoMdClose } from "react-icons/io";
import "../../styles/Settings/Settings.css";

const fetchData = async (url, setData, setError, setLoading) => {
  setLoading(true);
  try {
    const response = await api.get(url);
    setData(response.data);
    setLoading(false);
    console.log("data", response.data);
  } catch (error) {
    console.error("Error fetching data:", error);
    setError(error);
  } finally {
    setLoading(false);
  }
};

const RoleSettings = () => {
  const [roleSettingOpen, setRoleSettingOpen] = useState(false);
  const [rolePermissions, setRolePermissions] = useState({});
  const [roles, setRoles] = useState([]);
  const [modules, setModules] = useState([]);
  const [roleDialboxOpen, setRoleDialboxOpen] = useState(false);
  const [moduleDialboxOpen, setModuleDialboxOpen] = useState(false);
  const [newRole, setNewRole] = useState({ name: "" });
  const [newModule, setNewModule] = useState({ name: "" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [removePermissions, setRemovePermissions] = useState(new Set());
  const [userRole, setUserRole] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState();

  const createPermissionsMap = (permissionsData) => {
    const rolePermissions = {};
    permissionsData.forEach((item) => {
      const { role_name, module_name, action_name } = item;
      if (!rolePermissions[role_name]) rolePermissions[role_name] = {};
      if (!rolePermissions[role_name][module_name])
        rolePermissions[role_name][module_name] = [];
      rolePermissions[role_name][module_name].push(action_name);
    });
    return rolePermissions;
  };

  useEffect(() => {
    fetchData("/api/roles", setRoles, setError, setLoading);
    fetchData("/api/modules", setModules, setError, setLoading);
  }, []);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await api.get("/api/rolePermissions");
        const permissionsMap = createPermissionsMap(response.data);
        setRolePermissions(permissionsMap);
        console.log("premission", permissionsMap);
      } catch (error) {
        console.error("Failed to fetch permissions", error);
      }
    };
    fetchPermissions();
  }, []);

  const updatePermissions = async () => {
    const permissionsToUpdate = rolePermissions[userRole];
    console.log("permissions to update", permissionsToUpdate);
    if (!permissionsToUpdate) {
      alert("No permissions to update.");
      return;
    }
    try {
      await api.put(`/api/rolepermissions/${userRole}`, {
        permissions: permissionsToUpdate,
        removePermissions: Array.from(removePermissions),
      });
      alert("Permissions updated successfully!");
      setRoleSettingOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Failed to update permissions", error);
      alert("Failed to update permissions.");
    }
  };

  const handleCheckboxChange = (role, category, action) => {
    console.log("role,category,action", role, category, action);
    setRolePermissions((prevPermissions) => {
      const currentPermissions = prevPermissions[role]?.[category] || [];
      const updatedPermissions = currentPermissions.includes(action)
        ? currentPermissions.filter((perm) => perm !== action)
        : [...currentPermissions, action];

      setRemovePermissions((prev) => {
        const updatedSet = new Set(prev);
        if (currentPermissions.includes(action)) {
          updatedSet.add({ module: category, action });
        } else {
          updatedSet.delete({ module: category, action });
        }
        return updatedSet;
      });

      return {
        ...prevPermissions,
        [role]: {
          ...prevPermissions[role],
          [category]: updatedPermissions,
        },
      };
    });
  };

  const handleEditRole = (roleName) => {
    setUserRole(roleName);
    setRoleSettingOpen(true);
    setRemovePermissions(new Set());
  };

  const handleAddNewRole = async () => {
    try {
      // Check if the role name is not empty
      if (!newRole.name.trim()) {
        alert("Role name cannot be empty");
        return;
      }

      // Send POST request to create a new role
      const response = await api.post("/api/roles", { name: newRole.name });
      console.log("role name", newRole.name);
      // Handle the response
      if (response.status === 201) {
        setRoles((prevRoles) => [...prevRoles, response.data]);
        alert("Role added successfully!");
        setRoleDialboxOpen(false);
        setNewRole({ name: "" }); // Clear the input field
      }
    } catch (error) {
      console.error("Failed to add new role:", error);
      alert("Failed to add role. Please try again.");
    }
  };
  const handleAddNewModule = async () => {
    try {
      // Check if the role name is not empty
      if (!newModule.name.trim()) {
        alert("Module name cannot be empty");
        return;
      }

      // Send POST request to create a new role
      const response = await api.post("/api/modules", { name: newModule.name });
      console.log("module name", newModule.name);
      // Handle the response
      if (response.status === 201) {
        setModules((prevModules) => [...prevModules, response.data]);
        alert("Module added successfully!");
        setModuleDialboxOpen(false);
        setNewModule({ name: "" }); // Clear the input field
        window.location.reload();
      }
    } catch (error) {
      console.error("Failed to add new module:", error);
      alert("Failed to add module. Please try again.");
    }
  };

  const handleDeleteRole = async (roleId) => {
    try {
      // Send DELETE request to the backend
      const response = await api.delete(`/api/roles/${roleId}`);
      if (response.data.message === "Role deleted successfully") {
        // Optimistically remove the deleted role from the list
        setRoles((prevRoles) => prevRoles.filter((role) => role.id !== roleId));
        setDeleteDialogOpen(false);
        setIsCheckboxChecked(false);
        alert("Role deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting role:", error);
      alert("Failed to delete the role.");
    }
  };

  return (
    <div>
      <button
        className="add-btn role-btn"
        onClick={() => setRoleDialboxOpen(true)}
      >
        Add Role
      </button>
      <button
        className="add-module-btn"
        onClick={() => setModuleDialboxOpen(true)}
      >
        Add Module
      </button>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span className="loading-animation">LOADING...</span>
        </div>
      ) : error ? (
        <p>Error loading roles: {error.message}</p>
      ) : (
        !roleSettingOpen && (
          <ul>
            {roles?.length ? (
              roles.map((role) => (
                <li key={role.id}>
                  <div className="role-list">
                    <span
                      className="initial"
                      style={{
                        marginRight: "25px",
                        marginTop: "5px",
                        height: "40px",
                        width: "40px",
                        fontSize: "22px",
                      }}
                    >
                      {role.name ? role.name.slice(0, 2).toUpperCase() : ""}
                    </span>
                    <div className="role-right-container">
                      <div>
                        <h3 className="role-name">
                          {role.name ? role.name.replace("_", " ") : role.name}
                        </h3>
                        <p className="role-description">
                          User Profile Last Modified By Admin{" "}
                        </p>
                      </div>
                      <div className="actions-container">
                        <span>
                          <EditIcon
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={() => handleEditRole(role.name)}
                          />
                        </span>
                        <span>
                          <DeleteIcon
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() => {
                              setDeleteDialogOpen(true);
                              setRoleToDelete(role.id);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <p>Loading roles...</p>
            )}
          </ul>
        )
      )}
      {roleSettingOpen && (
        <div className="role-permission-mapping-container">
          <span className="close-icon">
            <IoMdClose onClick={() => setRoleSettingOpen(false)} />
          </span>
          <div className="action-div">
            <p className="actions">Create</p>
            <p className="actions">Read</p>
            <p className="actions">Update</p>
            <p className="actions">Delete</p>
          </div>
          {modules.map((module) => (
            <div key={module.id}>
              <h3 style={{ marginRight: "40px" }}>
                {module.name.charAt(0).toUpperCase() + module.name.slice(1)}
              </h3>
              <div className="checkbox-container">
                {["create", "read", "update", "delete"].map((action) => (
                  <div key={action} style={{ marginRight: "60px" }}>
                    <input
                      type="checkbox"
                      checked={rolePermissions[userRole]?.[
                        module.name
                      ]?.includes(action)}
                      className="action-label"
                      onChange={() =>
                        handleCheckboxChange(userRole, module.name, action)
                      }
                    />
                    {/* <label>
                      {action.charAt(0).toUpperCase() + action.slice(1)}
                    </label> */}
                  </div>
                ))}
              </div>
            </div>
          ))}
          <button
            onClick={updatePermissions}
            className="add-btn"
            style={{ position: "relative", left: "330px", width: "136px" }}
          >
            Save Permissions
          </button>
        </div>
      )}
      {roleDialboxOpen && (
        <div className="add-role-dial">
          <div className="add-role-settings">
            <div className="add-role-header">
              <h3 className="add-new-user-heading">Add New Role</h3>
            </div>
            <h3 className="label-header-element">Role Name</h3>
            <input
              className="user-element"
              placeholder="Enter Role Name"
              type="text"
              value={newRole.name}
              onChange={(e) => setNewRole({ name: e.target.value })}
            />
            <div className="task-btn-container">
              <button className="add-btn" onClick={handleAddNewRole}>
                Add
              </button>
              <button
                className="cancel-btn"
                onClick={() => {
                  setRoleDialboxOpen(false);
                  setNewRole([]);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {moduleDialboxOpen && (
        <div className="add-role-dial">
          <div className="add-role-settings">
            <div className="add-role-header">
              <h3 className="add-new-user-heading">Add New Module</h3>
            </div>
            <h3 className="label-header-element">Module Name</h3>
            <input
              className="user-element"
              placeholder="Enter Module Name"
              type="text"
              value={newModule.name}
              onChange={(e) => setNewModule({ name: e.target.value })}
            />
            <div className="task-btn-container">
              <button className="add-btn" onClick={handleAddNewModule}>
                Add
              </button>
              <button
                className="cancel-btn"
                onClick={() => {
                  setModuleDialboxOpen(false);
                  setNewModule([]);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {deleteDialogOpen && (
        <div className="delete-phase-dial">
          <div className="delete-dial">
            <div className="delete-header">
              <h3
                style={{ backgroundColor: "transparent", marginLeft: "15px" }}
              >
                Delete Role
              </h3>
            </div>
            <div style={{ backgroundColor: "white" }}>
              <p style={{ backgroundColor: "white" }}>
                Are you sure you want to delete this Role. This action cannot be
                undone.
              </p>
              <input
                type="checkbox"
                id="delete-phase"
                checked={isCheckboxChecked}
                onChange={() => setIsCheckboxChecked((prev) => !prev)}
              />
              <label
                htmlFor="delete-phase"
                style={{ backgroundColor: "white" }}
              >
                I understand this action cannot be undone.
              </label>
            </div>
            <div className="delete-dial-btn-con">
              <button
                onClick={() => handleDeleteRole(roleToDelete)}
                color="secondary"
                variant="contained"
                disabled={!isCheckboxChecked}
                className="add-btn"
                style={{ backgroundColor: isCheckboxChecked ? "red" : "gray" }}
              >
                Delete
              </button>

              <button
                onClick={() => {
                  setDeleteDialogOpen(false);
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoleSettings;
