import React, { useEffect, useState, useContext } from "react";
import { ProjectContext } from "../../App";
import { Link, useParams } from "react-router-dom";
import api from "../../api/axiosConfig";
import { format } from "date-fns";
import { IoMdClose } from "react-icons/io";
import { TbArrowBackUp } from "react-icons/tb";

const RevisionTable = () => {
  const { templateName, estimateId, phaseId } = useParams();
  const Template = templateName.charAt(0).toUpperCase() + templateName.slice(1);
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const [revision, setRevision] = useState([]);
  const [revisionTasks, setRevisionTasks] = useState([]);
  const [error, setError] = useState(null); // To handle errors
  const [loading, setLoading] = useState(true); // To show loading state
  const [revisedTemplateId, setRevisedTemplateId] = useState("");
  const codeDescriptions = {
    MD: "Member Design",
    CD: "Connection Design",
    SD: "Steel Detailing",
  };
  const fetchTaskData = async () => {
    try {
      const revisionResponse = await api.get(
        `/api/estimatesrevision/${estimateId}/${phaseId}`
      );
      const revisionTasksResponse = await api.get(
        `/api/estimate/revisedtasks/${revisedTemplateId}`
      );
      setRevision(revisionResponse.data);
      console.log("revision", revisionResponse.data);
      setRevisionTasks(revisionTasksResponse.data);
      console.log("revision tasks", revisionTasksResponse.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTaskData();
  }, [estimateId, phaseId, revisedTemplateId]);

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  }

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }
  const groupedTasks = revisionTasks.reduce((groups, task) => {
    const { code } = task;
    if (!groups[code]) {
      groups[code] = []; // Initialize the group for this code
    }
    groups[code].push(task); // Add the task to the group
    return groups;
  }, {});

  return (
    <div className="revision-main-container">
      <Link to={`/estimate/phases/${estimateId}/${phaseId}`}>
        <IoMdClose className="revise-task-close" />
      </Link>
      <div
        className={
          isNavBarOpen
            ? "revision-details-container"
            : "revision-details-container-close"
        }
      >
        <h3 style={{ marginBottom: "5px", marginRight: "20px" }}>
          Estimate No : {revision.estimate.estimationno}
        </h3>
        <h3 style={{ marginBottom: "5px", marginRight: "20px" }}>
          Project Name : {revision.estimate.projectname}
        </h3>
        <h3 style={{ marginBottom: "5px", marginRight: "20px" }}>
          Phase No : {revision.estimate.phase_number}
        </h3>
        <h3 style={{ marginBottom: "5px", marginRight: "20px" }}>
          Phase title : {revision.estimate.phase_name}
        </h3>
      </div>
      {!revisedTemplateId && (
        <table
          className={
            isNavBarOpen
              ? "estimate-details-table"
              : "estimate-details-table-close"
          }
        >
          <thead>
            <tr>
              <th style={{ width: "60px" }}>Rev No</th>
              <th>Date</th>
              <th>Template Name</th>
              <th>Estimate By</th>
              <th>Revised By</th>
            </tr>
          </thead>
          <tbody>
            {revision.tasks[Template] &&
              revision.tasks[Template].map((item, index) => (
                <tr key={index}>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => setRevisedTemplateId(item.id)}
                  >
                    Rev {index + 1}
                  </td>
                  <td>{format(new Date(item.date), "dd-MM-yyyy  hh:mm a")}</td>
                  <td>{item.template_name}</td>
                  <td>{item.estimated_by_name}</td>
                  <td>{item.revised_by_name}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {revisedTemplateId && (
        <div>
          <TbArrowBackUp
            className="revise-task-close"
            onClick={() => setRevisedTemplateId("")}
          />
          <table
            className={
              isNavBarOpen
                ? "revision-details-table"
                : "revision-details-table-close"
            }
          >
            <thead>
              <tr>
                <th style={{ width: "3px" }}>SNo</th>
                <th style={{ width: "90px" }}>Task Name</th>
                <th style={{ width: "16px" }}>Planned Hours</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(groupedTasks).map((code) => (
                <React.Fragment key={code}>
                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#f0f0f0",
                      }}
                    >
                      {codeDescriptions[code]}
                    </td>
                  </tr>
                  {groupedTasks[code].map((task, index) => (
                    <tr key={task.id}>
                      <td style={{ textAlign: "center" }}>{index + 1}</td>
                      <td>{task.task_name}</td>
                      <td style={{ textAlign: "center" }}>
                        {task.formatted_planned_hours}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default RevisionTable;
