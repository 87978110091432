import React, { useState, useContext, useEffect } from "react";
import { ProjectContext } from "../../App";
import "./AddUser.css";
import api from "../../api/axiosConfig";
import { GoEye } from "react-icons/go";
import { GoEyeClosed } from "react-icons/go";
const fetchData = async (url, setData, setError, setLoading) => {
  try {
    const response = await api.get(url);
    setData(response.data);
    console.log("data", response.data);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching data:", error);
    setError(error);
  }
};
const AddUser = () => {
  const { openDialog, setOpenDialog } = useContext(ProjectContext);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    designation: "",
    employeecode: "",
    gender: "",
    phone: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const roleEndpoint = "/api/roles";
    fetchData(roleEndpoint, setRoles, setError, setLoading);
  }, []);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // adding user details
  const handleAddUser = async () => {
    if (
      !newUser.name ||
      !newUser.email ||
      !newUser.role ||
      !newUser.designation
    ) {
      alert("All fields are required.");
      return;
    }

    try {
      const response = await api.post("/api/users", {
        ...newUser,
      });

      setOpenDialog(false);
      document.body.classList.remove("no-scroll");

      const data = response.data;
      setUsers([...users, data]);
      setNewUser({
        name: "",
        email: "",
        role: "",
        designation: "",
        password: "",
      });
      alert("New User added");
    } catch (error) {
      console.error("Error adding user:", error);
      alert("Error adding user. Please try again.");
    }
  };
  return (
    <>
      {openDialog && (
        <>
          <div
            className="add-layout-new-user-container"
            style={{ zIndex: "16" }}
          >
            <div className="add-header">
              <h3 className="add-new-user-heading">Add new user</h3>
            </div>
            <div className="add-user-content">
              <label htmlFor="name" className="add-user-label-header-element">
                Name
              </label>
              <input
                id="name"
                placeholder="Name"
                value={newUser.name}
                className="user-element"
                onChange={(e) =>
                  setNewUser({ ...newUser, name: e.target.value })
                }
              />

              <label htmlFor="email" className="add-user-label-header-element">
                Email
              </label>
              <input
                id="email"
                value={newUser.email}
                placeholder="Email"
                className="user-element"
                onChange={(e) =>
                  setNewUser({ ...newUser, email: e.target.value })
                }
              />
              <label htmlFor="role" className="add-user-label-header-element">
                Role
              </label>
              <select
                value={newUser.role} // Use role for each user
                onChange={(e) =>
                  setNewUser({ ...newUser, role: e.target.value })
                }
                className="add-user-gender"
              >
                <option value="" disabled>
                  Select Role
                </option>
                {roles.map((role) => (
                  <option key={role.id} value={role.name}>
                    {role.name}
                  </option>
                ))}
              </select>
              {/* <input
                id="role"
                value={newUser.role}
                className="user-element"
                placeholder="Role"
                onChange={(e) =>
                  setNewUser({ ...newUser, role: e.target.value })
                }
              /> */}
              <label
                htmlFor="designation"
                className="add-user-label-header-element"
              >
                Profile
              </label>
              <input
                id="designation"
                value={newUser.designation}
                className="user-element"
                placeholder="Profile"
                onChange={(e) =>
                  setNewUser({ ...newUser, designation: e.target.value })
                }
              />
              <label
                htmlFor="designation"
                className="add-user-label-header-element"
              >
                Employee Code
              </label>
              <input
                id="designation"
                value={newUser.employeecode}
                className="user-element"
                placeholder=" Enter Employee Code"
                onChange={(e) =>
                  setNewUser({ ...newUser, employeecode: e.target.value })
                }
              />
              <label
                htmlFor="designation"
                className="add-user-label-header-element"
              >
                Mobile No
              </label>
              <input
                id="designation"
                value={newUser.phone}
                className="user-element"
                placeholder=" Enter Mobile No"
                onChange={(e) =>
                  setNewUser({ ...newUser, phone: e.target.value })
                }
              />
              <label
                htmlFor="designation"
                className="add-user-label-header-element"
              >
                Gender
              </label>
              <select
                className="add-user-gender"
                value={newUser.gender}
                onChange={(e) =>
                  setNewUser({ ...newUser, gender: e.target.value })
                }
              >
                <option value="" disabled>
                  Select Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              <div className="password-input">
                <label
                  htmlFor="designation"
                  className="add-user-label-header-element"
                >
                  Password
                </label>
                <input
                  id="designation"
                  type={showPassword ? "text" : "password"}
                  value={newUser.password}
                  className="user-element-password"
                  placeholder=" Enter Password"
                  onChange={(e) =>
                    setNewUser({ ...newUser, password: e.target.value })
                  }
                />
                {showPassword ? (
                  <GoEyeClosed
                    className="adduser-password-icon"
                    onClick={handleClickShowPassword}
                  />
                ) : (
                  <GoEye
                    className="adduser-password-icon"
                    onClick={handleClickShowPassword}
                  />
                )}
              </div>
            </div>
            <div className="add-new-user-button-a-container">
              <button
                onClick={handleAddUser}
                style={{ marginLeft: "22px", marginRight: "20px" }}
                className="add-btn"
              >
                Add
              </button>
              <button
                onClick={() => setOpenDialog((prev) => !prev)}
                style={{ marginLeft: "-02px" }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddUser;
