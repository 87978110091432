import React, { useEffect, useState, useContext } from "react";
import { format } from "date-fns";
import { useAuth } from "../Authentication/AuthContext";
import { useRolePermissions } from "../Settings/RoleContext";
import { useParams } from "react-router-dom";
import api from "../../api/axiosConfig";
import { MdNumbers } from "react-icons/md";
import { IoCalendarOutline } from "react-icons/io5";
import { MdOutlineCategory } from "react-icons/md";
import { GrStatusGood } from "react-icons/gr";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { TbNotes } from "react-icons/tb";
import { LiaBusinessTimeSolid } from "react-icons/lia";
import { IoTimerOutline } from "react-icons/io5";
import { GrUserExpert } from "react-icons/gr";
import { MdCreditScore } from "react-icons/md";
import { CgRename } from "react-icons/cg";
import { BiTask } from "react-icons/bi";
import { SiTyper } from "react-icons/si";
import { MdOutlineSubtitles } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { ProjectContext } from "../../App";
import { utils, writeFile } from "xlsx";
import "./TimeSheetTable.css";
import { Helmet } from "react-helmet";

const General = () => {
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const [timesheets, setTimesheets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc"); // Default sort order for date grouping
  const [groupBy, setGroupBy] = useState("user"); // Global Group By state
  const { projectId } = useParams();
  const { hasPermission } = useRolePermissions();
  const { userDetails } = useAuth();
  const userId = userDetails.id;

  const formatTime = (duration) => {
    const [hours, minutes] = duration.split(":"); // Split the duration string into hours and minutes
    return `${hours}:${minutes}`; // Return only hours and minutes
  };
  const fetchTimesheets = async () => {
    try {
      setLoading(true);
      const endpoint =
        userDetails.role_name === "Administrator"
          ? `/api/timesheettable/projects/${projectId}` // Admin can fetch all timesheets
          : `/api/timesheettable/projects/${projectId}/user/${userDetails.id}`; // Non-admins fetch only their timesheets

      const response = await api.get(endpoint);
      const filteredTimesheets = response.data.filter(
        (timesheet) => timesheet.issue_id !== null
      );
      setTimesheets(filteredTimesheets);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const calculateSummary = (timesheets) => {
    return timesheets.reduce(
      (summary, timesheet) => {
        const durationMinutes = parseDuration(timesheet.duration);

        if (timesheet.billable === 1) {
          summary.billable += durationMinutes;
        } else {
          summary.nonBillable += durationMinutes;
        }

        if (timesheet.approvalstatus === "Approved") {
          summary.approved += durationMinutes;
        } else {
          summary.unapproved += durationMinutes;
        }

        return summary;
      },
      {
        billable: 0,
        nonBillable: 0,
        approved: 0,
        unapproved: 0,
      }
    );
  };

  // Grouping functions
  const groupByUser = (timesheets) => {
    return timesheets.reduce((groups, timesheet) => {
      const user = timesheet.user_name;
      if (!groups[user]) {
        groups[user] = [];
      }
      groups[user].push(timesheet);
      return groups;
    }, {});
  };

  const groupByDate = (timesheets) => {
    const grouped = timesheets.reduce((groups, timesheet) => {
      const date = new Date(timesheet.date).toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(timesheet);
      return groups;
    }, {});

    const sortedDates = Object.keys(grouped).sort((a, b) => {
      return sortOrder === "asc"
        ? new Date(a) - new Date(b)
        : new Date(b) - new Date(a);
    });

    return sortedDates.reduce((sortedGroups, date) => {
      sortedGroups[date] = grouped[date];
      return sortedGroups;
    }, {});
  };

  const groupByCategory = (timesheets) => {
    return timesheets.reduce((groups, timesheet) => {
      const category = timesheet.variation_id
        ? "Variation"
        : timesheet.issue_id
        ? "Issue"
        : "General";
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(timesheet);
      return groups;
    }, {});
  };

  const updateApprovalStatus = async (timesheetId, newStatus) => {
    try {
      await api.put(`/api/timesheettable/${timesheetId}/${userId}/approval`, {
        status: newStatus,
      });
      fetchTimesheets(); // Refresh the data after update
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };

  const getGroupedTimesheets = (timesheets, groupBy) => {
    switch (groupBy) {
      case "user":
        return groupByUser(timesheets);
      case "date":
        return groupByDate(timesheets);
      case "category":
        return groupByCategory(timesheets);
      default:
        return {};
    }
  };

  const parseDuration = (duration) => {
    const [hours, minutes] = duration.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
  };

  const groupedByPhase = timesheets.reduce((phases, timesheet) => {
    const phase = timesheet.phase_title;
    if (!phases[phase]) {
      phases[phase] = [];
    }
    phases[phase].push(timesheet);
    return phases;
  }, {});

  const overallSummary = Object.keys(groupedByPhase).reduce(
    (overall, phaseTitle) => {
      const phaseSummary = calculateSummary(groupedByPhase[phaseTitle]);
      overall.billable += phaseSummary.billable;
      overall.nonBillable += phaseSummary.nonBillable;
      overall.approved += phaseSummary.approved;
      overall.unapproved += phaseSummary.unapproved;
      return overall;
    },
    {
      billable: 0,
      nonBillable: 0,
      approved: 0,
      unapproved: 0,
    }
  );
  const exportToExcel = () => {
    const exportData = [];
    const columnWidths = {};

    // Helper function to update column widths
    const updateColumnWidths = (row) => {
      row.forEach((cell, index) => {
        if (cell && typeof cell === "string") {
          const length = cell.length;
          if (!columnWidths[index] || length > columnWidths[index]) {
            columnWidths[index] = length;
          }
        }
      });
    };

    // Loop over phases
    let rowOffset = 0;
    const merges = [];

    Object.keys(groupedByPhase).forEach((phaseTitle) => {
      const groupedTimesheets = getGroupedTimesheets(
        groupedByPhase[phaseTitle],
        groupBy
      );

      // Add Phase Title as a header
      exportData.push([phaseTitle]);
      updateColumnWidths([phaseTitle]);

      // Merge phase title
      merges.push({
        s: { r: rowOffset, c: 0 },
        e: { r: rowOffset, c: Object.keys(columnWidths).length - 1 },
      });

      rowOffset += 1;

      // Loop over groups (like user/date/category based on `groupBy`)
      Object.keys(groupedTimesheets).forEach((key) => {
        // Add group key as a subheader (e.g., User name, Date, or Category)
        exportData.push([key]);
        updateColumnWidths([key]);

        // Merge group key
        merges.push({
          s: { r: rowOffset, c: 0 },
          e: { r: rowOffset, c: Object.keys(columnWidths).length - 1 },
        });

        rowOffset += 1;

        // Add table headers (customize these headers as needed)
        const headers = [
          "Log Title",
          ...(groupBy === "user" ? ["Date", "Category"] : []),
          ...(groupBy === "date" ? ["User", "Category"] : []),
          ...(groupBy === "category" ? ["User", "Date"] : []),
          "Project",
          "Task",
          "Duration",
          "Billable",
          "Status",
          "Created By",
          "Created At",
          "Approved By",
          "Approved At",
        ];
        exportData.push(headers);
        updateColumnWidths(headers);

        // Loop over timesheets in the group
        groupedTimesheets[key].forEach((timesheet) => {
          const row = [
            timesheet.logtitle || "Not Available",
            ...(groupBy === "user"
              ? [
                  format(timesheet.date, "dd-MM-yyyy"),
                  timesheet.variation_id
                    ? "Variation"
                    : timesheet.issue_id
                    ? "Issue"
                    : "General",
                ]
              : []),
            ...(groupBy === "date" ? [timesheet.user_name, "General"] : []),
            ...(groupBy === "category" ? [timesheet.user_name, "General"] : []),
            timesheet.project_name,
            timesheet.task_name,
            formatTime(timesheet.duration),
            timesheet.billable === 1 ? "Billable" : "Non Billable",
            timesheet.approvalstatus,
            timesheet.creator_name,
            timesheet.created_at ? timesheet.created_at : "Not Available",
            timesheet.approver_name || "Not Available",
            timesheet.approved_at && timesheet.approved_at !== "NULL"
              ? format(timesheet.approved_at, "dd-MM-yyyy")
              : "Not Approved",
          ];
          exportData.push(row);
          updateColumnWidths(row);
        });

        // Add an empty row between groups
        exportData.push([]);
        rowOffset += 1; // account for empty row
      });

      // Add an empty row between phases
      exportData.push([]);
      rowOffset += 1; // account for empty row
    });

    // Create a worksheet and workbook
    const worksheet = utils.aoa_to_sheet(exportData);

    // Set column widths based on maximum content length
    worksheet["!cols"] = Object.keys(columnWidths).map((index) => ({
      wch: columnWidths[index] + 2,
    }));

    // Apply cell merges
    worksheet["!merges"] = merges;

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Timesheet Data");

    // Write the file
    writeFile(
      workbook,
      `Timesheet_Data_${new Date().toLocaleDateString()}.xlsx`
    );
  };
  useEffect(() => {
    fetchTimesheets();
  }, [projectId, userDetails]);

  if (loading)
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <span className="loading-animation">LOADING...</span>
      </div>
    );
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <Helmet>
        <title>Msuite - Projects</title>
      </Helmet>
      {!dialBoxopen && (
        <div
          className={isNavBarOpen ? "main-container" : "main-container-close"}
        >
          <div className="filter-categories">
            <select
              className="select-group-by"
              value={groupBy}
              onChange={(e) => setGroupBy(e.target.value)}
            >
              <option value="user">Group by User</option>
              <option value="date"> Group by Date</option>
            </select>
            {hasPermission("projects", "delete") && (
              <button
                onClick={exportToExcel}
                className={isNavBarOpen ? "export-btn" : "export-btn-close"}
                title="Export to Excel"
              >
                Export to Excel
              </button>
            )}
          </div>
          {timesheets.length === 0 && (
            <>
              <img
                className="issue-timesheet-image"
                src="/AppLayout/Issue.png"
              />
              <p className="issue-timesheet-message">
                No timesheets available in the category "Issue"
              </p>
            </>
          )}
          <div
            className={
              isNavBarOpen ? "overall-summary" : "overall-summary-close"
            }
          >
            <h3
              className="over-all-summary-header"
              style={{ marginTop: "5px", backgroundColor: "transparent" }}
            >
              Overall Summary :
            </h3>
            <p style={{ marginTop: "5px", backgroundColor: "transparent" }}>
              Billable :{" "}
              <span className="billable">
                {" "}
                {formatDuration(overallSummary.billable)}
              </span>
            </p>
            <p style={{ marginTop: "5px", backgroundColor: "transparent" }}>
              Non-Billable :{" "}
              <span className="non-billable">
                {" "}
                {formatDuration(overallSummary.nonBillable)}
              </span>
            </p>
            <p style={{ marginTop: "5px", backgroundColor: "transparent" }}>
              Approved :{" "}
              <span className="approved">
                {formatDuration(overallSummary.approved)}
              </span>
            </p>
            <p style={{ marginTop: "5px", backgroundColor: "transparent" }}>
              Unapproved:{" "}
              <span className="un-approved">
                {formatDuration(overallSummary.unapproved)}
              </span>
            </p>
          </div>
          {Object.keys(groupedByPhase).map((phaseTitle) => {
            const groupedTimesheets = getGroupedTimesheets(
              groupedByPhase[phaseTitle],
              groupBy
            );
            const phaseSummary = calculateSummary(groupedByPhase[phaseTitle]);
            const isSinglePhase = Object.keys(groupedByPhase).length === 1;
            return (
              <div
                key={phaseTitle}
                style={{ width: isNavBarOpen ? "99%" : "100%" }}
                className={
                  isSinglePhase ? "single-phase" : "table-container-timesheet"
                }
              >
                {" "}
                <h3 className="timesheet-phase-header">
                  <span className="timesheet-phase-details">{phaseTitle}</span>
                </h3>
                <div
                  className={isSinglePhase ? "single-summary" : "phase-summary"}
                >
                  <h3 className="category-summary" style={{ marginTop: "2px" }}>
                    Phase Summary : {""}
                  </h3>
                  <p className="billable" style={{ marginTop: "2px" }}>
                    {formatDuration(phaseSummary.billable)}
                  </p>
                  <p className="non-billable" style={{ marginTop: "2px" }}>
                    {formatDuration(phaseSummary.nonBillable)}
                  </p>
                  <p className="approved" style={{ marginTop: "2px" }}>
                    {formatDuration(phaseSummary.approved)}
                  </p>
                  <p className="un-approved" style={{ marginTop: "2px" }}>
                    {formatDuration(phaseSummary.unapproved)}
                  </p>
                </div>
                {Object.keys(groupedTimesheets).map((key) => (
                  <div
                    key={key}
                    style={{ marginBottom: "-25px" }}
                    className="table-parent"
                  >
                    <table className="timesheet-phase-table">
                      <thead>
                        <tr>
                          <th style={{ minWidth: "60px" }}>
                            <span className="table-icons">
                              <MdNumbers />
                              <p className="table-header">S.No</p>
                            </span>
                          </th>
                          <th>
                            <span className="table-icons">
                              <MdOutlineSubtitles
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header">Log Title</p>
                            </span>
                          </th>
                          {groupBy === "user" && (
                            <th>
                              <span className="table-icons">
                                <IoCalendarOutline
                                  style={{
                                    height: "14px",
                                    width: "30px",
                                  }}
                                />{" "}
                                <p className="table-header"> Date</p>
                              </span>
                            </th>
                          )}
                          {groupBy === "user" && (
                            <th>
                              <span className="table-icons">
                                <MdOutlineCategory
                                  style={{
                                    height: "14px",
                                    width: "30px",
                                  }}
                                />{" "}
                                <p className="table-header">Category</p>
                              </span>
                            </th>
                          )}
                          {groupBy === "date" && (
                            <th>
                              <span className="table-icons">
                                <CgProfile
                                  style={{
                                    height: "14px",
                                    width: "30px",
                                  }}
                                />{" "}
                                <p className="table-header">User</p>
                              </span>
                            </th>
                          )}
                          {groupBy === "date" && (
                            <th>
                              <span className="table-icons">
                                <MdOutlineCategory
                                  style={{
                                    height: "14px",
                                    width: "30px",
                                  }}
                                />{" "}
                                <p className="table-header">Category</p>
                              </span>
                            </th>
                          )}
                          {groupBy === "category" && (
                            <th>
                              {" "}
                              <span className="table-icons">
                                <CgProfile
                                  style={{
                                    height: "14px",
                                    width: "30px",
                                  }}
                                />{" "}
                                <p className="table-header">User</p>
                              </span>
                            </th>
                          )}
                          {groupBy === "category" && (
                            <th>
                              <span className="table-icons">
                                <IoCalendarOutline
                                  style={{
                                    height: "14px",
                                    width: "30px",
                                  }}
                                />{" "}
                                <p className="table-header">Date</p>
                              </span>
                            </th>
                          )}
                          <th>
                            <span className="table-icons">
                              <CgRename
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header">Issue No</p>
                            </span>
                          </th>
                          {/* <th>
                            <span className="table-icons">
                              <BiTask
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header">Task</p>
                            </span>
                          </th> */}
                          <th>
                            {" "}
                            <span className="table-icons">
                              <IoTimerOutline
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header"> Duration (hh:mm)</p>
                            </span>
                          </th>
                          <th>
                            <span className="table-icons">
                              <SiTyper
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header">Billable</p>
                            </span>
                          </th>
                          <th>
                            <span className="table-icons">
                              <GrStatusGood
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header">Status</p>
                            </span>
                          </th>
                          <th>
                            <span className="table-icons">
                              <TbNotes
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header">Notes</p>
                            </span>
                          </th>
                          <th>
                            <span className="table-icons">
                              <MdOutlineCreateNewFolder
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header"> Created By</p>
                            </span>
                          </th>
                          <th>
                            <span className="table-icons">
                              <LiaBusinessTimeSolid
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header"> Created At</p>
                            </span>
                          </th>
                          <th>
                            <span className="table-icons">
                              <GrUserExpert
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header"> Approved By</p>
                            </span>{" "}
                          </th>
                          <th>
                            <span className="table-icons">
                              <MdCreditScore
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header">Approved At</p>
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              backgroundColor: "rgb(243 238 238 / 34%)",
                              width: "100vw",
                              padding: "5px 0px",
                            }}
                            colSpan={14}
                          >
                            <div className="category-details">
                              <h3 className="filter-by">{key}</h3>
                              <div
                                className="group-summary-each-table"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <p className="billable">
                                  {formatDuration(
                                    calculateSummary(groupedTimesheets[key])
                                      .billable
                                  )}
                                </p>
                                <p className="non-billable">
                                  {formatDuration(
                                    calculateSummary(groupedTimesheets[key])
                                      .nonBillable
                                  )}
                                </p>
                                <p className="approved">
                                  {formatDuration(
                                    calculateSummary(groupedTimesheets[key])
                                      .approved
                                  )}
                                </p>
                                <p className="un-approved">
                                  {formatDuration(
                                    calculateSummary(groupedTimesheets[key])
                                      .unapproved
                                  )}
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                        {groupedTimesheets[key].length > 0 ? (
                          groupedTimesheets[key]
                            .sort((a, b) => b.id - a.id)
                            .map((timesheet, index) => (
                              <tr key={timesheet.id}>
                                <td style={{ textAlign: "center" }}>
                                  {index + 1}
                                </td>
                                <td>
                                  {timesheet.task_name
                                    ? timesheet.task_name
                                    : "Not Available"}
                                </td>
                                {groupBy === "user" && (
                                  <>
                                    <td>
                                      {format(timesheet.date, "dd-MM-yyyy")}
                                    </td>
                                    <td>
                                      {timesheet.variation_id
                                        ? "Variation"
                                        : timesheet.issue_id
                                        ? "Issue"
                                        : "General"}
                                    </td>
                                  </>
                                )}
                                {groupBy === "date" && (
                                  <>
                                    <td>{timesheet.user_name}</td>
                                    <td>
                                      {timesheet.variation_id
                                        ? "Variation"
                                        : timesheet.issue_id
                                        ? "Issue"
                                        : "General"}
                                    </td>
                                  </>
                                )}
                                {groupBy === "category" && (
                                  <>
                                    <td>{timesheet.user_name}</td>
                                    <td>
                                      {format(timesheet.date, "dd-MM-yyyy")}
                                    </td>
                                  </>
                                )}
                                <td>{timesheet.issueno}</td>
                                <td
                                  style={{
                                    color: timesheet.billable
                                      ? "#3acce6"
                                      : "#ee9c29",
                                    fontWeight: "700",
                                    textAlign: "center",
                                  }}
                                >
                                  {formatTime(timesheet.duration)}
                                </td>
                                <td
                                  style={{
                                    color: timesheet.billable
                                      ? "#3acce6"
                                      : "#ee9c29",
                                    fontWeight: "550",
                                  }}
                                >
                                  {timesheet.billable === 1
                                    ? "Billable"
                                    : "Non Billable"}
                                </td>
                                <td
                                  style={{
                                    // backgroundColor:
                                    //   timesheet.approvalstatus === "Approved"
                                    //     ? "#7ace4c"
                                    //     : timesheet.approvalstatus === "Rejected"
                                    //     ? "#fa2929"
                                    //     : timesheet.approvalstatus === "Pending"
                                    //     ? "#78909c"
                                    //     : "#78909c",
                                    color:
                                      timesheet.approvalstatus === "Approved"
                                        ? "white"
                                        : timesheet.approvalstatus ===
                                          "Rejected"
                                        ? "White"
                                        : timesheet.approvalstatus === "Pending"
                                        ? "White"
                                        : "white",
                                    minWidth: "200px",
                                  }}
                                >
                                  <select
                                    className="status-dropdown"
                                    value={timesheet.approvalstatus}
                                    onChange={(e) =>
                                      updateApprovalStatus(
                                        timesheet.id,
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      backgroundColor:
                                        timesheet.approvalstatus === "Approved"
                                          ? "#7ace4c"
                                          : timesheet.approvalstatus ===
                                            "Rejected"
                                          ? "#fa2929"
                                          : timesheet.approvalstatus ===
                                            "Pending"
                                          ? "#78909c"
                                          : "#78909c",
                                      color:
                                        timesheet.approvalstatus === "Approved"
                                          ? "white"
                                          : timesheet.approvalstatus ===
                                            "Rejected"
                                          ? "White"
                                          : timesheet.approvalstatus ===
                                            "Pending"
                                          ? "White"
                                          : "white",
                                      cursor: !hasPermission(
                                        "timesheets",
                                        "update"
                                      )
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                    disabled={
                                      !hasPermission("timesheets", "update")
                                    }
                                  >
                                    <option
                                      style={{
                                        backgroundColor: "white",
                                        color: "black",
                                      }}
                                      value="Pending"
                                    >
                                      Pending
                                    </option>
                                    <option
                                      style={{
                                        backgroundColor: "white",
                                        color: "black",
                                      }}
                                      value="Approved"
                                    >
                                      Approved
                                    </option>
                                    <option
                                      style={{
                                        backgroundColor: "white",
                                        color: "black",
                                      }}
                                      value="Rejected"
                                    >
                                      Rejected
                                    </option>
                                  </select>
                                </td>
                                <td>{timesheet.notes}</td>
                                <td>{timesheet.creator_name}</td>
                                <td>
                                  {timesheet.created_at
                                    ? format(timesheet.created_at, "dd-MM-yyyy")
                                    : "Not Available"}
                                </td>
                                <td>
                                  {timesheet.approver_name
                                    ? timesheet.approver_name
                                    : "Not Avilable"}
                                </td>
                                <td>
                                  {timesheet.approved_at &&
                                  timesheet.approved_at !== "NULL"
                                    ? format(
                                        timesheet.approved_at,
                                        "dd-MM-yyyy"
                                      )
                                    : "Not Approved"}
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan="14">No timesheets available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default General;
